import React, {useEffect, useState} from "react";
import {useIsFocused} from "@react-navigation/native";
import {
    boardAnswerEditRequest,
    responseWrapper
} from "../api/entity";
import {useMutation} from "react-query";
import client from "../api/client.ts";
import {board,favorite} from '../api/urls'
import {Image, PixelRatio, StyleSheet, Text, TextInput, Pressable, View} from "react-native";
import PublicTopMenu from "../components/public/PublicTopMenu.tsx";
import ReportMenu from "../components/public/ReportMenu.tsx";
import QuestionView from "../components/public/QuestionView.tsx";
import userContext from "../context/userContext.ts";
import {isDesktop} from "react-device-detect";
import {publicWrapper} from '../components/public/PublicStyle'
import arrow_left from '../assets/images/arrow_left.png'
import AsyncStorage from "@react-native-async-storage/async-storage";

function EditAnswer({navigation, route}){

    const answerData = route.params.answer
    const [openType,setOpenType] = useState(answerData.isPublic)
    const [bookSalon, setBookSalon] = useState([...userContext.getUser.bookSalon])

    const [answerText, setAnswerText] = useState('')

    const isFocused = useIsFocused()
    useEffect(()=>{
        if (isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }
            })
            setAnswerText(answerData.content)
        }
    },[useIsFocused()])

    const editInstance = useMutation({
        mutationFn: (data: boardAnswerEditRequest) => client.post<responseWrapper<boolean>>(board.post.update('answer'),data)
    })

    const editAction = ()=>{
        const request: boardAnswerEditRequest = {
            uid: answerData.uid,
            content: answerText,
            isPublic: openType
        }

        if(answerText.length===0) {
            return alert('답글 내용을 작성해주세요')
        }

        editInstance.mutate(request,{
            onSuccess: ({data})=> {
                if(data.status){
                    alert('수정이 완료 되었습니다')
                    navigation.pop()
                }
            }
        })
    }

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <View style={[publicWrapper.backgrounded,{padding:0}]}>
                <View style={styles.topWrapper}>
                    <Pressable
                        style={styles.horizontalWrapper}
                        onPress={()=>navigation.pop()}
                    >
                        <Image style={styles.arrowIcon} source={arrow_left}/>
                        <Text style={styles.backText}>돌아가기</Text>
                    </Pressable>
                </View>
                <QuestionView navigation={navigation} route={route} questionUid={answerData.questionUid}/>
                <View style={styles.answerMenuWrapper}>
                    <Text style={styles.answerMenuTitleText}>공개설정</Text>
                    <Pressable
                        style={[styles.answerMenuButtonWrapper, openType===1&&styles.selectColor]}
                        onPress={()=>{
                            setOpenType(1)
                        }}
                    >
                        <Text style={[styles.answerMenuButtonText, openType===1&&styles.selectColor]}>전체공개</Text>
                    </Pressable>
                    <Pressable
                        style={[styles.answerMenuButtonWrapper, openType===2&&styles.selectColor]}
                        onPress={()=>{
                            setOpenType(2)
                        }}
                    >
                        <Text style={[styles.answerMenuButtonText, openType===2&&styles.selectColor]}>내 북살롱 공개</Text>
                    </Pressable>
                    <Pressable
                        style={[styles.answerMenuButtonWrapper, openType===0&&styles.selectColor]}
                        onPress={()=>{
                            setOpenType(0)
                        }}
                    >
                        <Text style={[styles.answerMenuButtonText, openType===0&&styles.selectColor]}>비공개</Text>
                    </Pressable>
                </View>
                <View style={styles.writeAnswerWrapper}>
                    <TextInput
                        style={styles.inputAnswerText}
                        value={answerText}
                        onChangeText={setAnswerText}
                        multiline={true}
                        placeholder={'답글 내용을 입력하세요.'}
                        placeholderTextColor={'#AAAAAA'}
                        maxLength={5000}
                    />
                    <View style={styles.answerLengthWrapper}>
                        <Text style={styles.answerLengthText}>{answerText.length}</Text>
                        <Text style={styles.answerMaxLengthText}> / 5000</Text>
                    </View>

                </View>
                <View style={styles.bottomWrapper}>
                    <Pressable
                        style={styles.bottomDismissButtonWrapper}
                        onPress={()=>{
                            navigation.pop()
                        }}
                    >
                        <Text style={styles.bottomDismissButtonText}>취소</Text>
                    </Pressable>
                    <View style={{width: PixelRatio.getPixelSizeForLayoutSize(14)}}/>
                    <Pressable
                        style={styles.bottomSubmitButtonWrapper}
                        onPress={editAction}
                    >
                        <Text style={styles.bottomSubmitButtonText}>답글 수정 완료</Text>
                    </Pressable>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    separatorVertical: {
        backgroundColor: '#DDDDDD',
        flex: 1,
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    separatorHorizontal: {
        backgroundColor: '#DDDDDD',
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    topWrapper: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(43),
        alignItems: 'center',
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#D9D9D9'
    },
    arrowIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(6),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(16),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    backText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        color: '#2C4198',
    },
    questionWrapper: {
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(127),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    questionTitleText: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
    },
    answerMenuWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(82),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        flexDirection: 'row',
        alignItems: 'center',
    },
    answerMenuTitleText: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
        flex: 1,
    },
    answerMenuButtonWrapper: {
        borderColor: '#777777',
        height: PixelRatio.getPixelSizeForLayoutSize(28),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(11),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(7),
        borderWidth: 1,
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(50),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(5),
        alignItems: 'center',
        justifyContent: 'center',

    },
    answerMenuButtonText: {
        color: '#777777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
        textAlign: 'center',
        includeFontPadding: true
    },
    selectColor: {
        color: '#2C4198',
        borderColor: '#2C4198'
    },
    writeAnswerWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(362),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
    },
    inputAnswerText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500',
        backgroundColor: 'white',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        padding: PixelRatio.getPixelSizeForLayoutSize(20),
        flex: 1
    },

    answerLengthWrapper: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: PixelRatio.getPixelSizeForLayoutSize(20),
        right: PixelRatio.getPixelSizeForLayoutSize(20),

    },
    answerLengthText: {
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '700'
    },
    answerMaxLengthText: {
        color: '#AAAAAA',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500'
    },

    bottomWrapper: {
        flexDirection: 'row',
        height: PixelRatio.getPixelSizeForLayoutSize(83),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(15),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(14),
        alignItems: 'center',
        justifyContent: 'center',
    },
    bottomDismissButtonWrapper: {
        alignItems: 'center',
        backgroundColor: 'white',
        flex: 1,
        borderColor: '#DDDDDD',
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(8),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(18),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(14),
    },
    bottomSubmitButtonWrapper: {
        alignItems: 'center',
        backgroundColor: '#2C4198',
        flex: 1,
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(8),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(18),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(14),
    },
    bottomDismissButtonText: {
        color: 'black',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
    },
    bottomSubmitButtonText: {
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
    },
})

export default EditAnswer
