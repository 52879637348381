import { AppRegistry } from "react-native";
import App from "./App";
import fontA from "./assets/fonts/SCDream3.otf";
import fontB from "./assets/fonts/KoPub_Dotum_Pro.otf";
AppRegistry.registerComponent("App", () => App);

const a = new FontFace('SCDream5', `url(${fontA})`)
const b = new FontFace('KoPub_Dotum_Pro', `url(${fontB})`)
a.load().then(v=>v.status==="loaded"&&document.fonts.add(v))
b.load().then(v=>v.status==="loaded"&&document.fonts.add(v))



AppRegistry.runApplication("App", {
    rootTag: document.getElementById("root")
});
