import React, {useEffect, useState} from "react";
import TopMenu from "../components/myquestion/TopMenu.tsx";
import PublicTopMenu from '../components/public/PublicTopMenu.tsx'
import {publicWrapper} from '../components/public/PublicStyle'
import {Image, Linking, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import download_icon from '../assets/images/download_icon.svg'
import book_icon from '../assets/images/book_icon.svg'
import checkLogin from "../components/public/checkLogin.ts";
import {useIsFocused} from "@react-navigation/native";
import {useMutation, useQuery} from "react-query";
import client from "../api/client.ts";
import {board} from '../api/urls';
import axios from "axios";
import {asDynamicObservableObject} from "mobx/dist/types/dynamicobject";
import {isDesktop} from "react-device-detect";
import {responseWrapper} from "../api/entity";
import AsyncStorage from "@react-native-async-storage/async-storage";
function MyQuestionCreateBook({navigation, route}){
    const [textUrl, setTextUrl] = useState<Blob>('')
    const makeTextInstance = useMutation({
        mutationFn: ()=>client.post<string>(board.post.saveText),
        onSuccess:({data})=>{
            getFileUrlInstance.mutate(data)
        }
    })

    const getFileUrlInstance = useMutation({
        mutationFn: (url: string)=> axios.get(url,{
            responseType: 'text',
        }),
        onSuccess:({data})=>{
            const url = URL.createObjectURL(new Blob([data], {type: 'plaintext'}))
            setTextUrl(url)


            //setTextUrl(data)
        },
    })



    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }else {
                    makeTextInstance.mutate()
                }
            })

        }
    },[useIsFocused()])

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <TopMenu navigation={navigation} route={route}/>
            <View style={publicWrapper.backgrounded}>
                <View style={styles.topWrapper}>
                    <Text style={styles.titleText}>내가 작성한 답글 수 </Text>
                    <Text style={styles.titleNumberText}>0</Text>
                </View>
                <View style={styles.itemWrapper}>
                    <Text style={styles.itemTitleText}>내가 작성한 글을 txt파일로 다운로드 할 수 있습니다.</Text>
                    <View style={{flex:1}}/>
                    <View
                        style={styles.itemButtonWrapper}
                        href={textUrl}
                        hrefAttrs={{
                            download: 'myWriteText.txt',
                        }}
                    >
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Text style={styles.itemButtonText}>파일 다운로드</Text>
                            <Image style={styles.download_icon} source={download_icon}/>
                        </View>
                    </View>

                </View>
                <View style={styles.itemWrapper}>
                    <Text style={styles.itemTitleText}>내가 작성한 글을 종이책으로 만들 수 있습니다.</Text>
                    <View style={{flex:1}}/>
                    <Pressable
                        style={styles.itemButtonWrapper}
                        onPress={()=>{
                            Linking.openURL('https://www.1half.co.kr/').then()
                        }}
                    >
                        <Text style={styles.itemButtonText}>책 만들기 바로가기</Text>
                        <Image style={styles.book_icon} source={book_icon}/>
                    </Pressable>
                </View>
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500'
    },
    titleNumberText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '700'
    },
    topWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(46),
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemWrapper: {
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(112),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(18),
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(17),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(16),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(20),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10)
    },
    itemTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
        textAlign: 'center'
    },
    itemButtonWrapper: {
        backgroundColor: '#F1F6FB',
        height: PixelRatio.getPixelSizeForLayoutSize(39),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    itemButtonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '700',
        textAlign: 'center',
    },
    download_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(15),
        height: PixelRatio.getPixelSizeForLayoutSize(13),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(10)
    },
    book_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(18),
        height: PixelRatio.getPixelSizeForLayoutSize(18),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(10)
    }
})

export default MyQuestionCreateBook
