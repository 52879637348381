import React, {useEffect, useState} from "react";
import {Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import heart_fill from '../../assets/images/heart_fill.png'
import heart_stroke from '../../assets/images/heart_stroke.png'
import getDate from '../../components/public/getDate'
import {useIsFocused} from "@react-navigation/native";
import FavoriteConnection from "../../api/FavoriteConnection.ts";
import userContext from "../../context/userContext.ts";

function MyQuestionAnswerListItem({navigation, item}){
    const [favorite, setFavorite] = useState(false)
    const [favoriteCount, setFAvoriteCount] = useState(item.favoriteCount)

    const isFocused = useIsFocused()
    useEffect(() => {
        if(isFocused) {
            setFavorite(item.favorite)
            setFavorite(item.favoriteCount)
        }
    }, []);

    const favoriteInstance = FavoriteConnection('question', favorite, item.questionUid)


    return(
        <Pressable
            style={styles.listItemWrapper}
            onPress={()=>{
                const data = {
                    uid: item.questionUid,
                    userUid: item.userUid,
                    title: item.questionTitle,
                    isPublic: item.isPublic
                }
                navigation.push('Answer',{question: data})
            }}
        >
            <View style={styles.horizontalWrapper}>
                <Text style={styles.listItemTitleText} numberOfLines={1}>{item.questionTitle}</Text>
                <View style={{flex: 1}}/>
                <Pressable
                    style={styles.horizontalWrapper}
                    onPress={()=> {
                        favoriteInstance.mutate(null,{
                            onSuccess: ({data})=>{
                                if (data.status) {
                                    setFavorite(!favorite)
                                    setFAvoriteCount(favoriteCount+1)
                                }else {
                                    setFavorite(!favorite)
                                    setFAvoriteCount(favoriteCount-1)
                                }
                            }
                        })
                    }}
                >
                    <Image style={styles.icon} source={favorite?heart_fill:heart_stroke}/>
                    <Text style={styles.listItemHeartText}>{favoriteCount}</Text>
                </Pressable>

            </View>
            <View style={{flex: 1}}/>
            <View style={styles.horizontalWrapper}>
                <Text style={styles.listCategoryText}>{item.keywordString}</Text>
                <View style={styles.horizontalSeparator}/>
                <Text style={styles.listItemSubText}>{getDate(item.regDate)}</Text>
                <View style={styles.horizontalSeparator}/>
                <Text style={styles.listItemSubText}>{item.registerName}</Text>
                <View style={{flex: 1}}/>
                <View style={styles.listItemButtonWrapper}>
                    <Text style={styles.listItemButtonText}>답글보기</Text>
                </View>
            </View>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    horizontalSeparator: {
        backgroundColor: '#DDDDDD',
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8)
    },
    listItemWrapper: {
        backgroundColor: 'white',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        height: PixelRatio.getPixelSizeForLayoutSize(84),
        padding: PixelRatio.getPixelSizeForLayoutSize(16),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12)
    },
    listItemTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700'
    },
    listItemSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    listCategoryText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(18),
        height: PixelRatio.getPixelSizeForLayoutSize(18),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    listItemHeartText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '700'
    },
    listItemButtonWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(23),
        alignItems: 'center',
        justifyContent:'center',
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(5),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(10),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        borderColor: '#DDDDDD'
    },
    listItemButtonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500'
    }
})

export default MyQuestionAnswerListItem
