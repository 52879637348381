import React, {useEffect, useState} from "react";
import {Image, PixelRatio, Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import client from "../api/client.ts";
import {user} from '../api/urls'
import userContext from "../context/userContext";
import {isDesktop} from "react-device-detect";
import logo from '../assets/images/logo_2.svg'
import xImage from '../assets/images/x_icon.svg'
import {publicWrapper} from '../components/public/PublicStyle'
import {useMutation} from "react-query";
import {
    changePasswordRequest,
    checkRandomNumberRequest,
    randomNumberRequest,
    randomNumberResponse,
    responseWrapper
} from "../api/entity";
import {useIsFocused} from "@react-navigation/native";

function ChangePassword({navigation, route}){

    const [id,setId] = useState('')
    const [phoneNumber,setPhoneNumber] = useState('')
    const [checkNumber,setCheckNumber] = useState('')

    const [password,setPassword] = useState('')
    const [checkPassword,setCheckPassword] = useState('')

    const [isSendNumber, setIsSendNumber] = useState(false)
    const [isConfirmed, setIsConfirmed] = useState(false)

    const receiveNumberInstance = useMutation({
        mutationFn: (request: randomNumberRequest)=>{
            return client.post<responseWrapper<randomNumberResponse>>(user.post.makeRandomNumber, request)
        }
    })

    const checkNumberInstance = useMutation({
        mutationFn: (request: checkRandomNumberRequest)=> client.post<responseWrapper<boolean>>(user.post.checkRandomNumber, request)
    })

    const changePasswordInstance = useMutation({
        mutationFn: (request: changePasswordRequest)=> client.post<responseWrapper<any>>(user.post.changePassword, request)
    })

    const isFocuced = useIsFocused()
    useEffect(() => {
        if(isFocuced) {
            setIsConfirmed(false)
            setIsSendNumber(false)
        }
    }, [useIsFocused()]);

    const receiveNumberAction = ()=>{
        if(id!==''||phoneNumber!=='') {
            const req: randomNumberRequest = {
                id: id,
                receiveTelNumber: phoneNumber
            }
            receiveNumberInstance.mutate(req, {
                onSuccess: ({data})=> {
                    if(data.status) {
                        setIsSendNumber(true)
                        alert('인증번호 문자가 발송되었습니다')
                    }else {
                        if(data.errorcode === 18) {
                            return alert('아이디와 전화번호가 일치하지 않습니다')
                        }
                    }
                }
            })
        }else {
            return alert('아이디와 전화번호를 입력해주세요')
        }
    }

    useEffect(() => {
        if (password.length>0&&
            checkPassword.length>0&&
            phoneNumber.length===11&&
            checkNumber.length===6) {
            setIsConfirmed(true)
        }else {
            setIsConfirmed(false)
        }
    }, [password, checkPassword, phoneNumber, checkNumber, ]);

    const changeAction = ()=> {
        if(checkNumber.length <= 0) {
            return alert('인증번호를 입력해주세요')
        }

        if(password !== checkPassword) {
            return alert('비밀번호가 일치 하지 않습니다')
        }
        if(password.length === 0) {
            return alert('비밀번호를 입력하세요')
        }

        const  req: checkRandomNumberRequest = {
            receiveTelNumber: phoneNumber,
            inputDigit: checkNumber
        }

        checkNumberInstance.mutate(req, {
            onSuccess: ({data})=> {
                if(data.status) {
                    if(data.data) {
                        const request: changePasswordRequest = {
                            id: id,
                            newPassword: password
                        }

                        changePasswordInstance.mutate(request, {
                            onSuccess: ({data})=> {
                                if(data.status) {
                                    alert('비밀번호 변경에 성공 했습니다')
                                    navigation.push('Login')
                                }
                            }
                        })
                    }else {
                        return alert('인증 코드가 잘못되었습니다')
                    }
                }
            }
        })
    }



    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>

            <Pressable
                style={styles.xButtonWrapper}
                onPress={()=>{navigation.pop()}}
            >
                <Image style={styles.xButton} source={xImage}/>
            </Pressable>
            <View style={styles.wrapper}>
                <View style={styles.bannerWrapper}>
                    <Image style={styles.bannerIcon} source={logo}/>
                </View>
                <View style={{width: '100%', marginBottom: PixelRatio.getPixelSizeForLayoutSize(31)}}>
                    <View style={styles.inputTitleWrapper}>
                        <Text style={styles.inputTitleText}>아이디 </Text>
                        <Text style={styles.inputStarText}>*</Text>
                    </View>
                    <TextInput
                        style={styles.inputText}
                        placeholder={'가입 시 사용한 이메일을 입력해주세요'}
                        placeholderTextColor={'#999'}
                        value={id}
                        onChangeText={setId}
                    />
                    <View style={styles.inputTitleWrapper}>
                        <Text style={styles.inputTitleText}>전화번호 </Text>
                        <Text style={styles.inputStarText}>*</Text>
                    </View>
                    <TextInput
                        style={styles.inputText}
                        maxLength={11}
                        placeholder={'가입 시 사용한 휴대전화번호를 입력해주세요'}
                        placeholderTextColor={'#999'}
                        value={phoneNumber}
                        onChangeText={setPhoneNumber}
                    />
                    <View style={styles.inputTitleWrapper}>
                        <Text style={styles.inputTitleText}>확인코드 </Text>
                        <Text style={styles.inputStarText}>*</Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <TextInput
                            style={[styles.inputText,{width: '80%'}]}
                            maxLength={6}
                            placeholder={'휴대전화로 전송된 코드를 입력해주세요'}
                            placeholderTextColor={'#999'}
                            value={checkNumber}
                            onChangeText={setCheckNumber}
                        />
                        <View style={{width: PixelRatio.getPixelSizeForLayoutSize(8)}}/>
                        <Pressable
                            style={[styles.buttonWrapper,{flex: 0.8}]}
                            onPress={receiveNumberAction}
                        >
                            <Text
                                style={[styles.accessButtonText]}
                                numberOfLines={1}
                            >
                                {isSendNumber?'재발송':'인증문자발송'}
                            </Text>
                        </Pressable>
                    </View>
                    <View style={styles.inputTitleWrapper}>
                        <Text style={styles.inputTitleText}>비밀번호 </Text>
                        <Text style={styles.inputStarText}>*</Text>
                    </View>
                    <TextInput
                        style={styles.inputText}
                        placeholder={'비밀번호를 입력해주세요'}
                        placeholderTextColor={'#999'}
                        secureTextEntry={true}
                        value={password}
                        onChangeText={setPassword}
                    />
                    <View style={styles.inputTitleWrapper}>
                        <Text style={styles.inputTitleText}>비밀번호 확인 </Text>
                        <Text style={styles.inputStarText}>*</Text>
                    </View>
                    <TextInput
                        style={styles.inputText}
                        placeholder={'비밀번호를 입력해주세요'}
                        placeholderTextColor={'#999'}
                        secureTextEntry={true}
                        value={checkPassword}
                        onChangeText={setCheckPassword}
                    />
                </View>
                <View style={{width: '100%', marginBottom: PixelRatio.getPixelSizeForLayoutSize(10)}}>
                    <Pressable
                        style={[styles.buttonWrapper, !isConfirmed&&styles.disableWrapper]}
                        onPress={changeAction}
                    >
                        <Text style={[styles.buttonText, !isConfirmed&&styles.disableText]}>확인</Text>
                    </Pressable>
                </View>




            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    xButtonWrapper: {
        margin: PixelRatio.getPixelSizeForLayoutSize(16),
        alignSelf: 'flex-end'
    },
    xButton: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
    },
    wrapper: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    bannerWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(47),
    },
    bannerIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(154),
        height: PixelRatio.getPixelSizeForLayoutSize(36),
    },
    bannerText: {
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(30),
        fontWeight: '500',
        fontFamily: 'SCDream5',
    },
    inputTitleWrapper: {
        flexDirection: 'row',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(6)
    },
    inputTitleText: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
        fontFamily: 'KoPub_Dotum_Pro',

    },
    inputStarText: {
        color: '#DD2E44',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
        verticalAlign: 'top',
        fontFamily: 'KoPub_Dotum_Pro',
    },
    inputText: {
        flexDirection: 'row',
        flex: 1,
        color: '#232323',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#DDD',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        height: PixelRatio.getPixelSizeForLayoutSize(49),
        padding: PixelRatio.getPixelSizeForLayoutSize(17),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(15)
    },
    buttonWrapper:{
        height: PixelRatio.getPixelSizeForLayoutSize(49),
        backgroundColor: '#2C4198',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(15),
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonText: {
        color: '#FFF',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '400',
    },
    accessButtonText: {
        color: '#FFF',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '400',
    },
    disableWrapper:{
        backgroundColor: '#777',
    },
    disableText: {
        color: 'white',
    }

})
export default ChangePassword
