import React, {createFactory, useEffect} from "react";
import {NavigationContainer} from "@react-navigation/native";
import {QueryClient, QueryClientProvider} from "react-query";
import RootStack from "./screens/RootStack.tsx";
import fontA from "./assets/fonts/SCDream5.otf";
import fontB from "./assets/fonts/KoPub_Dotum_Pro.otf";


function App()  {

    const linking = {
        config: {
            screens: {
                Main: 'Main',
                AllQuestions: 'AllQuestions',
                Community: 'Community',
                Signup: 'Signup',
                Login: 'Login'
            },
        },
    };


    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <NavigationContainer
                linking={linking}
            >
                <RootStack/>
            </NavigationContainer>
        </QueryClientProvider>
    );
}
export default App;
