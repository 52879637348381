import React, {useEffect, useState} from "react";
import TopMenu from "../components/myquestion/TopMenu.tsx";
import PublicTopMenu from '../components/public/PublicTopMenu.tsx'
import {publicWrapper} from '../components/public/PublicStyle'
import {FlatList, Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import alert_icon from '../assets/images/alert_icon.svg'
import checkLogin from "../components/public/checkLogin.ts";
import {useIsFocused} from "@react-navigation/native";
import {MutationOptions, useMutation, useQuery} from "react-query";
import {favoriteRequest, myFavoriteBoard, myFavoriteResponse, myQuestionRequest} from "../api/entity";
import client from "../api/client.ts";
import {myQuestion,board} from '../api/urls'
import getDate from '../components/public/getDate'
import {isDesktop} from "react-device-detect";
import userContext from "../context/userContext.ts";
import AsyncStorage from "@react-native-async-storage/async-storage";

function MyQuestionMakeQuestion({navigation, route}){

    const [boardData, setBoardData] = useState<myFavoriteBoard>([])
    const [questionCount, setQuestionCount] = useState(0)
    const [lastUid,setLastUid] = useState(undefined)

    const boardInstance = useMutation({
        mutationFn: (data: myQuestionRequest)=> client.post<myFavoriteResponse>(myQuestion.post.myQuestion,data),
        onSuccess: ({data})=>{
            const length = data.questionList.length > 0? data.questionList.length-1 : undefined
            if (length !== undefined) {
                setQuestionCount(data.questionCount)
                setBoardData(data.questionList)
                setLastUid(data.questionList[length].questionUid)
            }
        }
    })

    /*const privateInstance = useMutation({
        mutationFn:(data: favoriteRequest)=> client.post(board.post.makePrivate, data),
        onSuccess: ({data})=> {
            const request: myQuestionRequest = {
                lastUid: undefined
            }
            boardInstance.mutate(request,{
                onSuccess:({data})=>{
                    setQuestionCount(data.questionCount)
                    setBoardData(data.questionList)
                    const length = data.questionList.length > 0? data.questionList.length-1 : undefined
                    if (length !== undefined) {
                        setLastUid(data.questionList[length].questionUid)
                    }
                }
            })
        }
    })*/

    const publicInstance = useMutation({
        mutationFn:(data: favoriteRequest)=> client.post(board.post.makePublic,data),
        onSuccess: ({data})=> {
            const request: myQuestionRequest = {
                lastUid: undefined
            }
            boardInstance.mutate(request,{
                onSuccess:({data})=>{
                    const length = data.questionList.length > 0? data.questionList.length-1 : undefined
                    if (length !== undefined) {
                        setLastUid(data.questionList[length].questionUid)
                        setQuestionCount(data.questionCount)
                        setBoardData(data.questionList)
                    }
                }
            })
        }
    })

    const emptyDataView = (
        <View style={styles.emptyWrapper}>
            <Image style={styles.empty_icon} source={alert_icon}/>
            <Text style={styles.emptyText}>아직 등록한 질문이 없네요.</Text>
            <Text style={styles.emptyText}>지금 바로 회원님만의 질문을 만들어보세요.</Text>
        </View>
    )


    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }else {
                    const boardRequest: myQuestionRequest = {
                        lastUid: undefined
                    }

                    boardInstance.mutate(boardRequest)
                }
            })

        }
    },[useIsFocused()])

    const questionList = (
        <FlatList
            data={boardData}
            renderItem={({item})=>{
                return(
                    <Pressable
                        style={styles.listItemWrapper}
                        onPress={()=>{
                            const data = {
                                uid: item.questionUid,
                                userUid: item.userUid,
                                title: item.questionTitle,
                                isPublic: item.isPublic
                            }
                            navigation.push('Answer', {question: data})
                        }}
                    >
                        <Text style={styles.listItemTitleText} numberOfLines={1}>{item.questionTitle}</Text>
                        <View style={{flex:1}}/>
                        <View style={styles.horizontalWrapper}>
                            <Text style={styles.listItemCategoryText}>{item.keywordString}</Text>
                            <View style={styles.horizontalSeparator}/>
                            <Text style={styles.listItemDateText}>{getDate(item.regDate)}</Text>
                            <View style={{flex: 1}}/>
                            <View
                                style={[styles.listItemButtonWrapper,item.isPublic===1&&styles.publicButtonWrapper]}
                                /*onPress={()=>{
                                    const request: favoriteRequest = {
                                        uid: item.questionUid
                                    }
                                    if(item.isPublic === 1) {
                                        const data = {
                                            uid: item.questionUid,
                                            userUid: item.userUid,
                                            title: item.questionTitle,
                                            isPublic: item.isPublic
                                        }
                                        navigation.push('Answer', {question: data})
                                        //privateInstance.mutate(request)
                                    }else {
                                        publicInstance.mutate(request)
                                    }
                                }}*/
                            >
                                <Text style={[styles.listItemButtonText,item.isPublic===1&&styles.publicButtonText]}>
                                    {item.isPublic===1?'공개':'비공개'}
                                </Text>
                            </View>
                        </View>
                    </Pressable>
                )
            }}
            onEndReached={()=>{
                const request: myQuestionRequest = {
                    lastUid: lastUid
                }
                boardInstance.mutate(request, {
                    onSuccess:({data})=>{
                        const length = data.questionList.length > 0? data.questionList.length-1 : undefined
                        if (length !== undefined) {
                            setBoardData([...boardData,...data.questionList])
                            setLastUid(data.questionList[length].questionUid)
                        }
                    }
                })
            }}
        />
    )

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <TopMenu navigation={navigation} route={route}/>
            <View style={publicWrapper.backgrounded}>
                <View style={styles.topWrapper}>
                    <Text style={styles.titleText}>내가 만든 질문 수 </Text>
                    <Text style={styles.titleNumberText}>{questionCount}</Text>
                </View>
                {questionCount>0 ? questionList : emptyDataView}

                <Pressable
                    style={styles.bottomButtonWrapper}
                    onPress={()=> {
                        navigation.push('MyQuestionWriteQuestion')
                    }}
                >
                    <Text style={styles.bottomButtonText}>새 질문 만들기</Text>
                </Pressable>
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    horizontalSeparator: {
        backgroundColor: '#DDD',
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8)
    },
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500'
    },
    titleNumberText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '700'
    },
    topWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(46),
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItemWrapper: {
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(81),
        padding: PixelRatio.getPixelSizeForLayoutSize(16),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    listItemTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700'
    },
    listItemCategoryText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    listItemDateText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    listItemButtonWrapper: {
        backgroundColor: '#B3B3B355',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(10),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(5),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(2)
    },
    publicButtonWrapper: {
        backgroundColor: '#2C419855'
    },
    listItemButtonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#777777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500'
    },
    publicButtonText: {
        color:'#2C4198'
    },
    bottomButtonWrapper: {
        backgroundColor: '#2C4198',
        height: PixelRatio.getPixelSizeForLayoutSize(50),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(8),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(13),
        alignItems: 'center',
        justifyContent: 'center',
        position: 'sticky',
        bottom: PixelRatio.getPixelSizeForLayoutSize(13),
    },
    bottomButtonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500'
    },
    emptyWrapper: {
        height: innerHeight - PixelRatio.getPixelSizeForLayoutSize(230),
        alignItems: 'center',
        justifyContent: 'center',
    },
    emptyText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#777777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
        textAlign: 'center'
    },
    empty_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(31),
        height: PixelRatio.getPixelSizeForLayoutSize(31),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(14),
    }
})


export default MyQuestionMakeQuestion
