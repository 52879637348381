import React, {useEffect} from "react";
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {MenuName} from '../components/public/MenuName.ts';
import Main from './Main.tsx'
import Login from './Login.tsx'
import Community from './Community.tsx'
import AllQuestions from './AllQuestions.tsx'
import Signup from './Signup.tsx'
import Menu from "./Menu.tsx";
import Answer from "./Answer.tsx";
import WriteAnswer from "./WriteAnswer.tsx";
import EditAnswer from './EditAnswer.tsx'
import AnswerDetail from "./AnswerDetail.tsx";
import MyQuestion from "./MyQuestion.tsx";
import MyQuestionAnswer from "./MyQuestionAnswer.tsx";
import MyQuestionCreateBook from "./MyQuestionCreateBook.tsx";
import MyQuestionMakeQuestion from "./MyQuestionMakeQuestion.tsx";
import MyQuestionWriteQuestion from "./MyQuestionWriteQuestion.tsx";
import ModifyUserContext from "./ModifyUserContext.tsx";
import MenuNoticeDetail from "./MenuNoticeDetail.tsx";
import MenuNotice from "./MenuNotice.tsx";
import MenuInquireList from "./MenuInquireList.tsx";
import MenuWriteInquire from "./MenuWriteInquire.tsx";
import MenuInquireDetail from "./MenuInquireDetail.tsx";
import ChangePassword from "./ChangePassword.tsx";
import {useIsFocused, useNavigation} from "@react-navigation/native";
import AsyncStorage, {useAsyncStorage} from "@react-native-async-storage/async-storage";
import userContext from "../context/userContext.ts";
import {loginResult, userResponse} from '../api/entity'
import client, {applyToken, cleanToken} from "../api/client.ts";
import UserContext from "../context/userContext.ts";



const Stack = createNativeStackNavigator();



function RootStack() {

    const navigation = useNavigation()

    const  isFocused = useIsFocused()
    useEffect(()=> {
        if (isFocused) {
            AsyncStorage.getItem('user').then(v=>{
                if(v!==null&&v!==undefined) {
                    if(v!=='undefined') {
                        const data: userResponse = JSON.parse(v)
                        userContext.setUser(data)
                    } else {
                        userContext.setUser(undefined)
                        userContext.setRefreshToken(undefined)
                        AsyncStorage.clear()
                    }
                }
            })

            AsyncStorage.getItem('token').then(token=>{
                if(token!==null) {
                    const data: loginResult = JSON.parse(token)
                    applyToken(data.token)
                    userContext.setRefreshToken(data.refreshToken)
                }else {
                    userContext.setUser(undefined)
                    userContext.setRefreshToken(undefined)
                    AsyncStorage.clear()
                }
            })

            client.interceptors.response.use((res)=>{
                if(res.data.errorcode===97) {
                    navigation.navigate(`Login`)
                }

                return res
            })
        }
    },[useIsFocused()])

    return (
        <Stack.Navigator
            initialRouteName="/Main"
            screenOptions={{
                headerShown: false,
                title: '이분의 글'
            }}
        >
            {/* 디자인 번호 순*/}
            {/* 1 */}
            <Stack.Screen
                name="Main"
                component={Main}
                initialParams={{menu: MenuName.Main}}
            />
            {/* 2 */}
            <Stack.Screen
                name='AllQuestions'
                component={AllQuestions}
                initialParams={{menu: MenuName.AllQuestion}}
            />
            <Stack.Screen
                name='Answer'
                component={Answer}
                initialParams={{menu: MenuName.AllQuestionAnswer}}
            />
            {/* 3 */}
            <Stack.Screen name='Community' component={Community} initialParams={{menu: MenuName.Community}}/>
            <Stack.Screen name='AnswerDetail' component={AnswerDetail} initialParams={{menu: MenuName.CommunityDetail}}/>
            <Stack.Screen name='WriteAnswer' component={WriteAnswer} initialParams={{menu: MenuName.CommunityWrite}}/>
            <Stack.Screen name='EditAnswer' component={EditAnswer} initialParams={{menu: MenuName.CommunityWrite}}/>
            {/* 4 */}
            <Stack.Screen name='MyQuestion' component={MyQuestion} initialParams={{menu: MenuName.MyQuestionHeart}}/>
            <Stack.Screen name='MyQuestionAnswer' component={MyQuestionAnswer} initialParams={{menu: MenuName.MyQuestionAnswer}}/>
            <Stack.Screen name='MyQuestionMakeQuestion' component={MyQuestionMakeQuestion} initialParams={{menu: MenuName.MyQuestionMakeQuestion}}/>
            <Stack.Screen name='MyQuestionCreateBook' component={MyQuestionCreateBook} initialParams={{menu: MenuName.MyQuestionCreateBook}}/>
            <Stack.Screen name='MyQuestionWriteQuestion' component={MyQuestionWriteQuestion} initialParams={{menu: MenuName.MyQuestionMakeQuestion}}/>
            {/* 5 */}
            <Stack.Screen name='Login' component={Login} />
            <Stack.Screen name='Signup' component={Signup}/>
            <Stack.Screen name='ChangePassword' component={ChangePassword}/>
            <Stack.Screen name='Modify' component={ModifyUserContext}/>
            <Stack.Screen name='Menu' component={Menu}/>
            <Stack.Screen name='MenuNotice' component={MenuNotice}/>
            <Stack.Screen name='MenuNoticeDetail' component={MenuNoticeDetail}/>
            <Stack.Screen name='MenuInquireList' component={MenuInquireList}/>
            <Stack.Screen name='MenuWriteInquire' component={MenuWriteInquire}/>
            <Stack.Screen name='MenuInquireDetail' component={MenuInquireDetail}/>
        </Stack.Navigator>
    )
}

export default RootStack;
