import React, {useEffect, useState} from "react";
import {PixelRatio, StyleSheet, Text, View} from "react-native";
import MenuWrapper from "../components/menu/MenuWrapper.tsx";
import {useMutation} from "react-query";
import {noticeRequest, noticeResponse, responseWrapper} from "../api/entity";
import client from "../api/client.ts";
import {notice} from '../api/urls'
import {useIsFocused} from "@react-navigation/native";
import getDate from '../components/public/getDate'
import checkLogin from "../components/public/checkLogin.ts";
import PublicScripts from "../components/public/PublicScripts.jsx";
import AsyncStorage from "@react-native-async-storage/async-storage";

function MenuNoticeDetail({navigation, route}){

    const [uid,setUid] = useState()
    const [boardData, setBoardData] = useState<noticeResponse>()

    const noticeInstance = useMutation({
        mutationFn: (uid:number)=> client.post<responseWrapper<noticeResponse>>(notice.detail(uid))
    })


    const isFocused = useIsFocused()
    useEffect(() => {
        if(isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }
            })
            if (route.params !== undefined) {
                setUid(route.params.uid)
            }
        }
    }, [useIsFocused()]);

    useEffect(() => {
        if(uid!==undefined) {
            noticeInstance.mutate(uid,{
                onSuccess: ({data})=> {
                    if(data.status) {
                        const _data = data.data
                        setBoardData(_data)
                    }else {
                        alert('잘못된 요청입니다')
                        navigation.pop()
                    }
                }
            })
        }
    }, [uid]);

    return (
        <View style={{height: innerHeight}}>
            <PublicScripts/>
            <MenuWrapper
                navigation={navigation}
                route={route}
                titleView={(
                    <>
                        <Text style={styles.titleText}>{boardData!==undefined&&boardData.title}</Text>
                        <Text style={styles.titleSubText}>{boardData!==undefined&&getDate(boardData.regDate)}</Text>
                    </>
                )}
                contentView={(
                    <>
                        <Text style={styles.contentText}>
                            {boardData!==undefined&&boardData.content}
                        </Text>
                    </>
                )}
                buttonItem={{
                    title:'목록으로',
                    action: ()=>{
                        navigation.pop()
                    }
                }}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(19),
        fontWeight: '700',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(14)
    },
    titleSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },
    contentText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '300',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(20)
    }
})

export default MenuNoticeDetail
