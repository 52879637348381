import React from "react";
import {PixelRatio, StyleSheet, View, Text, Pressable, Image} from "react-native";
import {boardResponse} from '../../api/entity'
import arrow_right from '../../assets/images/arrow_right_black.png'
import {MenuName} from "../public/MenuName.ts";


function BoardListItem({data, navigation, isLogin}):boardResponse {
    return (
        <Pressable
            style={[styles.boardItemWrapper,{flexDirection: 'row'}]}
            onPress={()=>{
                if (isLogin) {
                    navigation.push('Answer',{menu: MenuName.AllQuestionAnswer, question: data})
                }else {
                    alert(`로그인 페이지로 이동합니다`)
                    navigation.push('Login')
                }
            }}
        >
            <Text
                style={[styles.text,{flex: 1}]}
                numberOfLines={1}
            >
                {data.title}
            </Text>

            <Image style={styles.arrow} source={arrow_right}/>
        </Pressable>
    )
}

const styles = StyleSheet.create({

    boardItemWrapper: {
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(24),
        height:PixelRatio.getPixelSizeForLayoutSize(58),
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '700',
    },
    arrow: {
        width: PixelRatio.getPixelSizeForLayoutSize(7.5),
        height: PixelRatio.getPixelSizeForLayoutSize(12),
    },
});

export default BoardListItem;
