import React, {Component, useEffect} from "react";
import {useIsFocused} from "@react-navigation/native";


function PublicScripts(){

    const isFocused = useIsFocused()
    useEffect(() => {
        if(isFocused) {
            const script = document.createElement('script')
            script.src = '//wcs.naver.net/wcslog.js'
            script.onload=()=>{
                if(window.wcs_add) var wcs_add = {}
                wcs_add["wa"] = "19ecc33cde64e00";
                if(window.wcs) {
                    wcs_do();
                }
            }

            document.body.appendChild(script)
        }
    }, [useIsFocused()]);

    return (
        <></>
    )
}

export default PublicScripts
