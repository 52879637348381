import React, {useEffect, useState} from "react";
import {FlatList, Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import {isDesktop} from "react-device-detect";
import {publicWrapper} from '../components/public/PublicStyle'
import x_icon from '../assets/images/x_icon.svg'
import {useMutation} from "react-query";
import client from "../api/client.ts";
import {notice} from '../api/urls'
import getDate from '../components/public/getDate'
import {noticeReadRequest, noticeResponse, responseWrapper} from "../api/entity";
import {useIsFocused} from "@react-navigation/native";
import checkLogin from "../components/public/checkLogin.ts";
import PublicScripts from "../components/public/PublicScripts";

function MenuNotice({navigation, route}){

    const boardInstance = useMutation({
        mutationFn: (data: noticeReadRequest)=> client.post<responseWrapper<noticeResponse[]>>(notice.read, data)
    })
    const [boardData, setBoardData] = useState([])
    const [lastUid, setLastUid] = useState()


    const isFocused = useIsFocused()
    useEffect(() => {
        if(isFocused) {
            const request: noticeReadRequest = {
                lastUid: undefined
            }
            boardInstance.mutate(request,{
                onSuccess: ({data})=> {
                    if(data.status){
                        const _data = data.data
                        const length = _data.length>0?_data.length:undefined
                        if(length!=undefined) {
                            setBoardData(_data)
                            setLastUid(_data[length-1].uid)
                        }
                    }
                }
            })
        }
    }, [useIsFocused()]);

    return (
        <View style={{height: window.innerHeight}}>
            <PublicScripts/>
            <View style={[styles.wrapper, isDesktop&&publicWrapper.deskTopWrapper]}>
                <View style={styles.horizontalWrapper}>
                    <View style={{flex: 1}}/>
                    <Pressable onPress={()=>navigation.pop()}>
                        <Image source={x_icon} style={styles.x_icon}/>
                    </Pressable>
                </View>
                <View>
                    <Text style={styles.titleText}>공지사항</Text>
                    <Text style={styles.titleSubText}>총 <Text style={styles.titleColorText}>{boardData.length}</Text> 건</Text>
                </View>

                <FlatList
                    style={styles.listWrapper}
                    contentContainerStyle={styles.listWrapper}
                    data={boardData}
                    renderItem={({item})=>{
                        return (
                            <Pressable
                                style={styles.itemWrapper}
                                onPress={()=> {
                                    navigation.push('MenuNoticeDetail',{uid: item.uid})
                                }}
                            >
                                <Text style={styles.itemTitleText}>{item.title}</Text>
                                <Text style={styles.itemSubText}>{getDate(item.regDate)}</Text>
                            </Pressable>
                        )
                    }}
                    onEndReached={()=>{
                        const request:noticeReadRequest = {
                            lastUid: lastUid
                        }
                        boardInstance.mutate(request,{
                            onSuccess: ({data})=> {
                                if(data.status){
                                    const _data = data.data
                                    const length = _data.length>0?_data.length:undefined
                                    if(length!=undefined) {
                                        setBoardData([...boardData, ..._data])
                                        setLastUid(_data[length-1].uid)
                                    }
                                }
                            }
                        })
                    }}
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        minHeight: '100%',
        padding: PixelRatio.getPixelSizeForLayoutSize(16)
    },
    horizontalWrapper: {
        flexDirection: 'row'
    },
    x_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
    },
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(28),
        fontWeight: '700',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(30)
    },
    titleSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12)
    },
    titleColorText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '700',
    },
    listWrapper:{
        height: 100,
        minHeight: 'auto',
    },
    itemWrapper: {
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
        borderTopWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderTopColor: '#eee'
    },
    itemTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(8)
    },
    itemSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    }
})

export default MenuNotice
