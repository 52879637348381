import React, {useEffect, useRef, useState} from "react";
import {FlatList, Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import PublicTopMenu from '../components/public/PublicTopMenu.tsx'
import {publicWrapper} from '../components/public/PublicStyle'
import {boardReadRequest, boardResponse, categoryResponse, favoriteRequest, responseWrapper} from '../api/entity'
import reportIcon from '../assets/images/report.png'
import arrow_left from '../assets/images/arrow_left.png'
import {useMutation} from "react-query";
import client from "../api/client.ts";
import {board,favorite} from '../api/urls'
import getDate from '../components/public/getDate'
import CheckLogin from "../components/public/checkLogin.ts";
import heart_fill  from '../assets/images/heart_fill.png'
import heart_stroke from '../assets/images/heart_stroke.png'
import reply_icon  from '../assets/images/reply.png'
import view_icon from '../assets/images/eye.png'
import reply_icon_blue from '../assets/images/reply2.png'
import check_icon from '../assets/images/check.png'
import ellipse_icon from '../assets/images/ellipse.png'
import good_icon_fill from '../assets/images/good_fill.png'
import good_icon_stroke from '../assets/images/good_stroke.png'
import QuestionView from '../components/public/QuestionView.tsx'
import ReportMenu from '../components/public/ReportMenu.tsx'
import checkLogin from "../components/public/checkLogin.ts";
import {useIsFocused} from "@react-navigation/native";
import {isDesktop} from "react-device-detect";
import AsyncStorage from "@react-native-async-storage/async-storage";


function Answer({navigation, route}){


    const boardRef = useRef()

    const  question = route.params?.question
    if (question === undefined) {return (<Text>잘못 들어왔어요 </Text>)}

    const categoryInstance = useMutation({
        mutationFn:()=> client.post<categoryResponse[]>(board.post.getCategoryCode)
    })
    const answerInstance = useMutation({
        mutationFn: (data: boardReadRequest)=> client.post<responseWrapper<boardResponse[]>>(board.get.read(board.categoryType.answer),data),
    })

    const [uid,setUid] = useState(question.uid)
    const [sorting, setSorting] = useState('uid')
    const [lastUid,setLastUid] = useState(undefined)

    const [answerData,setAnswerData] = useState<boardResponse[]>([])



    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused){
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }
            })
            setUid(question.uid)
            const answerRequest: boardReadRequest = {
                parentUid: uid,
                sorting: sorting,
            }
            answerInstance.mutate(answerRequest, {
                onSuccess: ({data})=> {
                    if(data.status) {
                        const _data = data.data
                        setAnswerData(_data)
                        const length = _data.length > 0? _data.length-1 : undefined
                        if (length !== undefined) {
                            setLastUid(_data[length].uid)
                        }
                    }

                }
            })
        }
    },[useIsFocused()])

    useEffect(() => {
        const question = route.params.question
        if(question !== undefined) {
            setUid(question.uid)
        }

    }, [route.params.question]);

    useEffect(()=>{
        boardRef.current.scrollToOffset({offset:0})
        const answerRequest: boardReadRequest = {
            parentUid: uid,
            sorting: sorting,
        }
        answerInstance.mutate(answerRequest, {
            onSuccess: ({data})=> {
                if(data.status) {
                    const _data = data.data
                    setAnswerData(_data)
                    const length = _data.length > 0? _data.length-1 : undefined
                    if (length !== undefined) {
                        setLastUid(_data[length].uid)
                    }
                }

            }
        })
    },[sorting,uid])

    return(
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <ReportMenu navigation={navigation} route={route} category={'question'} articleData={question}/>
            <View style={[publicWrapper.backgrounded,{padding: 0}]}>
                <QuestionView navigation={navigation} route={route} questionUid={uid}/>
                <View style={[styles.answerMenuWrapper,styles.horizontalWrapper]}>
                    <Image style={styles.answerMenuIcon} source={reply_icon_blue}/>
                    <Text style={styles.answerMenuMiddleText}>답글</Text>
                    <View style={{flex:1}}/>
                    {/* 정렬 버튼*/}
                    <Pressable
                        style={styles.horizontalWrapper}
                        onPress={()=>{
                            setSorting('uid')
                        }}
                    >
                        <Image
                            style={sorting==='uid'?styles.answerMenuSelectedSortIcon:styles.answerMenuSortIcon}
                            source={sorting==='uid'?check_icon:ellipse_icon}
                        />
                        <Text style={sorting==='uid' ? styles.answerMenuSelectedSortText:styles.answerMenuSortText}>최신순</Text>
                    </Pressable>
                    <Pressable
                        style={styles.horizontalWrapper}
                        onPress={()=>{
                            setSorting('favorite')
                        }}
                    >
                        <Image
                            style={sorting==='favorite'?styles.answerMenuSelectedSortIcon:styles.answerMenuSortIcon}
                            source={sorting==='favorite'?check_icon:ellipse_icon}
                        />
                        <Text style={sorting==='favorite'?styles.answerMenuSelectedSortText:styles.answerMenuSortText}>추천순</Text>
                    </Pressable>

                </View>

                {/*Answer 부분*/}
                <FlatList
                    ref={boardRef}
                    style={styles.answerListWrapper}
                    data={answerData}
                    renderItem={({item})=>{
                        const answerDate = getDate(item.regDate)
                        const blindCount = item.whoIsReport===null?0:item.whoIsReport.length
                        if(item.blindCount <= blindCount) {
                            return <></>
                        }
                        return(
                            <Pressable
                                style={[styles.answerListItemWrapper,item.isPublic===0&&{backgroundColor: '#eee'}]}
                                onPress={()=>{
                                    navigation.push('AnswerDetail', {answer: item})
                                }}
                            >
                                <Text
                                    style={styles.answerListTitleText}
                                    numberOfLines={1}
                                >
                                    {item.content}
                                </Text>
                                <View style={styles.horizontalWrapper}>
                                    <Text style={styles.answerListSubText}>{item.nickname}</Text>
                                    <View style={styles.separatorHorizontal}/>
                                    <Text style={styles.answerListSubText}>{answerDate}</Text>
                                </View>
                            </Pressable>
                        )
                    }}
                    onEndReached={()=>{
                        const request: boardReadRequest = {
                            sorting: sorting,
                            parentUid: uid,
                            lastUid: lastUid
                        }

                        answerInstance.mutate(request,{
                            onSuccess: ({data})=> {
                                if(data.status) {
                                    const _data = data.data
                                    const length = _data.length > 0? _data.length-1 : undefined
                                    if (length !== undefined) {
                                        setAnswerData([...answerData,..._data])
                                        setLastUid(_data[length].uid)
                                    }
                                }

                            }
                        })
                    }}
                />

                <Pressable
                    style={styles.bottomButtonWrapper}
                    onPress={()=>{
                        navigation.push('WriteAnswer', {question: question})
                    }}
                >
                    <Text style={styles.bottomButtonText}>질문에 답글 바로 달기</Text>
                </Pressable>

            </View>
        </View>

    )

}

const styles = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
       separatorHorizontal: {
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        backgroundColor:  '#DDDDDD',
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8),
    },
    separatorVertical: {
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        backgroundColor:  '#DDDDDD',
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(14),
        height: PixelRatio.getPixelSizeForLayoutSize(14),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    bottomButtonWrapper: {
        backgroundColor: '#2C4198',
        height: PixelRatio.getPixelSizeForLayoutSize(50),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(8),
        margin: PixelRatio.getPixelSizeForLayoutSize(15),
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomButtonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
        textAlign: 'center'
    },
    answerMenuWrapper: {
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(50),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#DDDDDD',
    },
    answerMenuIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(22),
        height: PixelRatio.getPixelSizeForLayoutSize(22),
    },
    answerMenuMiddleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
    },

    answerMenuSortText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    answerMenuSelectedSortText: {
        color: 'black',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    answerMenuSortIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(4),
        height: PixelRatio.getPixelSizeForLayoutSize(4),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    answerMenuSelectedSortIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(12),
        height: PixelRatio.getPixelSizeForLayoutSize(12),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    answerListWrapper: {
        flex: 1,
        backgroundColor: 'white',
    },
    answerListItemWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(61),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(5),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#E5E5E5',
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12),
    },
    answerListTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(1),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12),
    },
    answerListSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },


})

export default Answer;
