import React, {useEffect, useState} from "react";
import {FlatList, Modal, PixelRatio, StyleSheet, Text, TextInput, Pressable, View} from "react-native";
import {useDaumPostcodePopup} from "react-daum-postcode";
import {postcodeScriptUrl} from "react-daum-postcode/lib/loadPostcode";
import {Picker} from "react-native-web";
import PickerItem from "react-native-web/dist/exports/Picker/PickerItem";
import {categoryResponse} from "../../api/entity";
import {useIsFocused} from "@react-navigation/native";
function SignupData({title, placeHolder, value, setValue, isPassword}) {
    return(
        <>
            <View style={styles.titleWrapper}>
                <Text style={styles.defaultText}>{title}</Text>
                <Text style={styles.starText}> *</Text>
            </View>
            <TextInput
                style={[styles.textInput,styles.defaultText]}
                onChangeText={setValue}
                value={value}
                placeholder={placeHolder}
                placeholderTextColor={'#999999'}
                secureTextEntry={isPassword}
            />
        </>
    );
}

function SignupBirthAgeData({title, value, setValue}) {
    return(
        <>
            <View style={styles.titleWrapper}>
                <Text style={styles.defaultText}>{title}</Text>
                <Text style={styles.starText}> *</Text>
            </View>
            <TextInput
                style={[styles.textInput,styles.defaultText]}
                onChangeText={setValue}
                value={value}
                placeholder={'생년 4자리를 입력하세요'}
                placeholderTextColor={'#999999'}
                maxLength={4}
            />
        </>
    );
}

function SignupDataAddress({title, value, setValue, setZipcode}) {

    const open = useDaumPostcodePopup(postcodeScriptUrl)

    const onComplete = (data)=> {
        setValue(data.address)
        //setZipcode(data.zonecode)
    }

    return(
        <View>
            <View style={styles.titleWrapper}>
                <Text style={styles.defaultText}>{title}</Text>
                <Text style={styles.starText}> *</Text>
            </View>
            <TextInput
                onPointerUp={()=>open({onComplete: onComplete})}
                style={[styles.textInput,styles.defaultText]}
                onChangeText={setValue}
                value={value}
                placeholder={'주소를 입력하세요'}
                placeholderTextColor={'#999999'}
                editable={false}
            />
        </View>
    );
}

function SignupEmailData({title, value, setValue}) {

    const [emailId,setEmailId] = useState('')
    const [emailDomain,setEmailDaomain] =useState('')

    useEffect(()=>{
        setValue(`${emailId}@${emailDomain}`)
    },[emailId, emailDomain])

    return(
        <View>
            <View style={styles.titleWrapper}>
                <Text style={styles.defaultText}>{title}</Text>
                <Text style={styles.starText}> *</Text>
            </View>
            <View style={[styles.horizontalWrapper]}>
                <TextInput
                    style={[styles.textInput]}
                    onChangeText={setEmailId}
                    value={emailId}
                />
                <Text style={[styles.defaultText,{marginHorizontal: 5}]}>@</Text>
                <TextInput
                    style={[styles.textInput]}
                    onChangeText={setEmailDaomain}
                    value={emailDomain}
                />
            </View>
        </View>
    );
}

function SignupPhoneNumberData({title, value, setValue}) {

    const [frontNum,setFrontNum] = useState('010')
    const [middleNum,setMiddleNum] = useState('')
    const [backNum,setBackNum] =useState('')

    const isFocused = useIsFocused()
    useEffect(()=>{
        if (isFocused) {
            if (value !== '') {
                setFrontNum(value.substring(0,3))
                setMiddleNum(value.substring(3,7))
                setBackNum(value.substring(7,11))
            }
        }
    },[])

    useEffect(()=>{
        setValue(`${frontNum}${middleNum}${backNum}`)
    },[frontNum, middleNum, backNum])

    return(
        <View>
            <View style={styles.titleWrapper}>
                <Text style={styles.defaultText}>{title}</Text>
                <Text style={styles.starText}> *</Text>
            </View>
            <View style={[styles.horizontalWrapper]}>
                <TextInput
                    style={[styles.textInput,styles.defaultText, {textAlign: 'center'}]}
                    keyboardType={"numeric"}
                    onChangeText={(v)=>{
                        setFrontNum(v.replace(/[^0-9]/g,''))
                    }}
                    value={frontNum}
                    maxLength={3}
                />
                <Text style={[styles.defaultText,{marginHorizontal: 5}]}>-</Text>
                <TextInput
                    style={[styles.textInput,styles.defaultText, {textAlign: 'center'}]}
                    keyboardType={"numeric"}
                    onChangeText={(v)=>{
                        setMiddleNum(v.replace(/[^0-9]/g,''))
                    }}
                    value={middleNum}
                    maxLength={4}
                />
                <Text style={[styles.defaultText,{marginHorizontal: 5}]}>-</Text>
                <TextInput
                    style={[styles.textInput,styles.defaultText, {textAlign: 'center'}]}
                    keyboardType={"numeric"}
                    onChangeText={(v)=>{
                        setBackNum(v.replace(/[^0-9]/g,''))
                    }}
                    value={backNum}
                    maxLength={4}
                />
            </View>
        </View>
    );
}

function SignupPickerData({title, value, setValue, etcValue ,setEtcValue, data}) {

    const middleString = title==='취미'?'를':'을'

    const [selectValue, setSelectValue] = useState(value)
    const [isEtc,setIsEtc] = useState(value===24?true:false)
    const firstData: categoryResponse = {description: `${title}${middleString} 입력하세요` ,code: -1}

    return(
        <View>
            <View style={styles.titleWrapper}>
                <Text style={styles.defaultText}>{title}</Text>
                <Text style={styles.starText}> *</Text>
            </View>
            <Picker
                style={styles.picker}
                selectedValue={selectValue}
                onValueChange={(v, i)=> {
                    if(v === '24') {
                        setValue(Number(v))
                        setSelectValue(v)
                        setIsEtc(true)
                    }else {
                        setValue(Number(v))
                        setSelectValue(v)
                        setIsEtc(false)
                        setEtcValue('')
                    }
                }}
            >
                {data.map((v: categoryResponse)=>{
                    return (
                        <PickerItem
                            label={`${v.description}`}
                            value={v.code}
                            key={v.code}
                        />
                    )
                })}
            </Picker>
            {isEtc&&(<TextInput
                style={[styles.textInput,styles.defaultText]}
                onChangeText={setEtcValue}
                value={etcValue}
                placeholderTextColor={'#999999'}
            />)}
        </View>
    );
}

function SignupSexData({value, setValue}) {
    return(
        <View>
            <View style={styles.titleWrapper}>
                <Text style={styles.defaultText}>성별</Text>
                <Text style={styles.starText}> *</Text>
            </View>
            <Picker
                style={styles.picker}
                selectedValue={value}
                onValueChange={(v, i)=> {
                    setValue(v)
                }}
            >
                <PickerItem label={'남'} value={0} key={0}/>
                <PickerItem label={'여'} value={1} key={1}/>
                <PickerItem label={'기타'} value={2} key={2}/>
            </Picker>
        </View>
    );
}

function SignupBirthYearData({value, setValue}) {

    const year = new Date().getFullYear() - 15
    let years = []
    for (let i = 1; i < 75; i++) {
        years.push(year-i)
    }

    return(
        <View>
            <View style={styles.titleWrapper}>
                <Text style={styles.defaultText}>생년</Text>
                <Text style={styles.starText}> *</Text>
            </View>
            <Picker
                style={styles.picker}
                selectedValue={value}
                onValueChange={(v, i)=> {
                    setValue(v)
                }}
            >
                {
                    years.map((v=><PickerItem label={v} value={v}/>))
                }
            </Picker>
        </View>
    );
}


const styles = StyleSheet.create({
    titleWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(11)
    },
    defaultText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    starText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#DD2E44',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        verticalAlign: 'top',
    },
    smallText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(10),
        fontWeight: 'bold',
    },
    textInput: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        fontFamily: 'KoPub_Dotum_Pro',
        minWidth: PixelRatio.getPixelSizeForLayoutSize(36),
        flex: 1,
        marginTop: PixelRatio.getPixelSizeForLayoutSize(10),
        height: PixelRatio.getPixelSizeForLayoutSize(44),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#DDD',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        padding: PixelRatio.getPixelSizeForLayoutSize(17),
    },
    picker: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        fontFamily: 'KoPub_Dotum_Pro',
        height: PixelRatio.getPixelSizeForLayoutSize(44),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(17),
        marginTop: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    }
})


export {SignupDataAddress, SignupData, SignupEmailData, SignupPickerData, SignupPhoneNumberData, SignupBirthAgeData, SignupSexData, SignupBirthYearData}
