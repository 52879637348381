import React, {useEffect, useState} from "react";
import {Image, Modal, PixelRatio, ScrollView, StyleSheet, Text, Pressable, View, Linking} from "react-native";
import xImage from '../assets/images/x_white.svg'
import closeIcon from '../assets/images/x_icon.svg'
import arrow_right from '../assets/images/arrow_right.svg'
import logo_icon from '../assets/images/menu_logo.svg'
import logout_icon from '../assets/images/icon - logout.svg'
import edit_icon from '../assets/images/icon - edit.svg'
import {publicWrapper} from '../components/public/PublicStyle'
import client, {cleanToken} from "../api/client.ts";
import {user,notice} from '../api/urls'
import {useMutation} from "react-query";
import {responseWrapper, termsRequest, termsResponse, userEntity, userResponse} from "../api/entity";
import userContext from "../context/userContext.ts";
import info_icon from '../assets/images/icon - info.svg'
import notice_icon from '../assets/images/icon - notice.svg'
import inquiry_icon from '../assets/images/icon - inquiry.svg'
import {useIsFocused} from "@react-navigation/native";
import {isDesktop} from "react-device-detect";
import AsyncStorage, {useAsyncStorage} from "@react-native-async-storage/async-storage";
import RenderHTML from "react-native-render-html";
import PublicScripts from "../components/public/PublicScripts";
import UserContext from "../context/userContext.ts";

function Menu({navigation}){

    const [isVisible, setIsVisible] = useState(false)
    const [modalContent, setModalContent] = useState('Default Text')
    const [modalTitle, setModalTitle] = useState('Title')
    const [height,setHeight] = useState()

    const [isLogin,setIsLogin] = useState(false)
    const logoutInstance = useMutation({
        mutationFn:()=>{
            return client.post<responseWrapper<any>>(user.get.logout)
        },
        onSuccess:({data})=>{
            if(data.status) {
                cleanToken()
                /*setIsLogin(false)
                userContext.setUser(undefined)
                userContext.setRefreshToken(undefined)
                AsyncStorage.clear()*/
            }
        }, onError: error => {
            console.log('logout_err')
            console.log(error)
        }
    })
    const [nickname, setNickname] = useState('');
    const isFocused = useIsFocused()
    useEffect(()=>{
        if (isFocused) {
            if (userContext.getUser !== undefined) {
                const nickname = userContext.getUser.nickname
                setNickname(nickname)
                setIsLogin(true)
            }else {
                setIsLogin(false)
            }
            setHeight(window.innerHeight)

        }
    },[useIsFocused()])



    const UnLoginView = (
        <Pressable style={styles.menuLoginFalseWrapper} onPress={()=>navigation.push('Login')}>
            <Text style={styles.menuText} >{'로그인 및 회원가입'}</Text>
            <Image style={styles.arrow_icon} source={arrow_right}/>
        </Pressable>
    )
    const LoginView = (
        <View style={[ styles.menuLoginTrueWrapper]}>

            <Pressable
                style={[styles.buttonBackground,{flex: 0.6}]}
                onPress={()=>navigation.push('Modify')}
            >
                <Image style={styles.buttonIcon} source={edit_icon}/>
                <Text style={styles.buttonText} numberOfLines={1}>
                    개인정보수정
                </Text>
            </Pressable>

            <View style={{width: PixelRatio.getPixelSizeForLayoutSize(14)}} />
            <Pressable
                style={[styles.buttonBackground, {flex: 0.4}]}
                onPress={()=> {
                    logoutInstance.mutate()
                    setIsLogin(false)
                    userContext.setUser(undefined)
                    userContext.setRefreshToken(undefined)
                    AsyncStorage.clear()
                }
            }
            >
                <Image style={styles.buttonIcon} source={logout_icon}/>
                <Text style={styles.buttonText} numberOfLines={1}>
                    로그아웃
                </Text>
            </Pressable>
        </View>
    )

    const termsInstance = useMutation({
        mutationFn: (data: termsRequest) => client.post<responseWrapper<termsResponse>>(notice.termsOfService,data),
        onSuccess: ({data}) => {
            if(data.status) {
                const _data = data.data
                setModalTitle(_data.title)
                setModalContent(_data.content)
                setIsVisible(true)
            }
        }
    })


    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicScripts/>
            <Modal visible={isVisible}>
                <View  style={[{height: height},isDesktop&&publicWrapper.deskTopWrapper]}>

                    <View style={modalStyles.closeWrapper}>
                        <Pressable onPress={()=>setIsVisible(false)}>
                            <Image source={closeIcon} style={modalStyles.closeIcon} />
                        </Pressable>

                    </View>
                    <Text style={modalStyles.titleText}>{modalTitle}</Text>
                    <ScrollView>
                        <RenderHTML source={{html: modalContent}}/>
                    </ScrollView>

                </View>

            </Modal>

            <View style={styles.topWrapper}>{/*상단 매뉴*/}
                <View style={styles.horizonWrapper}>
                    <View style={styles.bannerWrapper}>
                        <Image
                            style={[styles.bannerIcon]}
                            source={logo_icon}
                        />
                    </View>
                    <View style={{flex: 1}}/>
                    <Pressable onPress={()=>navigation.push('Main')}>
                        <Image source={xImage} style={styles.xButton}/>
                    </Pressable>
                </View>
                <View style={styles.menuUserWrapper}>
                    <Text style={[styles.lineWrapper,styles.menuText]}>{isLogin&&`${nickname}님 반갑습니다`}</Text>
                </View>
                {isLogin?LoginView:UnLoginView}
            </View>
            <View style={publicWrapper.backgrounded}>
                <View style={styles.menuWrapper}>
                    <Pressable
                        style={styles.horizonWrapper}
                        onPress={()=>{
                            /*const request: termsRequest = {
                                title: "사이트소개"
                            }
                            termsInstance.mutate(request)*/
                            Linking.openURL('https://1halfkr.notion.site/1halfkr/36628429d6fb4015bb5d94bd4e9d0a7a')
                        }}
                    >
                        <Image style={styles.icon} source={info_icon}/>
                        <Text style={[styles.listText]}>사이트소개</Text>
                    </Pressable>
                    <Pressable
                        style={styles.horizonWrapper}
                        onPress={()=>navigation.push('MenuNotice')}
                    >
                        <Image style={styles.icon} source={notice_icon}/>
                        <Text style={[styles.listText]}>공지사항</Text>
                    </Pressable>
                    <Pressable
                        style={styles.horizonWrapper}
                        onPress={()=>navigation.push('MenuInquireList')}
                    >
                        <Image style={styles.icon} source={inquiry_icon}/>
                        <Text style={[styles.listText]}>1:1문의</Text>
                    </Pressable>
                </View>
                <View style={styles.menuWrapper}>
                    <Pressable
                        style={styles.horizonWrapper}
                        onPress={()=>{
                            /*const request: termsRequest = {
                                title: "개인정보보호"
                            }
                            termsInstance.mutate(request)*/
                            Linking.openURL('https://1halfkr.notion.site/56ed30039f05455c98863c2c5306dc15')
                        }}
                    >
                        <Text style={[styles.lineWrapper,styles.listText]}>개인정보취급방침</Text>
                    </Pressable>
                    <Pressable
                        style={styles.horizonWrapper}
                        onPress={()=>{
                            /*const request: termsRequest = {
                                title: "운영약관"
                            }
                            termsInstance.mutate(request)*/
                            Linking.openURL('https://1halfkr.notion.site/fc747437f8a74d96960f25538a687740')
                        }}
                    >
                        <Text style={[styles.lineWrapper,styles.listText]}>회원이용약관</Text>
                    </Pressable>
                </View>

            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    topWrapper:{
        width: '100%',
        backgroundColor: '#2C4198',
    },
    horizonWrapper: {
        flexDirection:'row',
        alignItems: 'center',
    },
    bannerWrapper: {
        marginTop: PixelRatio.getPixelSizeForLayoutSize(24),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(20),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(16),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loginMenuWrapper: {
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(22),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(4),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(26),
    },
    title: {
        fontFamily: 'SCDream5',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '600',
        flex: 1,
        margin: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    bannerIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(107.5),
        height: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    xButton: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
        margin: PixelRatio.getPixelSizeForLayoutSize(16),
    },
    arrow_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(24),
        height: PixelRatio.getPixelSizeForLayoutSize(24),
    },
    menuUserWrapper: {
        marginTop: PixelRatio.getPixelSizeForLayoutSize(16),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(23),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12)

    },
    menuText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(22),
        fontWeight: '700',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(44),
    },
    menuLoginTrueWrapper: {
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(20),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(17),
        flexDirection: 'row',
        alignItems: 'center',
    },
    menuLoginFalseWrapper: {
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(22),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(26),
        flexDirection: 'row',
        alignItems: 'center'
    },
    buttonIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(12),
        height: PixelRatio.getPixelSizeForLayoutSize(12),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    buttonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '700',
        textAlign: 'center',
    },
    buttonBackground: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(14),
        backgroundColor: '#dddddd33',
        flex: 1,
    },
    icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(24),
        height: PixelRatio.getPixelSizeForLayoutSize(24),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    lineWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(44),
    },
    menuWrapper: {
        marginTop: PixelRatio.getPixelSizeForLayoutSize(10),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(10),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(20),
        backgroundColor: 'white',
    },
    listText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(22),
        fontWeight: '500',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(44),
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(5),
    },
})

const modalStyles = StyleSheet.create({
    closeWrapper: {
        alignItems: 'flex-end',
        padding: PixelRatio.getPixelSizeForLayoutSize(16)
    },
    closeIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
    },
    wrapper: {
        padding: PixelRatio.getPixelSizeForLayoutSize(16),
        flex: 1,
    },
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(28),
        fontWeight: '700',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    contentText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '500',
        flex: 1,
    }
})

export default Menu;
