import {useMutation} from "react-query";
import client from "./client.ts";
import {favoriteRequest, favoriteResponse, responseWrapper} from "./entity";
import {favorite} from '../api/urls'
import {useEffect} from "react";

function FavoriteConnection(category,isfavorite,uid){

    const request: favoriteRequest = {
        uid: uid
    }
    if(isfavorite) {
        return useMutation({
            mutationFn:()=> client.post<responseWrapper<any>>(favorite.post.remove(category),request)
        })
    }else{
        return useMutation({
            mutationFn:()=> client.post<responseWrapper<favoriteResponse>>(favorite.post.add(category),request),
        })
    }
}

export default FavoriteConnection
