import React, {useEffect, useState} from "react";
import {Image, View, StyleSheet, Text, TextInput, FlatList, Button, Alert} from "react-native";
import {CheckBox, PixelRatio, Pressable} from "react-native-web";
import {
    SignupBirthAgeData, SignupBirthYearData,
    SignupData,
    SignupDataAddress,
    SignupEmailData,
    SignupPhoneNumberData,
    SignupPickerData, SignupSexData
} from "../components/signup/SignupItem.tsx";
import client from "../api/client.ts";
import {useMutation} from "react-query";
import {categoryResponse, responseWrapper, userEntity, userResponse} from "../api/entity";
import {user} from '../api/urls'
import isEmailValidation from '../components/signup/isEmailValidation'
import xImage from '../assets/images/x_icon.svg'
import bannerIcon from '../assets/images/logo_2.svg'
import {useIsFocused} from "@react-navigation/native";
import {publicWrapper} from '../components/public/PublicStyle'
import {isDesktop} from "react-device-detect";
import userContext from "../context/userContext.ts";
import PublicScripts from "../components/public/PublicScripts.jsx";

function ModifyUserContext({navigation, route}){
    const userdata = userContext.getUser

    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('')
    const [name, setName] = useState(userdata.username);
    const [phoneNumber, setPhoneNumber] = useState(userdata.phoneNumber);
    const [address,setAddress] = useState(userdata.address);
    const [zipCode,setZipCode] = useState('');
    const [nickname, setNickname] = useState(userdata.nickname);
    const [birthYear, setBirthYear] = useState<number>(userdata.birthyear);
    const [homeTown, setHomeTown] = useState(userdata.hometown);
    const [sex,setSex] = useState<number>(userdata.sex)

    const [job,setJob] = useState<number>(userdata.job);
    const [hobby,setHobby] = useState<number>(userdata.hobby);
    const [morejob,setMoreJob] = useState(userdata.jobMore);
    const [morehobby,setMoreHobby] = useState(userdata.hobbyMore);

    const [nicknameIsOk,setNicknameIsOK] = useState(false)
    const [phoneNumberIsOk, setPhoneNumberIsOk] = useState(false)

    const [jobCategory,setJobCategory] = useState([])

    const jobCategoryInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<categoryResponse[]>>(user.getJob),
        onSuccess: ({data})=>{
            if(data.status) {
                const _data = data.data
                setJob(_data[0].code)
                setJobCategory(_data)
            }
        }
    })
    const [hobbyCategory,setHobbyCategory] = useState([])

    const hobbyCategoryInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<categoryResponse[]>>(user.getHobby),
        onSuccess: ({data})=>{
            if(data.status) {
                const _data = data.data
                setHobby(_data[0].code)
                setHobbyCategory(_data)
            }
        }
    })

    const isFocused = useIsFocused()
    useEffect(()=> {
        if(isFocused) {
            hobbyCategoryInstance.mutate()
            jobCategoryInstance.mutate()
        }
    },[useIsFocused()])

    const updateInstance = useMutation({
        mutationFn: (data: userEntity)=>{
            return client.post<responseWrapper<userResponse>>(user.post.updateUser, data)
        }
    })

    const nicknameCheck = useMutation({
        mutationFn: (data: {nickname: string})=>{
            return client.post<responseWrapper<boolean>>(user.post.isNicknameCanBeUsed, data)
        }
    })
    const phoneNumberCheck = useMutation({
        mutationFn: (data: {phonenumber: string})=> client.post<responseWrapper<boolean>>(user.post.isPhoneNumberCanBeUsed, data)
    })
    const backButton = ()=>{
        navigation.push('Menu')
    };

    useEffect(() => {
        if (nicknameIsOk&&phoneNumberIsOk) {
            const data: userEntity = {
                username: name,
                id: userdata.id,
                password: password,
                email: userdata.email,
                phoneNumber: phoneNumber,
                address: `${address}`,
                job: job,
                jobMore: job===24?morejob:undefined,
                hobby: hobby,
                hobbyMore: hobby===24?morehobby:undefined,
                nickname: nickname,
                sex: sex,
                hometown: homeTown,
                birthyear: birthYear,
                bookSalon: userdata.bookSalon,
                role: '000001',
            }

            updateInstance.mutate(data,{
                onSuccess: ({data})=>{
                    if(data.status) {
                        alert(`개인정보수정이 완료 되었습니다`)
                        userContext.setUser(data.data)
                        setNicknameIsOK(false)
                        navigation.push('Main')
                    }

                }
            })
        }
    }, [nicknameIsOk, phoneNumberIsOk]);

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicScripts/>
            {/*돌아가기*/}
            <Pressable
                style={styles.xButtonWrapper}
                onPress={backButton}>
                <Image style={styles.xButton} source={xImage}/>
            </Pressable>
            <View style={[styles.bannerWrapper]}>
                {/*이미지 위치*/}
                <View style={styles.horizonWrapper}>
                    <Image style={styles.bannerImage} source={bannerIcon}/>
                </View>
                <Text style={styles.loginText}>
                    회원정보변경
                </Text>
            </View>


            <View style={styles.dataFormWrapper}>
                {/*Signup Data From*/}
                <SignupData title={'닉네임'} value={nickname} setValue={setNickname} placeHolder={''}
                            isPassword={undefined}/>
                <SignupData title={'비밀번호'} value={password} setValue={setPassword} placeHolder={'비밀번호를 입력해주세요'} isPassword={true} />
                <SignupData title={'비밀번호 확인'} value={checkPassword} setValue={setCheckPassword} placeHolder={'비밀번호를 입력해주세요'} isPassword={true} />
                <SignupData title={'이름'} value={name} setValue={setName} placeHolder={name}
                            isPassword={undefined}/>

                <SignupPhoneNumberData title={'휴대전화번호'} value={phoneNumber} setValue={setPhoneNumber}/>
                <SignupBirthYearData value={birthYear} setValue={setBirthYear}/>
                <SignupDataAddress
                    title={'주소'}
                    value={address}
                    setValue={setAddress}
                    setZipcode={setZipCode}
                />
                <SignupData title={'고향'} value={homeTown} setValue={setHomeTown} placeHolder={'고향을 입력 하세요'} isPassword={false} />
                <SignupSexData value={sex} setValue={setSex}/>
                <SignupPickerData title={'직업'} value={job} setValue={setJob} etcValue={morejob} setEtcValue={setMoreJob} data={jobCategory}/>
                <SignupPickerData title={'관심사'} value={hobby} setValue={setHobby} etcValue={morehobby} setEtcValue={setMoreHobby} data={hobbyCategory}/>

                <View style={styles.termsWrapper}/>

                <Pressable
                    style={[styles.buttonWrapper]}
                    activeOpacity={0.5}
                    onPress={()=>{
                        const data: userEntity = {
                            username: name,
                            id: userdata.id,
                            password: password,
                            email: userdata.email,
                            phoneNumber: phoneNumber,
                            address: `${address}`,
                            job: job,
                            jobMore: job===24?morejob:undefined,
                            hobby: hobby,
                            hobbyMore: hobby===24?morehobby:undefined,
                            nickname: nickname,
                            sex: sex,
                            hometown: homeTown,
                            birthyear: birthYear,
                            bookSalon: [],
                            role: '000001',
                        }

                        for (const value in data) {
                            if (data[value]==='') {
                                return alert('비어있는항목이 존재 합니다')
                            }
                        }

                        if(password !== checkPassword) {
                            return alert('비밀번호를 확인해주세요')
                        }
                        if( nickname !== userdata.nickname) {
                            nicknameCheck.mutate({nickname: nickname},{
                                onSuccess:({data})=>{
                                    if(data.status) {
                                        setNicknameIsOK(data.data)
                                    }else {
                                        setNicknameIsOK(false)
                                        return alert('이 닉네임은 사용 할수 없습니다')
                                    }
                                }
                            })
                        }else {
                            setNicknameIsOK(true)
                        }
                        if (phoneNumber !== userdata.phoneNumber) {
                            phoneNumberCheck.mutate({phonenumber: phoneNumber}, {
                                onSuccess: ({data}) => {
                                    if(data.status) {
                                        const _data = data.data
                                        if (_data === false){
                                            setPhoneNumberIsOk(false)
                                            return alert('이 전화번호는 사용 할수 없습니다')
                                        }else {
                                            setPhoneNumberIsOk(true)
                                        }
                                    }
                                }
                            })
                        }else {
                            setPhoneNumberIsOk(true)
                        }
                    }}
                >
                    <Text style={[styles.buttonText]}>정보 수정</Text>
                </Pressable>

            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    xButtonWrapper: {
        margin: PixelRatio.getPixelSizeForLayoutSize(16),
        alignSelf: 'flex-end'
    },
    xButton: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),

    },
    bannerWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(39),
        alignItems: 'center'
    },
    bannerImage: {
        width: PixelRatio.getPixelSizeForLayoutSize(154),
        height: PixelRatio.getPixelSizeForLayoutSize(36),
    },
    loginText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'gray',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: 'bold',
    },
    linkText: {
        color: '#2C4198',
    },
    defaultText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: 'bold',
    },
    smallText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },
    checkBox: {
        width: PixelRatio.getPixelSizeForLayoutSize(15),
        height: PixelRatio.getPixelSizeForLayoutSize(15),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    horizonWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    dataFormWrapper: {
        width: '100%',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(41),
        paddingHorizontal:PixelRatio.getPixelSizeForLayoutSize(25),
    },
    termsWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(22),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(34)
    },
    termsText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
        color: '#676767',
    },
    termsButtonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
        color: '#000',
        textDecorationLine: 'underline'
    },
    buttonWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(54),
        backgroundColor: '#2C4198',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        justifyContent: 'center',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(100),
    },
    buttonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
        color: 'white',
        textAlign: 'center',
    }


});

export default ModifyUserContext
