import React, {useEffect, useState} from "react";
import arrow_left from "../../assets/images/arrow_left.png";
import reportIcon from "../../assets/images/report.png";
import lockIcon from '../../assets/images/lock_icon.svg'
import {Image, StyleSheet, Text, Pressable, View, PixelRatio} from "react-native";
import userContext from "../../context/userContext.ts";
import {MutationOptions, useMutation} from "react-query";
import client from "../../api/client.ts";
import {board} from '../../api/urls'
import {favoriteRequest, reportResponse, responseWrapper} from "../../api/entity";
import {useIsFocused} from "@react-navigation/native";
import answer from "../../screens/Answer";

function ReportMenu({navigation, route, category , articleData}){

    const clientUid = userContext.getUser.uid
    const [isReported,setIsReported] = useState(false)

    const isPublicString = articleData.isPublic===0?'공개하기':'비공개하기'
    const editString = category==='answer'?'수정하기':isPublicString
    const reportCategory = category==='answer'?'답글':'질문'


    const reportRequest: favoriteRequest = {
        uid: articleData.uid
    }

    const reportInstance = useMutation({
        mutationFn: (data: favoriteRequest)=> client.post<responseWrapper<reportResponse>>(board.post.report(category),data),
        onSuccess: ({data})=> {
            if(data.status) {
                    alert('신고되었습니다')
                    navigation.pop()
            }else {
                alert('이미 신고한 글입니다')
            }

        }
    })

    const privateInstance = useMutation({
        mutationFn: (data: favoriteRequest)=> client.post<responseWrapper<any>>(board.post.makePrivate(category),data),
        onSuccess: ({data})=> {
            if(data.status) {
                alert(`비공개 처리 되었습니다`)
                navigation.pop()

                }else {
                if(data.errorcode === 16) {
                    alert('이미 비공개 처리 된 글입니다')
                }
            }

        }
    })

    const publicInstance = useMutation({
        mutationFn: (data: favoriteRequest)=> client.post<responseWrapper<any>>(board.post.makePublic(category), data),
        onSuccess: ({data})=> {
            if(data.status) {
                alert(`공개 처리 되었습니다`)
                navigation.pop()

            }else {
                if(data.errorcode === 17) {
                    alert('이미 공개 처리 된 글입니다')
                }
            }

        }
    })

    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused) {
            if(articleData.length>0) {
                const count = articleData.whoIsReport.filter((v)=>v===clientUid).length
                setIsReported(count>0)
            }
        }
    },[useIsFocused()])

    return(
        <View style={styles.innerMenuWrapper}>
            <Pressable
                style={styles.horizontalWrapper}
                onPress={()=>navigation.pop()}
            >
                <Image style={styles.arrowIcon} source={arrow_left}/>
                <Text style={styles.innerBackText}>{'돌아가기'}</Text>
            </Pressable>
            <View style={{flex: 1}}/>
            {route.name!=='WriteAnswer'&&
                <Pressable
                    style={[styles.horizontalWrapper,styles.innerMenuButton]}
                    onPress={()=>{
                        if(articleData.userUid===clientUid&&category==='question') {
                            if(articleData.isPublic === 0) {
                                publicInstance.mutate(reportRequest)
                            }else {
                                privateInstance.mutate(reportRequest)
                            }

                        }else if(articleData.userUid===clientUid&&category==='answer') {
                            navigation.push('EditAnswer',{answer: articleData})
                        }else {
                            if(confirm('정말 신고하시겠습니까?')){
                                reportInstance.mutate(reportRequest)
                            }
                        }
                    }}
                >
                    {articleData.userUid!==clientUid&&<Image style={styles.reportIcon} source={reportIcon}/>}
                    <Text style={styles.innerMenuText}>{articleData.userUid===clientUid?editString:`부적절 ${reportCategory} 신고하기`}</Text>
                </Pressable>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    innerMenuWrapper: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(43),
        alignItems: 'center',
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#D9D9D9'
    },
    innerMenuButton: {
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(20),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(5),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(10),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#2C4198',
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(10),
        alignItems: 'center',
        gap: PixelRatio.getPixelSizeForLayoutSize(2)
    },
    innerBackText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        color: '#2C4198',
    },
    innerMenuText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
    },
    arrowIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(6),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(16),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    reportIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(15),
        height: PixelRatio.getPixelSizeForLayoutSize(15),
    },
})

export default  ReportMenu;
