
import React, {useEffect, useState} from "react";
import {Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import {MenuName} from "../public/MenuName.ts";
import fill from '../../assets/images/heart_fill.png'
import stroke from '../../assets/images/heart_stroke.png'
import reply_icon from '../../assets/images/reply.png'
import view_icon from '../../assets/images/eye.png'
import getDate from '../public/getDate'
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useMutation} from "react-query";
import client from "../../api/client.ts";
import {favorite} from '../../api/urls'
import FavoriteInstance from "../../api/FavoriteConnection.ts";
import {useIsFocused} from "@react-navigation/native";

function QuestionBoardItem({navigation,item ,setOption = undefined}){

    const [date,setDate] = useState('')
    const [isFavorite,setIsFavorite] = useState(false)
    const [favoriteCount,setFavoriteCount] = useState(0)
    const [keyword,setKeyword] = useState('')

    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused) {
            setIsFavorite(item.favorite)
            setDate(getDate(item.regDate))
            setKeyword(typeof item.keywordString === 'string'? item.keywordString:'키워드')
            setFavoriteCount(item.favoriteCount)
        }
    },[useIsFocused(),item] )

    const favoriteInstance = FavoriteInstance(favorite.categoryType.question,isFavorite,item.uid)

    const favoriteAction = ()=> {
        favoriteInstance.mutate(null,{
            onSuccess: ({data})=> {
                //# FIXME: responseWrapper issue?
                if (data.status) {
                    setIsFavorite(!isFavorite)
                    setFavoriteCount(favoriteCount + 1)
                } else {
                    setIsFavorite(!isFavorite)
                    setFavoriteCount(favoriteCount - 1)
                }
            }
        })
    }


    return (
        <Pressable
            style={[styles.boardWrapper, item.isPublic===0&&styles.privateColor]}
            onPress={()=>{
                setOption!==undefined&&setOption(false)
                navigation.navigate('Answer',{menu: MenuName.AllQuestionAnswer, question: item})
            }}
        >
            <Text style={styles.boardTitleText} numberOfLines={1}>{item.title}</Text>
            <View style={styles.boardSubWrapper}>
                <View style={styles.boardSubWrapper}>
                    <Text style={[styles.boardSubText,styles.blueText]} numberOfLines={1}>{keyword}</Text>
                    <View style={styles.separatorHorizontal}/>
                    <Text style={styles.boardSubText} numberOfLines={1}>{date}{}</Text>
                    <View style={styles.separatorHorizontal}/>
                    <Text style={styles.boardSubText}>{item.nickname}</Text>
                </View>
                <View style={{flex: 1}}/>
                <View style={styles.boardSubWrapper}>
                    <Pressable style={styles.boardSubWrapper} onPress={favoriteAction}>
                        <Image
                            style={styles.boardIcon}
                            source={isFavorite===false?stroke:fill}
                        />
                        <Text style={[styles.boardSubText]}>
                            {favoriteCount}
                        </Text>
                    </Pressable>
                    <View style={styles.separatorHorizontal}/>
                    <Image
                        style={styles.boardIcon}
                        source={reply_icon}
                    />
                    <Text style={[styles.boardSubText]}>
                        {item.answerCount}
                    </Text>
                </View>
            </View>

        </Pressable>
    );
}

const styles= StyleSheet.create({
    boardWrapper: {
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(74),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        padding: PixelRatio.getPixelSizeForLayoutSize(16),
        'box-shadow': '0px 2px 4px #0000000D'
    },
    boardTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        flex: 1,
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700'
    },
    boardSubWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    boardSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },
    separatorVertical: {
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(5),
        backgroundColor: '#E5E5E5'
    },
    boardIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(14),
        height: PixelRatio.getPixelSizeForLayoutSize(14),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(4),
    },
    separatorHorizontal: {
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        backgroundColor: '#DDDDDD',
        alignSelf: 'center',
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8),
    },
    blueText: {
        color: '#2C4198'
    },
    privateColor: {
        backgroundColor: '#eeeeee'
    }
});

export default QuestionBoardItem;
