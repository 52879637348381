import React, {useEffect, useRef, useState} from "react";
import {Button, FlatList, Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import PublicTopMenu from '../components/public/PublicTopMenu.tsx'
import {publicWrapper} from '../components/public/PublicStyle'
import client from "../api/client.ts";
import getDate from '../components/public/getDate'
import {useMutation} from "react-query";
import {board} from '../api/urls'
import {
    boardReadRequest,
    boardResponse,
    bookSalonResponse,
    categoryResponse,
    responseWrapper,
    userEntity
} from "../api/entity";
import check_icon from '../assets/images/check.png'
import ellipse_icon from '../assets/images/ellipse.png'
import favorite_icon_stroke from  '../assets/images/good_stroke.png'
import favorite_icon_fill from  '../assets/images/good_fill.png'
import reply_icon from  '../assets/images/reply.png'
import views_icon from  '../assets/images/eye.png'
import userContext from "../context/userContext.ts";
import checkLogin from "../components/public/checkLogin.ts";
import {useFocusEffect, useIsFocused} from "@react-navigation/native";
import {isDesktop} from "react-device-detect";
import AsyncStorage from "@react-native-async-storage/async-storage";

function Community({navigation,route}){


    const boardRef = useRef()

    const bookSalonInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<bookSalonResponse[]>>(board.post.getBookSalonCode),
        onSuccess:({data})=>{
            if (data.status) {
                const _data = data.data
                const user = userContext.getUser.bookSalon
                let myBookSalon = []
                let otherBookSalon = []

                _data.map(v=>{
                    if(user.filter(val=>v.code===Number(val)).length){
                        myBookSalon = [...myBookSalon,v]
                    }else{
                        otherBookSalon = [...otherBookSalon,v]
                    }
                })

                setBookSalonList([{code:undefined, description: '전체보기'},...myBookSalon,...otherBookSalon])
            }
            if (typeof data !== 'undefined') {

            }
        }
    })

    const [boardData, setBoardData] = useState([])
    const [lastUid,setLastUid] = useState(undefined)

    const [selectSort, setSelectSort] = useState<string>('uid')
    const [selectBookSalon,setSelectBookSalon] = useState<number|undefined>(route.params.bookSalon)
    let boardRequest: boardReadRequest = {
        parentUid: undefined ,
        lastUid: undefined,
        sorting: selectSort,
        bookSalon: selectBookSalon
    }
    const boardInstance = useMutation({
        mutationFn:(data: boardReadRequest)=> client.post<responseWrapper<boardResponse[]>>(board.get.read(board.categoryType.answer),data)
    })

    const [bookSalonList, setBookSalonList] = useState([])

    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused){
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }
            })

            bookSalonInstance.mutate()
            boardInstance.mutate(boardRequest,{
                onSuccess: ({data})=> {
                    if(data.status) {
                        const _data = data.data
                        setBoardData(_data)

                        const length = _data.length > 0? _data.length-1 : undefined
                        if (length !== undefined) {
                            setLastUid(_data[length].uid)
                        }
                    }

                }
            })
            setSelectBookSalon(route.params.bookSalon)
        }
    },[useIsFocused()])

    useEffect(()=> {
        boardRef.current.scrollToOffset({offset:0})
        boardInstance.mutate(boardRequest,{
            onSuccess: ({data})=> {
                if(data.status) {
                    const _data = data.data
                    setBoardData(_data)

                    const length = _data.length > 0? _data.length-1 : undefined
                    if (length !== undefined) {
                        setLastUid(_data[length].uid)
                    }
                }
            }
        })
    },[selectSort,selectBookSalon])

    function changeSortButton(title, sortText) {
        if (sortText === selectSort) {
            return (
                <>
                    <Image style={styles.selectSortIcon} source={check_icon}/>
                    <Text style={styles.selectSortText}>{title}</Text>
                </>
            )
        }else {
            return (
                <>
                    <Image style={styles.sortIcon} source={ellipse_icon}/>
                    <Text style={styles.sortText}>{title}</Text>
                </>
            )
        }
    }


    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            {/*북살롱 리스트*/}
            <View style={styles.bookSalonWrapper}>
                <FlatList
                    horizontal={true}
                    data={bookSalonList}
                    renderItem={({item})=>{
                        const myBookSalon = userContext.getUser.bookSalon.filter((v)=>v===item.code).length > 0
                        return (
                            <Pressable
                                style={[styles.bookSalonItemWrapper,selectBookSalon===item.code&&styles.selectWrapper]}
                                activeOpacity={1}
                                onPress={()=>{
                                    if(myBookSalon || item.code===undefined) {
                                        const request: boardReadRequest = {
                                            bookSalon: item.code,
                                            sorting: selectSort
                                        }

                                        setSelectBookSalon(item.code)
                                    }
                                }}
                            >
                                <Text
                                    style={[styles.bookSalonText,selectBookSalon === item.code&&styles.selectText]}
                                >
                                    {item.description}
                                </Text>
                                {/*나의 북살롱 에 등록 되어 있다면....*/}
                                {myBookSalon&&<Text style={styles.bookSalonSubText}>MY</Text>}
                            </Pressable>
                        );
                    }}
                />
            </View>

            <View style={publicWrapper.backgrounded}>
                {/* Sort Menu*/}
                <View style={styles.sortWrapper}>
                    <Pressable
                        style={styles.sortButtonWrapper}
                        onPress={()=> {
                            setSelectSort('uid')

                        }}
                    >
                        {changeSortButton('최신순', 'uid')}
                    </Pressable>
                    <Pressable
                        style={styles.sortButtonWrapper}
                        onPress={()=> {
                            setSelectSort('favorite')
                        }}
                    >
                        {changeSortButton('좋아요순', 'favorite')}
                    </Pressable>
                    <Pressable
                        style={styles.sortButtonWrapper}
                        onPress={()=> {
                            setSelectSort('reply')
                        }}
                    >
                        {changeSortButton('댓글많은순', 'reply')}
                    </Pressable>
                </View>
                {/*게시판 부분 Board*/}
                <FlatList
                    ref={boardRef}
                    data={boardData}
                    renderItem={({item})=>{
                        const date = getDate(item.regDate)
                        const blindCount = item.whoIsReport===null?0:item.whoIsReport.length
                        if(item.blindCount <= blindCount) {
                            return <></>
                        }
                        return(
                            <Pressable
                                style={styles.boardItemWrapper}
                                onPress={()=>{
                                    navigation.push('AnswerDetail',{answer: item})
                                }}
                            >
                                <Text style={styles.boardItemTitle}>{item.title}</Text>
                                <View style={styles.verticalSeparator}/>
                                <View style={styles.horizontalWrapper}>
                                    <Text style={[styles.boardMiddleText, styles.blueText]}>{item.keywordString}</Text>
                                    <View style={styles.horizontalSeparator}/>
                                    <Text style={styles.boardMiddleText}>{item.nickname}</Text>
                                    <View style={styles.horizontalSeparator}/>
                                    <Text style={[styles.boardMiddleText,{fontWeight: '500'}]}>{date}</Text>
                                </View>
                                <Text style={styles.boardContentsText} numberOfLines={2}>{item.content}</Text>
                            </Pressable>
                        )
                    }}
                    onEndReached={()=>{
                        const request: boardReadRequest = {
                            sorting: selectSort,
                            bookSalon: selectBookSalon,
                            lastUid: lastUid
                        }
                        boardInstance.mutate(request,{
                            onSuccess: ({data})=> {
                                if(data.status) {
                                    const _data = data.data
                                    const length = _data.length > 0? _data.length-1 : undefined
                                    if (length !== undefined) {
                                        setBoardData([...boardData, ..._data])
                                        setLastUid(_data[length].uid)
                                    }
                                }

                            }
                        })

                    }}
                />
            </View>
        </View>


    );
}

const styles = StyleSheet.create({
    horizontalWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    verticalSeparator: {
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        backgroundColor: '#E5E5E5',
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    horizontalSeparator: {
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        backgroundColor: '#E5E5E5',
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8),
    },
    bookSalonWrapper: {
        backgroundColor: 'white',
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(47),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#D9D9D9'
    },
    bookSalonItemWrapper:{
        flexDirection: 'row',
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(15),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(14),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    selectWrapper: {
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#2C4198',
    },
    bookSalonText: {
        fontFamily: 'SCDream5',
        color: '#777777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '300',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(18),
    },
    bookSalonSubText: {
        fontFamily: 'SCDream5',
        color:'#FF6DA2',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(7),
        fontWeight: '500',
        verticalAlign: 'auto',
    },
    selectText: {
        color: '#2C4198',
        fontWeight: '500',
    },
    sortWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(44),
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    sortButtonWrapper: {
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(13),
        flexDirection: 'row',
        alignItems: 'center',
    },
    selectSortText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'black',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    sortText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    selectSortIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(12),
        height: PixelRatio.getPixelSizeForLayoutSize(12),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    sortIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(4),
        height: PixelRatio.getPixelSizeForLayoutSize(4),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    boardItemWrapper: {
        backgroundColor: 'white',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12),
        height: PixelRatio.getPixelSizeForLayoutSize(172),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(18),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        'box-shadow': '0px 2px 4px #0000000D'
    },
    boardItemTitle: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
    },
    boardMiddleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '700',
    },
    blueText: {
        color: '#2C4198',
    },
    boardContentsText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(20),
        marginTop: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    boardSmallText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
    },
    boardIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(14),
        height: PixelRatio.getPixelSizeForLayoutSize(14),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(4),
    }
})


export default Community;
