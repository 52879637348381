import React, {useEffect, useState} from "react";
import PublicTopMenu from '../components/public/PublicTopMenu.tsx'
import {FlatList, Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import {publicWrapper} from '../components/public/PublicStyle'
import TopMenu from "../components/myquestion/TopMenu.tsx";
import heart_fill from '../assets/images/heart_fill.png'
import {useIsFocused} from "@react-navigation/native";
import checkLogin from "../components/public/checkLogin.ts";
import {useMutation} from "react-query";
import client from "../api/client.ts";
import {
    boardReadRequest,
    favoriteRequest,
    myFavoriteBoard,
    myFavoriteResponse,
    myQuestionRequest
} from "../api/entity.ts";
import {myQuestion} from "../api/urls";
import getDate from '../components/public/getDate'
import {isDesktop} from "react-device-detect";
import userContext from "../context/userContext.ts";
import AsyncStorage from "@react-native-async-storage/async-storage";

function MyQuestion({navigation, route}){

    const [boardData,setBoardData] = useState<myFavoriteBoard[]>([])
    const [answerCount, setAnswerCount] = useState(0)
    const [favoriteCount, setFavoriteCount] = useState(0)
    const [lastUid, setLastUid] = useState(undefined)
    const boardInstance = useMutation({
        mutationFn: (data: myQuestionRequest)=>client.post<myFavoriteResponse>(myQuestion.post.myFavorite, data),

    })



    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }else {
                    const boardRequest: myQuestionRequest = {
                        lastUid: lastUid
                    }
                    boardInstance.mutate(boardRequest,{
                        onSuccess: ({data})=> {
                            const length = data.questionList.length > 0? data.questionList.length-1 : undefined
                            if (length !== undefined) {
                                setLastUid(data.questionList[length].questionUid)
                                setBoardData(data.questionList)
                                setAnswerCount(data.answerCount)
                                setFavoriteCount(data.favoriteCount)
                            }

                        }
                    })
                }
            })

        }
    },[useIsFocused()])

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <TopMenu navigation={navigation} route={route}/>
            <View style={publicWrapper.backgrounded}>
                <View style={styles.topWrapper}>
                    <Text style={styles.titleText}>하트 질문 수 </Text>
                    <Text style={styles.titleNumberText}>{boardData.length}</Text>
                    <View style={{width: PixelRatio.getPixelSizeForLayoutSize(15)}}/>
                    <Text style={styles.titleText}>답변완료한 질문 수 </Text>
                    <Text style={styles.titleNumberText}>{answerCount}</Text>
                </View>
                <FlatList
                    data={boardData}
                    renderItem={({item})=>{
                        return (
                            <Pressable
                                style={styles.listItemWrapper}
                                onPress={()=>{

                                    const data = {
                                        uid: item.questionUid,
                                        userUid: item.userUid,
                                        title: item.questionTitle,
                                        isPublic: item.isPublic
                                    }

                                    if (item.amIAnswer) {
                                        navigation.push('Answer',{question: data})
                                    }else {
                                        navigation.push('WriteAnswer',{question: data})
                                    }
                                }}
                            >
                                <View style={styles.horizontalWrapper}>
                                    <Text style={styles.listItemTitleText} numberOfLines={1}>{item.questionTitle}</Text>
                                    <View style={{flex: 1}}/>
                                    <Image style={styles.icon} source={heart_fill}/>
                                    <Text style={styles.listItemHeartText}>{item.favoriteCount}</Text>
                                </View>
                                <View style={{flex: 1}}/>
                                <View style={styles.horizontalWrapper}>
                                    <Text style={styles.ListCategoryText}>{item.keywordString}</Text>
                                    <View style={styles.horizontalSeparator}/>
                                    <Text style={styles.listItemSubText}>{getDate(item.regDate)}</Text>
                                    <View style={styles.horizontalSeparator}/>
                                    <Text style={styles.listItemSubText}>{item.registerName}</Text>
                                    <View style={{flex: 1}}/>
                                    <View style={[styles.listItemButtonWrapper, item.amIAnswer&&styles.amIAnswerWrapper]}>
                                        <Text style={[styles.listItemButtonText, item.amIAnswer&&styles.amIAnswerText]}>{item.amIAnswer?'답글보기':'답글쓰기'}</Text>
                                    </View>
                                </View>
                            </Pressable>
                        )
                    }}
                    onEndReached={()=> {
                        const request: myQuestionRequest = {
                            lastUid: lastUid
                        }
                        boardInstance.mutate(request,{
                            onSuccess: ({data})=> {
                                const length = data.questionList.length > 0? data.questionList.length-1 : undefined
                                if (length !== undefined) {
                                    setBoardData([...boardData,...data.questionList])
                                    setAnswerCount(data.answerCount)
                                    setFavoriteCount(data.favoriteCount)
                                    setLastUid(data.questionList[length].questionUid)
                                }

                            }
                        })
                    }}
                />
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    horizontalSeparator: {
        backgroundColor: '#DDDDDD',
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8)
    },
    titleText: {
        color: '#232323',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500'
    },
    titleNumberText: {
        color: '#2C4198',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '700'
    },
    topWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(46),
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItemWrapper: {
        backgroundColor: 'white',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        height: PixelRatio.getPixelSizeForLayoutSize(84),
        padding: PixelRatio.getPixelSizeForLayoutSize(16),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12)
    },
    listItemTitleText: {
        color: '#232323',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700'
    },
    ListCategoryText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    listItemSubText: {
        color: '#888',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(18),
        height: PixelRatio.getPixelSizeForLayoutSize(18),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    listItemHeartText: {
        color: '#777',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '700'
    },
    amIAnswerWrapper: {
        borderColor: '#DDDDDD'
    },
    amIAnswerText: {
        color: '#777'
    },
    listItemButtonWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(23),
        alignItems: 'center',
        justifyContent:'center',
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(5),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(10),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        borderColor: '#2C4198'
    },
    listItemButtonText: {
        color: '#2C4198',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500'
    }
})

export default MyQuestion
