import React, {useEffect, useState} from "react";
import {PixelRatio, StyleSheet, Text, TextInput, View} from "react-native";
import MenuWrapper from "../components/menu/MenuWrapper.tsx";
import {useIsFocused} from "@react-navigation/native";
import {request} from "axios";
import {inquireSaveRequest, inquireSaveResponse, responseWrapper} from "../api/entity";
import {useMutation} from "react-query";
import client from "../api/client.ts";
import {inquire} from '../api/urls'
import PublicScripts from "../components/public/PublicScripts.jsx";



function MenuWriteInquire({navigation,route}){

    const [titleString, setTtitleString] = useState('')
    const [contentString, setContentString] = useState('')

    const writeInstance = useMutation({
        mutationFn: (request: inquireSaveRequest) => client.post<responseWrapper<inquireSaveResponse>>(inquire.save, request)
    })

    const [height,setHeight] = useState()
    const isFocused = useIsFocused()
    useEffect(() => {
        if(isFocused) {
            setHeight(window.innerHeight)
        }
    }, [useIsFocused()]);

    const contentView = (
        <>
            <Text style={[styles.subTitleText,{marginTop: PixelRatio.getPixelSizeForLayoutSize(26)}]}>제목</Text>
            <TextInput
                style={styles.titleInput}
                value={titleString}
                onChangeText={setTtitleString}
                placeholder={'제목을 입력하세요'}
                placeholderTextColor={'#999'}
            />
            <Text style={styles.subTitleText}>문의내용</Text>

            <View>
                <TextInput
                    style={styles.contentInput}
                    value={contentString}
                    onChangeText={setContentString}
                    multiline={true}
                    maxLength={2000}
                    placeholder={'내용을 입력하세요'}
                    placeholderTextColor={'#999'}
                />
                <View style={styles.contentLengthWrapper}>
                    <Text style={styles.contentMaxLength}>
                        <Text style={styles.contentLength}>
                            {contentString.length}
                        </Text>
                        {' / 2000'}
                    </Text>
                </View>

            </View>

        </>
    )

    const writeAction = ()=>{
        if (titleString.length>0&&contentString.length>0) {
            const request: inquireSaveRequest = {
                title: titleString,
                content: contentString
            }

            writeInstance.mutate(request, {
                onSuccess: ({data})=> {
                    alert('저장에 성공 했습니다')
                    setTtitleString('')
                    setContentString('')
                    navigation.pop()
                }
            })
        }else {
            alert('제목과 내용을 입력해 주세요')
        }
    }


    return(
        <View style={{height: height}}>
            <PublicScripts/>
            <MenuWrapper
                navigation={navigation}
                route={route}
                buttonItem={{
                    title:'문의 등록하기',
                    action: writeAction
                }}
                titleView={<Text style={styles.titleText}>1:1 문의 내역</Text>}
                contentView={contentView}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(28),
        fontWeight: '700',
    },
    subTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(8)
    },
    titleInput: {
        fontFamily: 'KoPub_Dotum_Pro',
        height: PixelRatio.getPixelSizeForLayoutSize(49),
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#999999',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        padding: PixelRatio.getPixelSizeForLayoutSize(17)
    },
    contentInput: {
        fontFamily: 'KoPub_Dotum_Pro',
        height: PixelRatio.getPixelSizeForLayoutSize(173),
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#999999',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        padding: PixelRatio.getPixelSizeForLayoutSize(17)
    },
    contentLengthWrapper: {
        position: 'absolute',
        bottom: PixelRatio.getPixelSizeForLayoutSize(17),
        right: PixelRatio.getPixelSizeForLayoutSize(17)
    },
    contentLength: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500',
    },
    contentMaxLength: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#AAA',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500',
    }
})

export default MenuWriteInquire
