import React, {useState} from "react";
import {PixelRatio, StyleSheet, Text, TextInput, View, Pressable, CheckBox} from "react-native-web";
import {useMutation} from "react-query";
import {favoriteResponse, loginResult, responseWrapper, userEntity, userResponse} from '../api/entity';
import client,{applyToken,cleanToken} from "../api/client.ts";
import {user, favorite} from '../api/urls'
import {Image, Modal} from "react-native";
import xImage from '../assets/images/x_icon.svg'
import userContext from "../context/userContext.ts";
import logo from '../assets/images/logo_2.svg'
import {publicWrapper} from '../components/public/PublicStyle'
import {isDesktop} from "react-device-detect";
import AsyncStorage, {useAsyncStorage} from "@react-native-async-storage/async-storage";
import PublicScripts from "../components/public/PublicScripts";


function Login({navigation}) {

    const [id,setId]  = useState('');
    const [password, setPassword] = useState('')
    const [idSave, setIdSave] = useState(false)
    const [autoLoin,setAutoLogin] = useState(false)

    const loginInstance = useMutation({
        mutationFn: (data: {id: String, password: String})=>{
            return client.post<responseWrapper<loginResult>>(user.post.login, data)
        }
    })

    const getUserInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<userResponse>>(user.post.readUser),
        onSuccess: ({data})=> {
            const userData: userResponse = data.data
            userContext.setUser(userData)
            AsyncStorage.setItem('user',JSON.stringify(userData))
            navigation.navigate('Main')
        },
        onError: (error)=> alert(error)
    })

    const backButton = ()=>{
        navigation.push('Menu')
    };

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>

            <PublicScripts/>

            <Pressable
                style={styles.xButtonWrapper}
                onPress={backButton}
            >
                <Image style={styles.xButton} source={xImage}/>
            </Pressable>
            <View style={styles.wrapper}>
                <View style={styles.bannerWrapper}>
                    <Image style={styles.bannerIcon} source={logo}/>
                </View>
                <View style={{width: '100%'}}>
                    <Text style={styles.inputTitleText}>아이디</Text>
                    <TextInput
                        style={styles.inputText}
                        onChangeText={setId} value={id}
                        placeholder={'이메일을 입력해주세요.'}
                        placeholderTextColor={'#999999'}
                    />
                    <Text style={styles.inputTitleText}>비밀번호</Text>
                    <TextInput
                        style={styles.inputText}
                        onChangeText={setPassword}
                        value={password}
                        placeholder={'비밀번호를 입력해주세요.'}
                        placeholderTextColor={'#999999'}
                        secureTextEntry={true}
                    />
                </View>

                <View style={[styles.horizonWrapper, {width: '100%'}]}>
                    <Pressable
                        style={styles.horizonWrapper}
                        onPress={()=>setIdSave(!idSave)}
                    >
                        <CheckBox
                            style={styles.checkBox}
                            color={'#2C4198'}
                            value={idSave}
                        />
                        <Text style={styles.checkBoxText}>아이디 저장</Text>
                    </Pressable>
                    <View style={{width: PixelRatio.getPixelSizeForLayoutSize(18)}}/>
                    <Pressable
                        style={styles.horizonWrapper}
                        onPress={()=>setAutoLogin(!autoLoin)}
                    >
                        <CheckBox
                            style={styles.checkBox}
                            color={'#2C4198'}
                            value={autoLoin}
                        />
                        <Text style={styles.checkBoxText}>자동 로그인</Text>
                    </Pressable>
                </View>
                <Pressable
                    style={styles.buttonWrapper}
                    onPress={()=>{
                        if (id === '' || password === '') {
                            return alert('아이디와 비밀번호를 입력 하세요.')
                        }
                        const data = {
                            'id': id,
                            'password': password
                        }
                        cleanToken()
                        loginInstance.mutate(data,{
                            onSuccess: ({data})=>{
                                if(data.status){
                                    applyToken(data.data.token)
                                    userContext.setRefreshToken(data.data.refreshToken)
                                    useAsyncStorage('token').setItem(JSON.stringify(data.data))
                                    getUserInstance.mutate()
                                }
                                else{
                                    if(data.errorcode === 11){
                                        return alert('계정이 정지 됐습니다')
                                    }else {
                                        return alert(`로그인에 실패 했습니다`)
                                    }
                                }
                            }
                        })
                    }}
                >
                    <Text style={styles.buttonText}>로그인</Text>
                </Pressable>
                <View style={[styles.horizonWrapper,{width: '100%'}]}>
                    <Pressable
                        onPress={()=>navigation.push('Signup')}
                    >
                        <Text style={styles.signupText}>회원가입</Text>
                    </Pressable>
                    <View style={{flex: 1}}/>
                    <Pressable
                        onPress={()=>navigation.push('ChangePassword')}
                    >
                        <Text style={styles.subText}>비밀번호찾기</Text>
                    </Pressable>

                </View>
            </View>
        </View>

    );
}
const styles = StyleSheet.create({
    xButtonWrapper: {
        margin: PixelRatio.getPixelSizeForLayoutSize(16),
        alignSelf: 'flex-end'
    },
    xButton: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
    },
    wrapper: {
        width: '100%',
        justifyContent:'center',
        alignItems:'center',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    bannerWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(47),
    },
    bannerIcon:{
        width: PixelRatio.getPixelSizeForLayoutSize(154),
        height: PixelRatio.getPixelSizeForLayoutSize(36),
    },
    inputTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(8)
    },
    inputText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#DDD',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        height: PixelRatio.getPixelSizeForLayoutSize(49),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(13),
        padding: PixelRatio.getPixelSizeForLayoutSize(17),
    },
    buttonWrapper: {
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        backgroundColor: '#2C4198',
        alignItems: 'center',
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(54),
    },
    buttonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
        textAlign: 'center',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    horizonWrapper: {
        flexDirection: 'row',
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(10),
        /*alignItems: 'center',
        justifyContent: 'center'*/
    },
    checkBoxText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
        color: '#676767',
    },
    checkBox:{
        marginRight: PixelRatio.getPixelSizeForLayoutSize(8),
        width: PixelRatio.getPixelSizeForLayoutSize(18),
        height: PixelRatio.getPixelSizeForLayoutSize(18),
    },
    subText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: 'bold',
        color: 'gray',
    },
    signupText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        textDecorationLine: 'underline'
    }
});



export default Login;
