import React, {useEffect, useState} from "react";
import {FlatList, Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import MenuWrapper from "../components/menu/MenuWrapper.tsx";
import alert_icon from '../assets/images/alert_icon.svg'
import checkLogin from "../components/public/checkLogin.ts";
import {useIsFocused} from "@react-navigation/native";
import {useMutation} from "react-query";
import getDate from '../components/public/getDate'
import {inquire} from '../api/urls'
import client from "../api/client.ts";
import {inquireReadRequest, inquireReadResponse, responseWrapper} from "../api/entity";
import PublicScripts from "../components/public/PublicScripts.jsx";
import AsyncStorage from "@react-native-async-storage/async-storage";

function MenuInquireList({navigation, route}){

    const [boardData, setBoardData] = useState([])
    const [lastUid, setLastUid] = useState()
    const boardInstance = useMutation({
        mutationFn:(request: inquireReadRequest)=>client.post<responseWrapper<inquireReadResponse[]>>(inquire.read, request)
    })

    const [height,setHeight] = useState()

    const isFocused = useIsFocused()
    useEffect(() => {
        if(isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                    return true
                }else {
                    setHeight(window.innerHeight)
                    const request: inquireReadRequest = {
                        lastUid: undefined
                    }
                    boardInstance.mutate(request,{
                        onSuccess: ({data})=> {
                            if(data.status) {
                                const _data = data.data
                                const lenth = _data.length
                                if(lenth>0) {
                                    setLastUid(_data[lenth-1])
                                    setBoardData(_data)
                                }
                            }
                        }
                    })
                }
            })


        }
    }, [useIsFocused()]);

    const emptyView = (
        <View style={styles.emptyWrapper}>
            <Image source={alert_icon} style={{width:50,height:50, marginBottom: PixelRatio.getPixelSizeForLayoutSize(14)}}/>
            <Text style={styles.emptyText}>문의 내역이 없습니다</Text>
        </View>
    )

    const boardView = (
        <FlatList data={boardData} renderItem={({item})=> {
            return (
                <Pressable
                    style={[styles.listItemWrapper, styles.horizontalWrapper]}
                    onPress={()=>{
                        navigation.push('MenuInquireDetail',{uid: item.uid})
                    }}
                >
                    <View style={{flex: 1}}>
                        <Text
                            style={styles.itemTitleText}
                            numberOfLines={1}
                        >
                            {item.title}
                        </Text>
                        <View style={{flex: 1}}/>
                        <Text style={styles.itemSubText}>{getDate(item.regDate)}</Text>
                    </View>
                    <View>
                        <View style={{flex: 1}}/>
                        <Text style={[styles.itemAnswerWrapper, item.isAnswered!==0&&styles.itemAnswerTrue]}>
                            {item.isAnswered===0?'답변대기':'답변완료'}
                        </Text>
                    </View>
                </Pressable>
            )
        }}/>
    )

    return(
        <View style={{height: height}}>

            <PublicScripts/>

            <MenuWrapper
                navigation={navigation}
                route={route}
                buttonItem={{title:'1:1문의작성',action:()=>{navigation.push('MenuWriteInquire')}}}
                titleView={<Text style={styles.titleText}>1:1 문의 내역</Text>}
                contentView={boardData.length>0?boardView:emptyView}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    horizontalWrapper: {
      flexDirection: 'row',
    },
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(28),
        fontWeight: '700',
    },
    emptyWrapper: {
        alignItems:'center',
        justifyContent: 'center',
        height: 'auto',
        flex: 1,
    },
    emptyText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
    },
    listItemWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(75),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#EEE'
    },
    itemTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
    },
    itemSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },
    itemAnswerWrapper: {
        color: '#777',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
        textAlign: 'center',
        alignSelf: 'center',
        backgroundColor: '#77777733',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(2),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(5),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(10)
    },
    itemAnswerTrue:{
        color: '#2C4198',
        backgroundColor: '#2C419833'
    }
})

export default MenuInquireList
