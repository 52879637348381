import React, {useEffect, useState} from "react";
import {
    Image,
    View,
    StyleSheet,
    Text,
    TextInput,
    FlatList,
    Button,
    Alert,
    Modal,
    ScrollView,
    Linking
} from "react-native";
import {CheckBox, PixelRatio, Pressable} from "react-native-web";
import {
    SignupBirthAgeData, SignupBirthYearData,
    SignupData,
    SignupDataAddress,
    SignupEmailData,
    SignupPhoneNumberData,
    SignupPickerData, SignupSexData
} from "../components/signup/SignupItem.tsx";
import client from "../api/client.ts";
import {useMutation} from "react-query";
import {categoryResponse, responseWrapper, termsRequest, termsResponse, userEntity} from "../api/entity";
import {user, notice} from '../api/urls'
import isEmailValidation from '../components/signup/isEmailValidation'
import xImage from '../assets/images/x_icon.svg'
import bannerIcon from '../assets/images/logo_2.svg'
import closeIcon from '../assets/images/x_icon.svg'
import {useIsFocused} from "@react-navigation/native";
import {publicWrapper} from '../components/public/PublicStyle'
import {isDesktop} from "react-device-detect";
import RenderHTML from "react-native-render-html";
import PublicScripts from "../components/public/PublicScripts.jsx";
function Signup({navigation}){

    const [isVisible, setIsVisible] = useState(false)
    const [modalContent, setModalContent] = useState('Default Text')
    const [modalTitle, setModalTitle] = useState('Title')
    const [height,setHeight] = useState()

    const [password,setPassword] = useState('');
    const [checkPassword,setCheckPassword] = useState('')
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phoneNumber,setPhoneNumber] = useState('');
    const [address,setAddress] = useState('');
    const [zipCode,setZipCode] = useState('');
    const [nickname, setNickname] = useState('');
    const [birthYear, setBirthYear] = useState<number>();
    const [homeTown, setHomeTown] = useState('');
    const [sex,setSex] = useState<number>(0)

    const [job,setJob] = useState<number>();
    const [hobby,setHobby] = useState<number>();
    const [morejob,setMoreJob] = useState('');
    const [morehobby,setMoreHobby] = useState('');
    const [agree,setAgree] = useState(false)

    const [idIsOk,setIdIsOK] = useState(false)
    const [nicknameIsOk,setNicknameIsOK] = useState(false)
    const [phoneNumberIsOk, setPhoneNumberIsOk] = useState(false)

    const [jobCategory,setJobCategory] = useState([])

    const jobCategoryInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<categoryResponse[]>>(user.getJob),
        onSuccess: ({data})=>{
            if(data.status) {
                const _data = data.data
                setJob(_data[0].code)
                setJobCategory(_data)
            }

        }
    })
    const [hobbyCategory,setHobbyCategory] = useState([])

    const hobbyCategoryInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<categoryResponse[]>>(user.getHobby),
        onSuccess: ({data})=>{
            if(data.status) {
                const _data = data.data
                setHobby(_data[0].code)
                setHobbyCategory(_data)
            }
        }
    })

    const isFocused = useIsFocused()
    useEffect(()=> {
        if(isFocused) {
            hobbyCategoryInstance.mutate()
            jobCategoryInstance.mutate()
            setHeight(window.innerHeight)
        }
    },[useIsFocused()])

    const signupInstance = useMutation({
        mutationFn: (data: userEntity)=>{
            return client.post<responseWrapper<userEntity>>(user.post.signUp, data)
        }
    })

    const idCheck = useMutation({
        mutationFn: (data: {id: string})=>{
            return client.post<responseWrapper<boolean>>(user.post.isIdCanBeUsed, data)
        }
    })
    const nicknameCheck = useMutation({
        mutationFn: (data: {nickname: string})=>{
            return client.post<responseWrapper<boolean>>(user.post.isNicknameCanBeUsed, data)
        }
    })

    const phoneNumberCheck = useMutation({
        mutationFn: (data: {phonenumber: string})=> client.post<responseWrapper<boolean>>(user.post.isPhoneNumberCanBeUsed, data)
    })
    const backButton = ()=>{
            navigation.push('Login')
    };

    useEffect(() => {
        if (idIsOk&&nicknameIsOk&&phoneNumberIsOk) {
            const data: userEntity = {
                username: name,
                id: email,
                password: password,
                email: email,
                phoneNumber: phoneNumber,
                address: `${address}`,
                zipcode: zipCode,
                job: job,
                jobMore: job===24?morejob:undefined,
                hobby: hobby,
                hobbyMore: hobby===24?morehobby:undefined,
                nickname: nickname,
                sex: sex,
                hometown: homeTown,
                birthyear: birthYear,
                bookSalon: [],
                role: '000001',
            }

            signupInstance.mutate(data,{
                onSuccess: ({data})=>{
                    if(data.status) {
                        alert(`가입이 완료 되었어요`)
                        navigation.push('Login')
                    }
                }
            })
        }
    }, [idIsOk, nicknameIsOk, phoneNumberIsOk]);

    const termsInstance = useMutation({
        mutationFn: (data: termsRequest) => client.post<responseWrapper<termsResponse>>(notice.termsOfService,data),
        onSuccess: ({data}) => {
            if(data.status) {
                const _data = data.data
                setModalTitle(_data.title)
                setModalContent(_data.content)
                setIsVisible(true)
            }
        }
    })

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicScripts/>

            <Modal visible={isVisible}>
                <View  style={[{height: height},isDesktop&&publicWrapper.deskTopWrapper]}>
                    <View style={modalStyles.closeWrapper}>
                        <Pressable
                            onPress={()=>{
                                setIsVisible(false)
                            }}
                        >
                            <Image source={closeIcon} style={modalStyles.closeIcon} />
                        </Pressable>

                    </View>
                    <Text style={modalStyles.titleText}>{modalTitle}</Text>
                    <ScrollView>
                        <RenderHTML source={{html:modalContent}}/>
                    </ScrollView>
                </View>

            </Modal>


            {/*돌아가기*/}
            <Pressable
                style={styles.xButtonWrapper}
                onPress={backButton}>
                <Image style={styles.xButton} source={xImage}/>
            </Pressable>
            <View style={[styles.bannerWrapper]}>
                {/*이미지 위치*/}
                <View style={styles.horizonWrapper}>
                    <Image style={styles.bannerImage} source={bannerIcon}/>
                </View>
                <Text style={styles.loginText}>
                    이미 회원이신가요?
                    <Text style={styles.linkText} onPress={()=>navigation.push('Login')}>
                        로그인하기
                    </Text>
                </Text>
            </View>


            <View style={styles.dataFormWrapper}>
                {/*Signup Data From*/}
                <SignupEmailData title={'이메일'} value={email} setValue={setEmail} />
                <SignupData title={'닉네임'} value={nickname} setValue={setNickname} placeHolder={'닉네임을 입력해주세요'}
                            isPassword={undefined}/>
                <SignupData title={'비밀번호'} value={password} setValue={setPassword} placeHolder={'비밀번호를 입력해주세요'} isPassword={true} />
                <SignupData title={'비밀번호 확인'} value={checkPassword} setValue={setCheckPassword} placeHolder={'비밀번호를 입력해주세요'} isPassword={true} />
                <SignupData title={'이름'} value={name} setValue={setName} placeHolder={'이름을 입력해주세요'}
                            isPassword={undefined}/>

                <SignupPhoneNumberData title={'휴대전화번호'} value={phoneNumber} setValue={setPhoneNumber}/>
                {/*<SignupBirthAgeData title={'생년'} value={birthYear} setValue={setBirthYear}/>*/}
                <SignupBirthYearData value={birthYear} setValue={setBirthYear}/>
                <SignupDataAddress
                    title={'주소'}
                    value={address}
                    setValue={setAddress}
                    setZipcode={setZipCode}
                />
                <SignupData title={'고향'} value={homeTown} setValue={setHomeTown} placeHolder={'고향을 입력 하세요'} isPassword={false} />
                <SignupSexData value={sex} setValue={setSex}/>
                <SignupPickerData title={'직업'} value={job} setValue={setJob} etcValue={morejob} setEtcValue={setMoreJob} data={jobCategory}/>
                <SignupPickerData title={'관심사'} value={hobby} setValue={setHobby} etcValue={morehobby} setEtcValue={setMoreHobby} data={hobbyCategory}/>

                <View style={styles.termsWrapper}>
                    <CheckBox style={[styles.checkBox]} value={agree} onValueChange={setAgree} color={'#2C4198'} />
                    <Text style={[styles.termsText,{flex: 1}]}>이분의일 약관에 모두 동의합니다</Text>
                    <Pressable
                        onPress={()=>{
                            /*const request: termsRequest = {
                                title: "운영약관"
                            }
                            termsInstance.mutate(request)*/
                            Linking.openURL('https://1halfkr.notion.site/fc747437f8a74d96960f25538a687740')
                        }}
                    >
                        <Text style={styles.termsButtonText}>약관보기</Text>
                    </Pressable>

                </View>

                <Pressable
                    style={[styles.buttonWrapper,!agree&&{backgroundColor: 'gray'}]}
                    activeOpacity={agree?0.5:1}
                    onPress={()=>{
                        if (agree) {
                            const data: userEntity = {
                                username: name, //디자인 기획 상 안들어 갈 확률이 높음.
                                id: email,
                                password: password,
                                email: email,
                                phoneNumber: phoneNumber,
                                address: `${address}`,
                                job: job,
                                jobMore: job===24?morejob:undefined,
                                hobby: hobby,
                                hobbyMore: hobby===24?morehobby:undefined,
                                nickname: nickname,
                                sex: sex,//디자인 어떻게 나올지 모름
                                hometown: homeTown,
                                birthyear: birthYear,
                                bookSalon: [],
                                role: '000001',
                            }

                            for (const value in data) {
                                if (data[value] === '' || value === undefined)
                                    if (value==='bookSalon') {
                                    }else {
                                        return alert(`비어있는 항목이 존재 합니다 ${value}`)
                                    }
                            }
                            if (password !== checkPassword) {
                                return alert('비밀번호를 확인해주세요')
                            }


                            if (birthYear/1000 < 1) {
                                return alert('생년 4자리를 입력해주세요')
                            }

                            if(isEmailValidation(email) === false) {
                                return alert('이메일 형식이 맞지 않습니다')
                            }

                            nicknameCheck.mutate({nickname: data.nickname}, {
                                onSuccess: ({data})=>{
                                    if (data.status) {
                                        const _data = data.data
                                        if (_data === false) {
                                            setNicknameIsOK(false)
                                            return alert('닉네임을 사용 할수 없습니다')
                                        }else {
                                            setNicknameIsOK(true)
                                        }
                                    }

                                }
                            })

                            idCheck.mutate({id: email},{
                                onSuccess: ({data})=>{
                                    if(data.status) {
                                        const _data = data.data
                                        if (_data === false){
                                            setIdIsOK(false)
                                            return alert('이 이메일은 사용 할수 없습니다')
                                        }else {
                                            setIdIsOK(true)
                                        }
                                    }

                                }
                            })

                            phoneNumberCheck.mutate({phonenumber: phoneNumber}, {
                                onSuccess: ({data}) => {
                                    if(data.status) {
                                        const _data = data.data
                                        if (_data === false){
                                            setPhoneNumberIsOk(false)
                                            return alert('이 전화번호는 사용 할수 없습니다')
                                        }else {
                                            setPhoneNumberIsOk(true)
                                        }
                                    }
                                }
                            })

                        }else {
                            return  agree&&alert('약관에 동의가 필요합니다');
                        }

                    }}
                >
                    <Text style={[styles.buttonText]}>회원가입</Text>
                </Pressable>

            </View>
        </View>
    );

}

const styles = StyleSheet.create({
    xButtonWrapper: {
        margin: PixelRatio.getPixelSizeForLayoutSize(16),
        alignSelf: 'flex-end'
    },
    xButton: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),

    },
    bannerWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(39),
        alignItems: 'center'
    },
    bannerImage: {
        width: PixelRatio.getPixelSizeForLayoutSize(154),
        height: PixelRatio.getPixelSizeForLayoutSize(36),
    },
    loginText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'gray',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: 'bold',
    },
    linkText: {
        color: '#2C4198',
    },
    defaultText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: 'bold',
    },
    smallText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },
    checkBox: {
        width: PixelRatio.getPixelSizeForLayoutSize(15),
        height: PixelRatio.getPixelSizeForLayoutSize(15),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    horizonWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    dataFormWrapper: {
        width: '100%',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(41),
        paddingHorizontal:PixelRatio.getPixelSizeForLayoutSize(25),
    },
    termsWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(22),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(34)
    },
    termsText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
        color: '#676767',
    },
    termsButtonText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
        color: '#000',
        textDecorationLine: 'underline'
    },
    buttonWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(54),
        backgroundColor: '#2C4198',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        justifyContent: 'center',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(100),
    },
    buttonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
        color: 'white',
        textAlign: 'center',
    }
});

const modalStyles = StyleSheet.create({
    closeWrapper: {
        alignItems: 'flex-end',
        padding: PixelRatio.getPixelSizeForLayoutSize(16)
    },
    closeIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
    },
    wrapper: {
        padding: PixelRatio.getPixelSizeForLayoutSize(16),
        flex: 1,
    },
    titleText: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(28),
        fontWeight: '700',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    contentText: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '500',
        flex: 1,
    }
})

export default  Signup;
