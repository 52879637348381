import React, {useEffect, useState} from "react";
import {
    FlatList,
    Image,
    PixelRatio,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    Pressable,
    View
} from "react-native";
import PublicTopMenu from '../components/public/PublicTopMenu.tsx'
import {
    anotherAnswerRequest,
    boardReadRequest,
    boardReplySaveRequest,
    boardResponse,
    favoriteRequest,
    favoriteResponse, responseWrapper, showFavoriteRequest
} from "../api/entity";
import {publicWrapper} from '../components/public/PublicStyle'
import reportIcon from '../assets/images/report.png'
import arrow_left from '../assets/images/arrow_left.png'
import arrow_right from '../assets/images/arrow_right_black.png'
import lockIcon from '../assets/images/lock_icon.svg'
import favorite_fill from '../assets/images/good_fill.png'
import favorite_stroke from '../assets/images/good_stroke.png'
import reply_icon from '../assets/images/reply.png'
import view_icon from  '../assets/images/eye.png'
import reply_menu_icon from  '../assets/images/reply_menu.svg'
import getDate from '../components/public/getDate'
import ReportMenu from '../components/public/ReportMenu.tsx'
import checkLogin from "../components/public/checkLogin.ts";
import {useMutation} from "react-query";
import client from "../api/client.ts";
import {board,favorite} from '../api/urls'
import {useIsFocused} from "@react-navigation/native";
import FavoriteInstance from '../api/FavoriteConnection.ts'
import ReplyListItem from "../components/answerdetail/ReplyListItem.tsx";
import answer from "./Answer";
import {isDesktop} from "react-device-detect";
import login from "./Login";
import AsyncStorage from "@react-native-async-storage/async-storage";


function AnswerDetail({navigation, route}) {



    const answer = route.params.answer
    const answerInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<boardResponse>>(board.get.detail(board.categoryType.answer,answer.uid)),
        onSuccess:({data})=>{
            if(data.status) {
                const _data = data.data
                setAnswerData(_data)
                setIsFavoriteAnswer(_data.favorite)
                setAnswerUid(_data.uid)
                setAnswerFavoriteCount(_data.favoriteCount)
            }
        }
    })
    const [answerData,setAnswerData] = useState<boardResponse>(route.params.answer)
    const [answerUid, setAnswerUid] = useState(answer.uid)
    const [replyCount,setReplyCount] = useState(answerData.replyCount)

    const [anotherAnswerList,setAnotherAnswerList] = useState([])

    const anotherAnswerInstance = useMutation({
        mutationFn: (data: anotherAnswerRequest)=> client.post<responseWrapper<boardResponse[]>>(board.post.anotherAnswer,data),
        onSuccess: ({data})=>{
            if (data.status) {
                const _data = data.data
                setAnotherAnswerList(_data)
            }
        }
    })

    const [isFavoriteAnswer,setIsFavoriteAnswer] = useState(false)
    const answerFavoriteChangeInstance = FavoriteInstance('answer',isFavoriteAnswer,answerUid)
    const answerFavoriteAction = ()=> {
        answerFavoriteChangeInstance.mutate(null,{
            onSuccess: ({data, headers})=>{
                if (data.status) {
                    setAnswerFavoriteCount(answerFavoriteCount+1)
                    setIsFavoriteAnswer(!isFavoriteAnswer)
                } else {
                    setAnswerFavoriteCount(answerFavoriteCount-1)
                    setIsFavoriteAnswer(!isFavoriteAnswer)
                }
            }
        })
    }
    const [answerFavoriteCount, setAnswerFavoriteCount] = useState(0)


    const replyInstance = useMutation({
        mutationFn: (data: boardReadRequest)=> client.post<responseWrapper<boardResponse[]>>(board.get.read(board.categoryType.reply),data)
    })
    const replyRequest: boardReadRequest = {
        parentUid: answer.uid,
    }
    const [lastUid,setLastUid] = useState(undefined)
    const [replyData,setReplyData] = useState([])


    const writeReplyInstance = useMutation({
        mutationFn: (data: boardReplySaveRequest)=> client.post<responseWrapper<boardResponse>>(board.post.save(board.categoryType.reply),data)
    })

    const writeReplyAction = ()=> {

        if(replyText.length === 0){
            return alert('댓글 내용을 입력해주세요.')
        }

        const saveReplyRequest: boardReplySaveRequest = {
            answerUid: answer.uid,
            content: replyText,
        }

        writeReplyInstance.mutate(saveReplyRequest,{
            onSuccess: ({data})=> {
                if (data.status) {
                    replyInstance.mutate(replyRequest, {
                        onSuccess: ({data})=> {
                            if (data.status) {
                                const _data = data.data
                                setReplyData(_data)
                                setReplyCount(_data.length)
                            }

                        }
                    })
                    setReplyText('')
                }

            }
        })
    }

    const [answerFavoriteList, setAnswerFavoriteList] = useState()
    const [replyFavoriteList, setReplyFavoriteList] = useState()

    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }
            })
            answerInstance.mutate()
            replyInstance.mutate(replyRequest,{
                onSuccess: ({data})=> {
                    if (data.status) {
                        const _data = data.data
                        setReplyData(_data)
                        const length = _data.length > 0? _data.length-1 : undefined
                        if (length !== undefined) {
                            setLastUid(_data[length].uid)
                        }
                    }

                }
            })

            const anotherRequest: anotherAnswerRequest = {
                userUid: answerData.userUid
            }
            anotherAnswerInstance.mutate(anotherRequest)
        }
    },[useIsFocused()])

    const [replyText, setReplyText] = useState('')

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <ReportMenu navigation={navigation} route={route} category={'answer'} articleData={answerData}/>
            <ScrollView style={[publicWrapper.backgrounded,{padding: 0}]}>
                {/*답글*/}
                <Pressable
                    style={styles.answerWrapper}
                    onPress={()=>{
                        // 이거 답글 목록으로 가려면 parentUid 필요 함
                        // 모든 답글(Community) 에서 이동 시엔 parentUid를 구할 방법이 전무하기 때문에
                        // 해결불가
                        navigation.pop()
                    }}
                >
                    <Text style={styles.answerCategoryText}>{answerData.keywordString}</Text>
                    <Text style={styles.answerTitleText}>{answerData.title}</Text>
                    <View style={styles.separatorVertical}/>
                    <Text style={styles.answerContentsText}>{answerData.content}</Text>
                    <View style={styles.horizontalWrapper}>
                        <Text style={styles.answerNameText} numberOfLines={1}>{answerData.nickname}</Text>
                        <View style={styles.separatorHorizontal}/>
                        <Text style={styles.answerSubText}>{getDate(answerData.registerDate)}</Text>
                        <View style={{flex: 1}}/>
                        <Pressable
                            style={styles.horizontalWrapper}
                            onPress={answerFavoriteAction}
                        >
                            <Image style={styles.answerIcons} source={isFavoriteAnswer?favorite_fill:favorite_stroke} />
                            <Text style={styles.answerSubText}>추천 {answerFavoriteCount}</Text>
                        </Pressable>

                        <View style={styles.separatorHorizontal}/>
                        <Image style={styles.answerIcons} source={reply_icon} />
                        <Text style={styles.answerSubText}>댓글 {replyCount}</Text>
                        <View style={styles.separatorHorizontal}/>
                        <Image style={styles.answerIcons} source={view_icon} />
                        <Text style={styles.answerSubText}>조회 {answerData.hits}</Text>
                    </View>
                </Pressable>
                <View style={styles.replyWrapper}>
                    <View style={styles.replyMenuWrapper}>
                        <Image style={styles.replyMenuIcon} source={reply_menu_icon}/>
                        <Text style={styles.replyMenuText}>댓글</Text>

                    </View>
                    <FlatList
                        style={styles.replyListWrapper}
                        data={replyData}
                        renderItem={({item})=><ReplyListItem item={item} />}
                        onEndReached={()=> {
                            const request: boardReadRequest = {
                                parentUid: answer.uid,
                                lastUid: lastUid
                            }
                            replyInstance.mutate(request, {
                                onSuccess: ({data})=>{
                                    if (data.status) {
                                        const _data = data.data
                                        if(_data.length>0) {
                                            setReplyData([...replyData, ..._data])
                                            const length = _data.length > 0? _data.length-1 : undefined
                                            if (length !== undefined) {
                                                setLastUid(_data[length].uid)
                                            }
                                        }
                                    }

                                }
                            })
                        }}
                    />
                </View>

                {/*Reply Write*/}
                <View style={styles.writeReplyWrapper}>
                    <View style={styles.writeReplyInnerWrapper}>
                        <TextInput
                            style={styles.writeReplyText}
                            multiline={true}
                            value={replyText}
                            onChangeText={setReplyText}
                            placeholder={'댓글을 입력해주세요.'}
                            placeholderTextColor={'#777777'}
                            maxLength={200}
                        />
                        <View style={styles.writeReplyBottomWrapper}>
                            <Text style={styles.replyLengthText}>{replyText.length}</Text>
                            <Text style={styles.replyMaxLengthText}>/200</Text>
                            <View style={{flex: 1}}/>
                            <Pressable
                                style={styles.writeReplyButtonWrapper}
                                onPress={writeReplyAction}
                            >
                                <Text style={styles.writeReplyButtonText}>등록</Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
                    <View style={styles.otherAnswerTitleWrapper}>
                        <Text style={styles.otherAnswerUserNameText}>{answerData.nickname}</Text>
                        <Text style={styles.otherAnswerTitleText}>님의 다른 답글</Text>
                    </View>

                    <FlatList
                        style={styles.otherAnswerListWrapper}
                        data={anotherAnswerList}
                        renderItem={({item})=>{
                            return(
                                <Pressable
                                    style={styles.otherAnswerItemWrapper}
                                    onPress={()=>{
                                        navigation.push('AnswerDetail',{answer: item})
                                    }}
                                >
                                    <Text
                                        style={styles.otherAnswerKeywordText}
                                        numberOfLines={1}
                                    >
                                        {item.title}
                                    </Text>
                                    <View style={{width: PixelRatio.getPixelSizeForLayoutSize(10)}}/>
                                    <Text
                                        style={styles.otherAnswerContentsText}
                                        numberOfLines={1}
                                    >
                                        {item.content}
                                    </Text>
                                    <Image style={styles.otherArrowIcon} source={arrow_right}/>
                                </Pressable>
                            )

                        }}
                    />
                </ScrollView>

        </View>
    )
}

const styles = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    separatorVertical: {
        backgroundColor: '#E5E5E5',
        height: PixelRatio.getPixelSizeForLayoutSize(1),
    },
    separatorHorizontal: {
        backgroundColor: '#E5E5E5',
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5)
    },
    answerWrapper: {
        backgroundColor: 'white',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(20),
        marginTop: PixelRatio.getPixelSizeForLayoutSize(6),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12)
    },
    answerCategoryText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '700'
    },
    answerTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(19),
        fontWeight: '700',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(11),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(19),
    },
    answerContentsText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '300',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(18),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(20),
    },
    answerNameText: {
        fontFamily: 'KoPub_Dotum_Pro',
        maxWidth: PixelRatio.getPixelSizeForLayoutSize(50),
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
    },
    answerSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(11),
        fontWeight: '500',
    },
    answerIcons: {
        width: PixelRatio.getPixelSizeForLayoutSize(14),
        height: PixelRatio.getPixelSizeForLayoutSize(14),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(4),
    },
    replyWrapper: {
      height: PixelRatio.getPixelSizeForLayoutSize(250)
    },
    replyMenuWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(50),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(12),
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#E5E5E5'
    },
    replyMenuText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
    },
    replyMenuIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(22),
        height: PixelRatio.getPixelSizeForLayoutSize(22),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(6),
    },
    replyListWrapper: {
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
    },
    writeReplyWrapper: {
        padding: PixelRatio.getPixelSizeForLayoutSize(16),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    writeReplyInnerWrapper: {
        backgroundColor: 'white',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#D9D9D9',
    },
    writeReplyText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        height: PixelRatio.getPixelSizeForLayoutSize(121),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(18),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(20),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#D9D9D9',
    },
    writeReplyBottomWrapper: {
        flexDirection: 'row',
        height: PixelRatio.getPixelSizeForLayoutSize(51),
        alignItems: 'center',
        paddingLeft: PixelRatio.getPixelSizeForLayoutSize(17),
        paddingRight: PixelRatio.getPixelSizeForLayoutSize(11),
    },
    writeReplyButtonWrapper: {
        backgroundColor: '#2C4198',
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(9),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(15),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    writeReplyButtonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    replyLengthText: {
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '700'
    },
    replyMaxLengthText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500'
    },
    otherAnswerWrapper: {
        backgroundColor: 'white',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
    },
    otherAnswerTitleWrapper: {
        backgroundColor: 'white',
        flexDirection: 'row',
        height: PixelRatio.getPixelSizeForLayoutSize(51),
        alignItems: 'center',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#E5E5E5',

    },
    otherAnswerUserNameText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700'
    },
    otherAnswerTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700'
    },
    otherAnswerListWrapper: {
        backgroundColor: 'white',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
    },
    otherAnswerItemWrapper: {
        flexDirection: 'row',
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(15),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#E5E5E5',
        alignItems: 'center',
    },
    otherAnswerKeywordText :{
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#777777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
        width: '30%'
    },
    otherAnswerContentsText :{
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
        flex: 1
    },
    otherArrowIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(7.4),
        height: PixelRatio.getPixelSizeForLayoutSize(12),
    }

})

export default AnswerDetail;
