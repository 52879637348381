import React, {useEffect, useState} from "react";
import TopMenu from "../components/myquestion/TopMenu.tsx";
import PublicTopMenu from '../components/public/PublicTopMenu.tsx'
import {publicWrapper} from '../components/public/PublicStyle'
import {FlatList, Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import {useIsFocused} from "@react-navigation/native";
import {useMutation} from "react-query";
import {favoriteRequest, myFavoriteBoard, myFavoriteResponse, myQuestionRequest} from "../api/entity";
import client from "../api/client.ts";
import {myQuestion} from '../api/urls'
import {isDesktop} from "react-device-detect";
import MyQuestionAnswerListItem from "../components/myquestion/MyQuestionAnswerListItem.tsx";
import AsyncStorage from "@react-native-async-storage/async-storage";

function MyQuestionAnswer({navigation, route}){

    const [answerCount, setAnswerCount] = useState(0)
    const [boardData, setBoardData] = useState<myFavoriteBoard[]>([])
    const [lastUid, setLastUid] = useState(0)

    const boardInstance = useMutation({
        mutationFn: (data: myQuestionRequest)=>client.post<myFavoriteResponse>(myQuestion.post.myAnswer,data)
    })


    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }
            })
            const boardRequest: myQuestionRequest = {
                lastUid: undefined
            }
            boardInstance.mutate(boardRequest, {
                onSuccess: ({data})=> {
                    setAnswerCount(data.answerCount)
                    setBoardData(data.questionList)
                    const length = data.questionList.length > 0? data.questionList.length-1 : undefined
                    if (length !== undefined) {
                        setLastUid(data.questionList[length].questionUid)
                    }
                }
            })
        }
    },[useIsFocused()])


    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <TopMenu navigation={navigation} route={route}/>
            <View style={publicWrapper.backgrounded}>
                <View style={styles.topWrapper}>
                    <Text style={styles.titleText}>답변완료한 질문 수 </Text>
                    <Text style={styles.titleNumberText}>{answerCount}</Text>
                </View>
                <FlatList
                    data={boardData}
                    renderItem={({item}):myFavoriteBoard=>{
                        let favorite = item.favorite
                        return (
                            <MyQuestionAnswerListItem navigation={navigation} item={item}/>
                        )
                    }}
                    onEndReached={()=>{
                        const request: myQuestionRequest = {
                            lastUid: lastUid
                        }
                        boardInstance.mutate(request, {
                            onSuccess: ({data})=> {
                                const length = data.questionList.length > 0? data.questionList.length-1 : undefined
                                if (length !== undefined) {
                                    setAnswerCount(data.answerCount)
                                    setBoardData([...boardData,...data.questionList])
                                    setLastUid(data.questionList[length].questionUid)
                                }
                            }
                        })
                    }}
                />
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    horizontalSeparator: {
        backgroundColor: '#DDDDDD',
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8)
    },
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500'
    },
    titleNumberText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '700'
    },
    topWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(46),
        flexDirection: 'row',
        alignItems: 'center',
    },
})
export default MyQuestionAnswer
