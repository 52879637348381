import React, {useEffect, useState} from "react";
import {Text, View, Image, TextInput, Pressable, FlatList} from "react-native";
import {ImageBackground, Modal, PixelRatio, StyleSheet} from "react-native-web";
import logo from '../../assets/images/logo.svg'
import search from '../../assets/images/search.png'
import menu from '../../assets/images/menu.png'
import x_icon from '../../assets/images/x_icon.svg'
import search_icon from '../../assets/images/search.svg'
import alert_icon from '../../assets/images/alert_icon.svg'
import {MenuName} from "./MenuName.ts";
import {useMutation} from "react-query";
import client from "../../api/client.ts";
import {board} from "../../api/urls";
import {boardResponse, categoryResponse, responseWrapper, searchRequest} from '../../api/entity.ts'
import {isDesktop} from "react-device-detect";
import {publicWrapper} from './PublicStyle'
import QuestionBoardItem from "../allquestion/QuestionBoardItem.tsx";
import PublicScripts from './PublicScripts'
function PublicTopMenu({navigation, route}) {

    const mainNumber = route.params.menu===0 ? 0 : Math.floor(route.params.menu / 10) *10
    const [isVisible,setIsVisible] = useState(false)

    const [searchString,setSearchString] = useState('')
    const [isSearch,setIsSearch] = useState(false)

    const [lastUid, setLastUid] = useState()

    const [boardData, setBoardData] = useState([])
    const searchInstance = useMutation({
        mutationFn: (data: searchRequest)=> client.post<responseWrapper<boardResponse[]>>(board.post.search,data),
    })

    useEffect(()=>{
        if(isVisible){
            if (client.defaults.headers.Authorization===undefined) {
                setIsVisible(false)
                alert('로그인 페이지로 이동합니다')
                navigation.push('Login')
            }
        }else {
            setSearchString('')
            setBoardData([])
            setIsSearch(false)
        }
    },[isVisible])

    const emptyView = (
        <View style={modalStyle.emptyWrapper} >
            <Image source={alert_icon} style={modalStyle.alert_icon}/>
            <Text style={modalStyle.emptyText}>검색 결과가 없습니다</Text>
        </View>
    )
    const boardList = (
        <FlatList
            style={{width: '100%'}}
            data={boardData}
            renderItem={({item})=>{
                return (
                    <QuestionBoardItem item={item} navigation={navigation} setOption={setIsVisible}/>
                )
            }}
            onEndReached={()=> {
                const request: searchRequest = {
                    title: searchString,
                    lastUid: lastUid
                }

                searchInstance.mutate(request,{
                    onSuccess: ({data}) => {
                        if(data.status) {
                            setIsSearch(true)
                            const length = data.data.length>0?data.data.length:undefined
                            if (length!==undefined) {
                                setBoardData([...boardData, ...data.data])
                                setLastUid(data.data[length-1].uid)
                            }
                        }
                    }
                })
            }}
        />
    )

    return (
        <View style={styles.wrapper}>
            <PublicScripts/>
            {/*검색 화면 부분*/}
            <Modal
                visible={isVisible}
                transparent={true}
            >
                <View style={[{height: window.innerHeight},isDesktop&&publicWrapper.deskTopWrapper, {height: '100%'}]}>
                    <View style={modalStyle.contentsWrapper}>
                        <View style={modalStyle.horizontalWrapper}>
                            <View style={{flex:1}}/>
                            <Pressable
                                style={modalStyle.horizontalWrapper}
                                onPress={()=>setIsVisible(!isVisible)}
                            >
                                <Image style={modalStyle.x_icon} source={x_icon}/>
                            </Pressable>
                        </View>
                        <View style={modalStyle.searchWrapper}>
                            <View style={[modalStyle.horizontalWrapper,{gap: 8}]}>
                                <TextInput
                                    style={modalStyle.searchInputText}
                                    value={searchString}
                                    onChangeText={setSearchString}
                                    placeholder={'질문 키워드를 입력하세요'}
                                    placeholderTextColor={'#C2C2C2'}
                                />
                                <Pressable
                                    onPress={()=>{
                                        const request: searchRequest = {
                                            title: searchString
                                        }

                                        searchInstance.mutate(request,{
                                            onSuccess: ({data}) => {
                                                if(data.status) {
                                                    setIsSearch(true)
                                                    const length = data.data.length>0?data.data.length:undefined
                                                    if (length!==undefined) {
                                                        setBoardData(data.data)
                                                        setLastUid(data.data[length-1].uid)
                                                    }else{
                                                        setBoardData([])
                                                    }
                                                }
                                            }
                                        })
                                    }}
                                >
                                    <Image style={modalStyle.searchIcon} source={search_icon}/>
                                </Pressable>
                            </View>
                            <View style={modalStyle.verticalSeparator}/>
                        </View>
                        <View style={modalStyle.boardWrapper}>
                            {isSearch&&boardData.length === 0?emptyView:boardList}
                        </View>
                    </View>
                </View>

            </Modal>


            <View style={styles.topVav}>
                <Image
                    style={styles.icon}
                    source={logo}
                />
                <View style={{flex: 1}}/>

                <Pressable onPress={()=>setIsVisible(true)}>
                    <ImageBackground
                        source={search}
                        style={[styles.image, {marginRight: PixelRatio.getPixelSizeForLayoutSize(12),}]}
                    />
                </Pressable>

                <Pressable onPress={()=>navigation.push('Menu',{menu:0})}>
                    <ImageBackground
                        source={menu}
                        style={[styles.image, {marginRight: PixelRatio.getPixelSizeForLayoutSize(18),}]}
                    />
                </Pressable>
            </View>
            {/*하단 매뉴 부분*/}
            <View style={styles.topMenu}>
                <Pressable
                    style={[
                        styles.buttonWrapper,
                        mainNumber===MenuName.Main && styles.selectedButtonWrapper
                    ]}
                    activeOpacity={1}
                    onPress={()=>navigation.push('Main',{menu: MenuName.Main})}
                >
                    <Text
                        style={[
                            styles.button,
                            mainNumber===MenuName.Main && styles.selectedButton
                        ]}
                    >
                        HOME
                    </Text>
                </Pressable>
                <Pressable
                    style={[
                        styles.buttonWrapper,
                        mainNumber===MenuName.AllQuestion && styles.selectedButtonWrapper
                    ]}
                    activeOpacity={1}
                    onPress={()=>navigation.push('AllQuestions',{menu: MenuName.AllQuestion})}
                >
                    <Text
                        style={[
                            styles.button,
                            mainNumber===MenuName.AllQuestion && styles.selectedButton
                        ]}
                    >
                        모든질문
                    </Text>
                </Pressable>
                <Pressable
                    style={[
                        styles.buttonWrapper,
                        mainNumber===MenuName.Community && styles.selectedButtonWrapper
                    ]}
                    activeOpacity={1}
                    onPress={()=>navigation.push('Community',{menu: MenuName.Community})}
                >
                    <Text
                        style={[
                            styles.button,
                            mainNumber===MenuName.Community && styles.selectedButton
                        ]}
                    >
                        모든답글
                    </Text>
                </Pressable>
                <Pressable
                    style={[
                        styles.buttonWrapper,
                        mainNumber===MenuName.MyQuestionHeart && styles.selectedButtonWrapper
                    ]}
                    activeOpacity={1}
                    onPress={()=>navigation.push('MyQuestion')}
                >
                    <Text style={[
                        styles.button,
                        mainNumber==MenuName.MyQuestionHeart && styles.selectedButton
                    ]}>
                        나의질문
                    </Text>
                </Pressable>
            </View>
        </View>

    );
}

const  styles = StyleSheet.create({
    wrapper: {
        position: 'sticky',
        overflow: 'auto',
        backgroundColor: '#2C4198',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
    },
    topVav: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        height: PixelRatio.getPixelSizeForLayoutSize(51),
    },
    topMenu: {
        flex: 1,
        height: PixelRatio.getPixelSizeForLayoutSize(47),
        justifyContent: 'space-around',
        flexDirection: 'row',
    },
    image: {
        width: PixelRatio.getPixelSizeForLayoutSize(24),
        height: PixelRatio.getPixelSizeForLayoutSize(24),
        justifyContent: 'center',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(14),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(13),
    },
    buttonWrapper: {
        justifyContent:'center',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(14),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    button: {
        textAlign: 'center',
        color: '#FFFFFFB2',
        autosize:true,
        fontFamily: 'SCDream5',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '200',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(19),
    },
    selectedButtonWrapper: {
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(2),
        borderColor: '#ffffff80'
    } ,
    selectedButton: {
        fontWeight: '600',
        color: '#FFFFFF',
    },
    titleText: {
        fontFamily: 'SCDream5',
        flex: 1,
        margin: PixelRatio.getPixelSizeForLayoutSize(10),
        textAlign: 'left',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '600'
    },
    icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(107.4),
        height: PixelRatio.getPixelSizeForLayoutSize(25),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(20),
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(13),
    }
});

const modalStyle = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    contentsWrapper: {
        backgroundColor: 'white',
        height: '100%',
    },
    x_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
        marginTop: PixelRatio.getPixelSizeForLayoutSize(18),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    boardWrapper: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    emptyWrapper: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    emptyText: {
        color: '#777777',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(15)
    },
    alert_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
    },
    searchWrapper: {
        marginTop: PixelRatio.getPixelSizeForLayoutSize(15),
        paddingLeft: PixelRatio.getPixelSizeForLayoutSize(23),
        paddingRight: PixelRatio.getPixelSizeForLayoutSize(21),
    },
    searchInputText: {
        minWidth: PixelRatio.getPixelSizeForLayoutSize(200),
        height: PixelRatio.getPixelSizeForLayoutSize(32),
        color: '#232323',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '700',
        letterSpacing: -1,
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(8),
        flex: 1
    },
    searchIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(24),
        height: PixelRatio.getPixelSizeForLayoutSize(24),
    },
    verticalSeparator: {
        backgroundColor: '#2C4198',
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(2),
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(8)
    },

})

export default PublicTopMenu;
