import axios from "axios";
import {loginResult, responseWrapper} from "./entity";
import userContext from "../context/userContext.ts";
import {user} from './urls'
import AsyncStorage, {useAsyncStorage} from "@react-native-async-storage/async-storage";
import getDate from '../components/public/getDate'
import {createNavigatorFactory} from "@react-navigation/native";

const url = 'https://halfgusul.o-r.kr'
const client = axios.create({
    baseURL: 'https://server.2geul.kr',
});




client.interceptors.response.use((res) => {

    const data: responseWrapper<any> = res.data
    if(data.status === false) {
        if(data.errorcode === 98) { //Token has expired.
            // console.log(`Error! Token has expired. ${data.message}`)
            applyToken(userContext.getRefreshToken)
            client.post<responseWrapper<loginResult>>(user.get.refresh).then((res)=>{
                if(res.data.status) {
                    const _data = res.data.data
                    userContext.setRefreshToken(_data.refreshToken)
                    applyToken(_data.token)
                    useAsyncStorage('token').setItem(JSON.stringify(_data))
                }
            })
        }
        else if(data.errorcode === 4) { //Refresh Token is Missing.
            //console.log('Refresh Token is Missing!')
            userContext.setUser(undefined)
            userContext.setRefreshToken(undefined)
            AsyncStorage.clear()
            cleanToken()
        }else if(data.errorcode===97) {
            cleanToken()
            userContext.setUser(undefined)
            userContext.setRefreshToken(undefined)
            AsyncStorage.clear()
        }
        console.log(`${data.errorcode}`)
    }else {
    }

    //console.log(res.data)
    return res
}, (error)=> {
    console.log(error)
})




export function applyToken(jwt: string){
    client.defaults.headers.Authorization = `Bearer ${jwt}`
}

export function cleanToken(){
    client.defaults.headers.Authorization = undefined;
}


export default client;
