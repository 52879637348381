import React, {useEffect, useState} from "react";
import {FlatList, Image, PixelRatio, StyleSheet, Text, TextInput, Pressable, View} from "react-native";
import TopMenu from "../components/myquestion/TopMenu.tsx";
import PublicTopMenu  from '../components/public/PublicTopMenu.tsx'
import {publicWrapper} from '../components/public/PublicStyle'
import arrow from '../assets/images/arrow_left.png'
import {Picker} from "react-native-web";
import {useMutation} from "react-query";
import client from "../api/client.ts";
import {boardQuestionSaveRequest, boardResponse, categoryResponse, responseWrapper} from "../api/entity";
import {board} from '../api/urls'
import {useIsFocused} from "@react-navigation/native";
import CheckLogin from "../components/public/checkLogin.ts";
import {isDesktop} from "react-device-detect";
import {set} from "mobx";
import AsyncStorage from "@react-native-async-storage/async-storage";

function MyQuestionWriteQuestion({navigation,route}){

    const [questionString,setQuestionString] = useState('')
    const [selectCategory,setSelectCategory] = useState(-1)
    const [categoryList,setCategoryList] = useState<categoryResponse>([])

    const categoryInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<categoryResponse[]>>(board.post.getCategoryCode),
        onSuccess: ({data})=> {
            if(data.status) {
                const _data = data.data
                setCategoryList(_data)
                setSelectCategory(_data[0].code)
            }

        }
    })

    const saveQuestionInstance = useMutation({
        mutationFn:(data: boardQuestionSaveRequest)=>client.post<responseWrapper<boardResponse>>(board.post.save(board.categoryType.question),data),
        onSuccess:({data})=>{
            if(data.status) {
                setQuestionString('')

                navigation.pop()
            }

        }
    })



    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }else {
                    categoryInstance.mutate()
                }
            })

        }
    },[useIsFocused()])

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <View style={styles.topWrapper}>
                <Pressable
                    style={styles.horizontalWrapper}
                    onPress={()=>{
                        navigation.pop()
                    }}
                >
                    <Image style={styles.arrowIcon} source={arrow}/>
                    <Text style={styles.titleText}>돌아가기 </Text>
                </Pressable>
            </View>
            <View style={publicWrapper.backgrounded}>
                <View style={styles.categoryWrapper}>
                    <Picker
                        style={styles.categoryPicker}
                        selectedValue={selectCategory}
                        onValueChange={(v,i)=>{
                            setCategoryList(categoryList.filter((v)=>v.code!==-1))
                            setSelectCategory(v)
                        }}
                    >
                        {categoryList.map((v:categoryResponse)=>{
                            return <Picker.Item label={v.description} value={v.code}/>
                        })}
                    </Picker>
                </View>
                <View>
                    <TextInput
                        style={styles.questionInputText}
                        value={questionString}
                        onChangeText={setQuestionString}
                        multiline={true}
                        placeholder={'질문 내용을 입력하세요.\n' +
                            '질문은 한 번 등록하시면 삭제 및 수정할 수 없으며 비공개 처리만 가능합니다.\n' +
                            '등록 후 기본상태는 비공개입니다. 일단 질문을 공개하시면 비공개 처리하시더라도, 공개 당시 내 질문에 답글을 작성했던 회원이 자신의 \'답한질문\'에서 질문을 볼 수 있습니다.\n' +
                            '부적절한 질문을 작성하면 다른 회원들에게 신고를 받아 블라인드 처리될 수 있으니 유의하시기 바랍니다. 신고로 블라인드 처리된 질문의 경우 \'책만들기\'에서 txt파일로 다운로드 받으실 수 없습니다.'}
                        placeholderTextColor={'#999'}
                        maxLength={50}
                    />
                    <View style={styles.inputTextLengthWrapper}>
                        <Text style={styles.inputTextLength}>{questionString.length}</Text>
                        <Text style={styles.inputTextMaxLength}>/50</Text>
                    </View>

                </View>

                <View style={styles.bottomWrapper}>
                    <Pressable
                        style={styles.bottomCancelButtonWrapper}
                        onPress={()=>navigation.pop()}
                    >
                        <Text style={styles.bottomCancelButtonText}>취소</Text>
                    </Pressable>
                    <View style={{width:PixelRatio.getPixelSizeForLayoutSize(14)}}/>
                    <Pressable
                        style={styles.bottomSubmitButtonWrapper}
                        onPress={()=>{
                            if(selectCategory === -1) {
                                return alert('주제를 선택해 주세요.')
                            }
                            if(questionString.length === 0) {
                                return alert('질문 내용을 입력해 주세요.')
                            }

                            const questionSaveRequest: boardQuestionSaveRequest = {
                                title: questionString,
                                isNotice: 0,
                                isPublic: 1,
                                keyword: selectCategory,
                            }

                            if(confirm('질문은 한 번 등록하시면 삭제 및 수정할 수 없으며 비공개 처리만 가능합니다')){
                                saveQuestionInstance.mutate(questionSaveRequest)
                            }
                        }}
                    >
                        <Text style={styles.bottomSubmitButtonText}>작성완료</Text>
                    </Pressable>
                </View>

            </View>


        </View>
    );
}

const styles = StyleSheet.create({
    horizontalWrapper: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    arrowIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(6),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500'
    },
    topWrapper: {
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(47),
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#D9D9D9'
    },

    categoryWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(66),
        justifyContent: 'center'
    },
    categoryPicker: {
        color: '#232',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500',
        width: PixelRatio.getPixelSizeForLayoutSize(180),
    },

    questionInputText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500',
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(187),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        padding: PixelRatio.getPixelSizeForLayoutSize(10)
    },
    inputTextLengthWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        bottom: PixelRatio.getPixelSizeForLayoutSize(18),
        right: PixelRatio.getPixelSizeForLayoutSize(18),
    },
    inputTextLength: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '700',
    },
    inputTextMaxLength: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#AAAAAA',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500',
    },
    bottomWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: PixelRatio.getPixelSizeForLayoutSize(83),
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(15),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(14)
    },
    bottomCancelButtonWrapper:{
        backgroundColor: 'white',
        flex: 1,
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(18),
        alignItems: 'center',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(8),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#DDD'
    },
    bottomCancelButtonText:{
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
    },
    bottomSubmitButtonWrapper:{
        backgroundColor: '#2C4198',
        flex: 1,
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(18),
        alignItems: 'center',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(8)
    },
    bottomSubmitButtonText:{
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#FFF',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
    }
})

export default MyQuestionWriteQuestion
