export enum MenuName{
    Main = 0,
    AllQuestion = 10,
    AllQuestionAnswer = 11,
    Community = 20,
    CommunityDetail,
    CommunityWrite = 22,
    MyQuestionHeart = 30,
    MyQuestionAnswer = 31,
    MyQuestionMakeQuestion = 32,
    MyQuestionCreateBook = 33,

}
