import React, {useEffect, useState} from "react";
import {Image, PixelRatio, ScrollView, StyleSheet, Text, Pressable, View} from "react-native";
import x_icon from '../assets/images/x_icon.svg'
import {publicWrapper} from '../components/public/PublicStyle'
import {isDesktop} from "react-device-detect";
import {useMutation} from "react-query";
import client from "../api/client.ts";
import {inquire} from '../api/urls'
import {inquireDetailResponse, responseWrapper} from "../api/entity";
import {useIsFocused} from "@react-navigation/native";
import CheckLogin from "../components/public/checkLogin.ts";
import getDate from '../components/public/getDate'
import PublicScripts from "../components/public/PublicScripts";
import AsyncStorage from "@react-native-async-storage/async-storage";

function MenuInquireDetail({navigation, route}) {

    const uid = route.params.uid
    if(uid === undefined) {
        navigation.pop()
    }

    const boardInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<inquireDetailResponse>>(inquire.detail(uid))
    })
    const [boardData, setBoardData] = useState()
    const [title, setTitle] = useState('')
    const [questionContent, setQuestionContent] = useState('')
    const [questionRegDate, setQuestionRegDate] = useState(0)
    const [questionNickname, setQuestionNickname] = useState('')
    const [answerContent, setAnswerContent] = useState('')
    const [answerRegDate, setAnswerRegDate] = useState()
    const [answerNickname, setAnswerNickName] = useState('')
    const [isAnswered, setIsAnswered] = useState(false)


    const isFocused = useIsFocused()
    useEffect(() => {
        if(isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }
            })
            boardInstance.mutate(null,{
                onSuccess: ({data})=> {
                    if(data.status) {
                        const _data= data.data
                        setBoardData(_data)
                        setTitle(_data.title)
                        setQuestionContent(_data.questionContent)
                        setQuestionRegDate(_data.questionRegDate)
                        setQuestionNickname(_data.questionNickname)
                        setAnswerContent(_data.answerContent)
                        setAnswerRegDate(_data.answerRegDate)
                        setAnswerNickName(_data.answerNickname)
                        setIsAnswered(_data.isAnswered)
                    }
                }
            })
        }
    }, [useIsFocused()]);

    return (
        <View style={[styles.wrapper, isDesktop&&publicWrapper.deskTopWrapper]}>

            <PublicScripts/>

            <View style={styles.horizontalWrapper}>
                <View style={{flex: 1}}/>
                <Pressable onPress={()=>navigation.pop()}>
                    <Image style={styles.x_icon} source={x_icon} />
                </Pressable>
            </View>
            <View style={styles.questionWrapper}>
                <View style={{flex: 1}}>
                    <Text style={styles.titleText} numberOfLines={4}>{title}</Text>
                    <View style={{height: PixelRatio.getPixelSizeForLayoutSize(14)}}/>
                    <Text style={styles.questionSubText}>{getDate(questionRegDate)}</Text>
                </View>
                <View>
                    <View style={{flex: 1}}/>
                    <Text style={[styles.isAnswered, isAnswered!==0&&styles.isAnsweredTrue]}>
                        {isAnswered===0?'답변대기':'답변완료'}
                    </Text>
                </View>

            </View>

            <Text style={styles.questionContentText}>{questionContent}</Text>
            {isAnswered!==0&&(
                <View style={styles.answerWrapper}>
                    <View style={styles.horizontalWrapper}>
                        <Text style={styles.answerTitleText}>답변완료</Text>
                        <View style={styles.horizontalSeparator}/>
                        <Text style={styles.answerSubText}>{getDate(answerRegDate)}</Text>
                    </View>
                    <Text style={styles.answerContentText}>
                        {answerContent}
                    </Text>


                </View>
            )}

        </View>
    )
}


const styles = StyleSheet.create({
    wrapper: {
      padding: PixelRatio.getPixelSizeForLayoutSize(16)
    },
    horizontalWrapper: {
      flexDirection: 'row',
        alignItems: 'center'
    },
    horizontalSeparator: {
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        backgroundColor: '#DDD',
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(12)
    },
    x_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
    },
    questionWrapper: {
        flexDirection: 'row',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(24),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(20),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#eee'
    },
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(19),
        fontWeight: '700',
    },
    questionSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },
    isAnswered: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
        backgroundColor: '#77777733',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(10),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(5),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(2)
    },
    isAnsweredTrue: {
        color: '#2C4198',
        backgroundColor: '#2C419833',
    },
    questionContentText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '300',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(18),
    },
    answerWrapper: {
        gap: 10,
        height: 'auto',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(20),
        backgroundColor:'#F4F5F7',
        padding: PixelRatio.getPixelSizeForLayoutSize(20),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
    },
    answerTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
    },
    answerSubText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },
    answerContentText: {
        fontFamily: 'KoPub_Dotum_Pro',
        height: 'auto',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '300',
    },
})

export default MenuInquireDetail
