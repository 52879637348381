import React, {useEffect, useState} from "react";
import {Image, PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import getDate from '../public/getDate'
import favorite_stroke from '../../assets/images/good_stroke.png'
import favorite_fill from '../../assets/images/good_fill.png'
import {useIsFocused} from "@react-navigation/native";
import FavoriteInstance from "../../api/FavoriteConnection.ts";
import {useMutation} from "react-query";
import client from "../../api/client.ts";
import {favorite,board} from '../../api/urls'
import {favoriteRequest, favoriteResponse, reportResponse, responseWrapper} from "../../api/entity";
import userContext from "../../context/userContext.ts";

function ReplyListItem({item}){

    const [isFavorite,setIstFavorite] = useState(item.favorite)
    const [isDelete, setIsDelete] = useState(false)

    const reportReplyInstance = useMutation({
        mutationFn:(data: favoriteRequest)=> client.post<responseWrapper<any>>(board.post.report(board.categoryType.reply),data),
        onSuccess: ({data})=>{
            if (data.status) {
                alert(`신고되었습니다`)
            }else {
                alert('이미 신고한 글입니다')
            }
        }
    })

    const deleteReplyInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<any>>(board.post.deleteReply(item.uid)),
        onSuccess: ({data})=> {
            if (data.status) {
                setIsDelete(true)
                alert('삭제되었습니다.')
            }
        }
    })

    const privateReplyInstance = useMutation({
        mutationFn: (data: favoriteRequest)=> client.post<responseWrapper<any>>(board.post.makePrivate)
    })

    const user = userContext.getUser

    const isFocused = useIsFocused()
    useEffect(()=>{
        if(isFocused) {
        }
    },[useIsFocused()])


    const favoriteInstance = FavoriteInstance(favorite.categoryType.reply,isFavorite,item.uid)
    const favoriteAction = ()=> {
        favoriteInstance.mutate(null,{
            onSuccess: ({data})=> {
                if(data.status) {
                    setIstFavorite(!isFavorite)
                }else if (data!==undefined) {
                    setIstFavorite(!isFavorite)
                }
            }
        })
    }

    if (isDelete===true) {
        return (<></>);
    }
    return(
        <View style={styles.replyItemWrapper}>
            <Text style={styles.replyNameText}>{item.nickname}</Text>
            <Text style={styles.replyContentsText}>{item.content}</Text>
            <View style={styles.horizontalWrapper}>
                <Text style={styles.replySubText}>{getDate(item.regDate)}</Text>
                <View style={styles.separatorHorizontal}/>
                <Pressable style={styles.horizontalWrapper} onPress={favoriteAction}>
                    <Image style={styles.icon} source={isFavorite?favorite_fill:favorite_stroke}/>
                    <Text style={styles.replySubText}>추천 {item.favoriteCount}</Text>
                </Pressable>

                <View style={styles.separatorHorizontal}/>
                <Pressable
                    onPress={()=>{
                        const request: favoriteRequest = {
                            uid: item.uid
                        }
                        if (item.userUid===user.uid&& isDelete === false) {
                            deleteReplyInstance.mutate()
                        }else {
                            reportReplyInstance.mutate(request)
                        }

                    }}
                >
                    <Text style={styles.replySubText}>{item.userUid===user.uid?'삭제하기':'신고하기'}</Text>
                </Pressable>

            </View>
        </View>
    )

}

const styles = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    separatorVertical: {
        backgroundColor: '#E5E5E5',
        height: PixelRatio.getPixelSizeForLayoutSize(1),
    },
    separatorHorizontal: {
        backgroundColor: '#E5E5E5',
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8)
    },

    replyItemWrapper: {
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(18),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#E5E5E5'
    },
    replyNameText: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '700'
    },
    replyContentsText: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500',
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(8),
    },
    replySubText: {
        color: '#888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500'
    },
    icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(14),
        height: PixelRatio.getPixelSizeForLayoutSize(14),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(4),
    },
})
export default ReplyListItem
