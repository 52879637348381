import {StyleSheet, PixelRatio} from "react-native-web";
import {isDesktop} from 'react-device-detect'


const TextStyle =  StyleSheet.create({
    blueColor:{
        color: '#2C4198'
    },
    middleText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
    },
    titleText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '600',
    },
    smallText : {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
    },
    separator: {
        height: PixelRatio.getPixelSizeForLayoutSize(2),
        margin: PixelRatio.getPixelSizeForLayoutSize(4),
        backgroundColor: 'gray',
    },
});

const ListStyle = StyleSheet.create({
    itemWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(44),
    },
});

const publicWrapper = StyleSheet.create({
    backgrounded: {
        backgroundColor: '#F4F5F7',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        width: '100%',
        height: '100%',
    },
    deskTopWrapper : {
        width : isDesktop&&PixelRatio.getPixelSizeForLayoutSize(620),
        height: isDesktop&&PixelRatio.getPixelSizeForLayoutSize(800),
        alignSelf: 'center',
    }
})


export {TextStyle, ListStyle, publicWrapper}
