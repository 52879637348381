import React, {useState} from "react";
import {PixelRatio, StyleSheet, Text, Pressable, View} from "react-native";
import {MenuName} from "../public/MenuName.ts";

function TopMenu({navigation, route}){

    const [selected, setSelected] = useState(route.params.menu)

    function isSelect(menuNumber){
        return selected === menuNumber
    }

    return (
        <View style={styles.wrapper}>
            <Pressable
                style={[styles.buttonWrapper, isSelect(MenuName.MyQuestionHeart) && styles.selectWrapper]}
                onPress={()=> {
                    if(!isSelect(MenuName.MyQuestionHeart)) {
                        navigation.push('MyQuestion')
                    }
                }}
            >
                <Text style={[styles.buttonText, isSelect(MenuName.MyQuestionHeart) && styles.selectColor]}>하트질문</Text>
            </Pressable>
            <Pressable
                style={[styles.buttonWrapper, isSelect(MenuName.MyQuestionAnswer) && styles.selectWrapper]}
                onPress={()=> {
                    if(!isSelect(MenuName.MyQuestionAnswer)) {
                        navigation.push('MyQuestionAnswer')
                    }
                }}
            >
                <Text style={[styles.buttonText, isSelect(MenuName.MyQuestionAnswer) && styles.selectColor]}>답한질문</Text>
            </Pressable>
            <Pressable
                style={[styles.buttonWrapper, isSelect(MenuName.MyQuestionMakeQuestion) && styles.selectWrapper]}
                onPress={()=> {
                    if(!isSelect(MenuName.MyQuestionMakeQuestion)) {
                        navigation.push('MyQuestionMakeQuestion')
                    }
                }}
            >
                <Text style={[styles.buttonText, isSelect(MenuName.MyQuestionMakeQuestion) && styles.selectColor]}>만든질문</Text>
            </Pressable>
            <Pressable
                style={[styles.buttonWrapper, isSelect(MenuName.MyQuestionCreateBook) && styles.selectWrapper]}
                onPress={()=> {
                    if(!isSelect(MenuName.MyQuestionCreateBook)) {
                        navigation.push('MyQuestionCreateBook')
                    }
                }}
            >
                <Text style={[styles.buttonText, isSelect(MenuName.MyQuestionCreateBook) && styles.selectColor]}>책만들기</Text>
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(47),
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(30),
        alignItems: 'center',
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#D9D9D9'
    },
    buttonWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(47),
        justifyContent: 'center',
    },
    selectWrapper: {
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#2C4198'
    },
    buttonText: {
        fontFamily: 'SCDream5',
        color:'#777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '300',
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
        marginTop: PixelRatio.getPixelSizeForLayoutSize(15),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(14),
    },
    selectColor: {
        color: '#2C4198',
        fontWeight: '500'
    }
})

export default TopMenu
