import React, {useEffect, useRef, useState} from 'react';
import {Text, View, FlatList, PixelRatio, StyleSheet, Pressable, Image, ScrollView} from "react-native";
import {publicWrapper} from '../components/public/PublicStyle'
import PublicTopMenu from "../components/public/PublicTopMenu.tsx";
import {
    boardReadRequest,
    boardResponse,
    categoryResponse,
    favoriteResponse,
    favoriteRequest,
    responseWrapper, totalCountRequest
} from "../api/entity";
import QuestionBoardItem from "../components/allquestion/QuestionBoardItem.tsx";
import {useMutation} from "react-query";
import client from "../api/client.ts";
import {board, favorite} from '../api/urls'
import checkIcon from '../assets/images/check.png'
import ellipse from '../assets/images/ellipse.png'
import checkLogin from "../components/public/checkLogin.ts";
import {useIsFocused} from "@react-navigation/native";
import {isDesktop} from "react-device-detect";
import userContext from "../context/userContext.ts";
import AsyncStorage from "@react-native-async-storage/async-storage";


function AllQuestions({navigation, route}) {

    const boardRef = useRef()

    const sortData = route.params.sorting===undefined?'uid':route.params.sorting
    const [keyword,setKeyword] = useState(route.params.keyword)
    const [sorting, setSorting] = useState(sortData)
    const [lastUid,setLastUid] = useState(0)
    const [totalCount, setTotalCount] = useState(0)

    const totalCountInstance = useMutation({
        mutationFn: (req: totalCountRequest)=> client.post<responseWrapper<number>>(board.post.totalCount, req),
        onSuccess: ({data})=> {
            if(data.status) {
                setTotalCount(data.data)
            }
        }
    })

    const boardRequest: boardReadRequest = {sorting: sorting, keyword: keyword}

    const keywordsInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<categoryResponse[]>>(board.post.getCategoryCode)
    })

    const boardInstance = useMutation({
        mutationFn: (data: boardReadRequest)=> client.post<responseWrapper<boardResponse[]>>(board.get.read(board.categoryType.question),data)

    })

    const isFocused = useIsFocused()
    useEffect(()=>{
        if (isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }else {
                    keywordsInstance.mutate(null,{
                        onSuccess:({data})=> {
                            if (data.status) {
                                const _data = data.data
                                setKeywords([{description: '전체보기'}, ..._data])
                            } else {

                            }
                        }
                    })
                    totalCountInstance.mutate({keyword: undefined})
                    setSelectedKeyword(keyword)
                }
            })

        }

    },[useIsFocused()])

    useEffect(()=>{
        boardRef.current.scrollToOffset({offset:0})
        boardInstance.mutate(boardRequest,{
            onSuccess: ({data})=> {
                if(data.status) {
                    const _data = data.data
                    setBoardData(_data)
                    const length = _data.length > 0? _data.length-1 : 0
                    if (length > 0) {
                        setLastUid(_data[length].uid)
                    }
                }

            }
        })
    },[sorting, keyword])

    useEffect(() => {
        const req: totalCountRequest = {
            keyword: keyword
        }
        totalCountInstance.mutate(req)
    }, [keyword]);

    function isSelectKeyword(code){
        return selectedKeyword === code
    }
    function isSelectSort(item){
        return sorting === item
    }

    const [selectedKeyword,setSelectedKeyword] = useState(undefined)

    const [boardData, setBoardData] = useState([])
    const [keywords, setKeywords] = useState<categoryResponse[]>([])


    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <View style={styles.keywordWrapper}>
                <FlatList
                    horizontal={true}
                    data={keywords}
                    renderItem={({item})=>{
                        return (
                            <Pressable
                                style={[styles.keywordItemWrapper,selectedKeyword === item.code&&styles.selectedKeywordItemWrapper]}
                                onPress={()=> {
                                    setSelectedKeyword(item.code)
                                    setKeyword(item.code)
                                }}
                                activeOpacity={1}
                            >
                                <Text
                                    style={[styles.keywordItem,selectedKeyword === item.code&&styles.selectedColor]}
                                >
                                    {item.description}
                                </Text>
                            </Pressable>
                        )
                    }}
                />
            </View>

            <View style={publicWrapper.backgrounded}>
                {/*키워드 정렬 리스트*/}

                {/*정렬 부분*/}
                <View style={styles.sortWrapper}>
                    <Text style={styles.sortTitleText}>총 질문 수
                        <Text style={{color: '#2C4198', fontWeight: '700'}}> {totalCount}</Text>
                        개
                    </Text>
                    <Pressable
                        style={{flexDirection: 'row'}}
                        onPress={()=>{
                            setSorting('uid')
                        }}
                    >
                        <Image
                            style={isSelectSort('uid')?styles.selectSortIcon:styles.sortIcon}
                            source={isSelectSort('uid')? checkIcon:ellipse}
                        />
                        <Text style={[styles.sortButtonText,isSelectSort('uid')&&styles.selectedColor]}>
                            최신순
                        </Text>
                    </Pressable>

                    <Pressable
                        style={{flexDirection: 'row'}}
                        onPress={()=>{
                            setSorting('favorite')
                        }}
                    >
                        <Image
                            style={isSelectSort('favorite')?styles.selectSortIcon:styles.sortIcon}
                            source={isSelectSort('favorite')? checkIcon:ellipse}
                        />
                        <Text style={[styles.sortButtonText,isSelectSort('favorite')&&styles.selectedColor]}>
                            하트순
                        </Text>
                    </Pressable>
                    <Pressable
                        style={{flexDirection: 'row'}}
                        onPress={()=>{
                            setSorting('answer')
                        }}
                    >
                        <Image
                            style={isSelectSort('answer')?styles.selectSortIcon:styles.sortIcon}
                            source={isSelectSort('answer')? checkIcon:ellipse}
                        />
                        <Text style={[styles.sortButtonText,isSelectSort('answer')&&styles.selectedColor]}>
                            답글많은순
                        </Text>
                    </Pressable>
                </View>
                    {/*게시판 본문*/}
                <FlatList
                    style={{height:window.innerHeight - PixelRatio.getPixelSizeForLayoutSize(300)}}
                    ref={boardRef}
                    data={boardData}
                    renderItem={({item}):boardResponse=> {
                        return <QuestionBoardItem item={item} navigation={navigation}/>
                    }}
                    onEndReached={()=> {
                        const request: boardReadRequest =  {
                            sorting: sorting,
                            keyword: keyword,
                            lastUid: lastUid
                        }
                            boardInstance.mutate(request,{
                                onSuccess: ({data})=> {
                                    if(data.status) {
                                        const _data = data.data
                                        const length = _data.length > 0? _data.length-1 : 0
                                        if (length > 0) {
                                            setBoardData([...boardData,..._data])
                                            setLastUid(_data[length].uid)
                                        }
                                    }
                                },
                                onError: ({error})=>alert(error)

                            })
                    }}
                />



            </View>
        </View>

    );
}

const styles = StyleSheet.create({
    keywordWrapper: {
        backgroundColor: 'white',
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(47),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderBottomColor: '#E5E5E5',
    },
    selectedKeywordItemWrapper: {
        borderBottomColor: '#2C4198',
        borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(2),
    },
    keywordItemWrapper: {
        justifyContent: 'center',
        alignItems: 'center',
        height :PixelRatio.getPixelSizeForLayoutSize(47),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(15),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(14),
    },
    keywordItem: {
        fontFamily: 'SCDream5',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '300',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(18),
        color: '#777777',
        textAlign: 'center',
        letterSpacing: -1,
    },
    selectedColor: {
        color: '#2C4198',
        fontWeight: '500',
    },
    sortWrapper: {
        flexDirection: 'row',
        height: PixelRatio.getPixelSizeForLayoutSize(44),
        alignItems: 'center',
    },
    sortTitleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        flex: 1,
    },
    sortButtonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'gray',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    sortIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(4),
        height: PixelRatio.getPixelSizeForLayoutSize(4),
        alignSelf: 'center',
    },
    selectSortIcon: {
        width: PixelRatio.getPixelSizeForLayoutSize(12),
        height: PixelRatio.getPixelSizeForLayoutSize(12),
        alignSelf: 'center',
    },

})


export default AllQuestions;
