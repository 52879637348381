import React from "react";
import {Image, PixelRatio, StyleSheet, Text, View} from "react-native";
import {Pressable} from "react-native-web";
import {categoryResponse} from '../../api/entity.ts'
import arrow_right from '../../assets/images/arrow_right.png'

function KeywordListItem({navigation, data, isLogin}){
    return (
        <Pressable
            style={[styles.wrapper,{backgroundColor: `#${data.maincolor}`}]}
            onPress={()=>{
                if (isLogin) {
                    navigation.push('AllQuestions',{keyword: data.code})
                }else {
                    alert(`로그인 페이지로 이동합니다`)
                    navigation.push('Login')
                }

            }}
        >
            <View>
                <Text style={styles.titleText}>{data.description}</Text>
                <Text style={styles.detailText} numberOfLines={3}>{data.description}에 대해 말해주세요.</Text>
            </View>
            <View activeOpacity={1} style={[styles.buttonWrapper,{backgroundColor: `#${data.subcolor}`}]}>
                <Text style={styles.bottomText}>{'바로가기'}</Text>
                <Image style={styles.arrow_right} source={arrow_right}/>
            </View>

        </Pressable>
    );
}

const styles= StyleSheet.create({
    wrapper: {
        width: PixelRatio.getPixelSizeForLayoutSize(135),
        height: PixelRatio.getPixelSizeForLayoutSize(168),
        backgroundColor: '#159F76',
        margin: PixelRatio.getPixelSizeForLayoutSize(15),
        padding: PixelRatio.getPixelSizeForLayoutSize(18),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(7),
        justifyContent: 'space-between'
    },
    textWrapper: {
        width: PixelRatio.getPixelSizeForLayoutSize(103),
        height: PixelRatio.getPixelSizeForLayoutSize(73)
    },
    titleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(11),
    },
    detailText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: 600,
        flex: 1,
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(20)
    },
    buttonWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#11111111',
        width: PixelRatio.getPixelSizeForLayoutSize(99),
        height: PixelRatio.getPixelSizeForLayoutSize(33),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(50),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(15),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(10)
    },
    bottomText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: 'white',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
        textAlign: 'center',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(33),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(6),
    },
    arrow_right: {
        width: PixelRatio.getPixelSizeForLayoutSize(8),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
    },
});

export default KeywordListItem;
