import React, {useEffect, useState} from "react";
import heart_stroke from "../../assets/images/heart_stroke.png";
import heart_fill from "../../assets/images/heart_fill.png";
import reply_icon from "../../assets/images/reply.png";
import view_icon from "../../assets/images/eye.png";
import {StyleSheet, Pressable, View, Text, Image, PixelRatio} from "react-native";
import getDate from "./getDate";
import {boardResponse, responseWrapper} from '../../api/entity.ts'
import {useMutation} from "react-query";
import client from "../../api/client.ts";
import {board} from "../../api/urls";
import FavoriteInstance from "../../api/FavoriteConnection.ts";
import {useIsFocused} from "@react-navigation/native";

function QuestionView({navigation, route, questionUid}){
    const [isFavorite,setIsFavorite] = useState(false)
    const [favoriteCount, setFavoriteCount] = useState(0)
    const [questionData,setQuestionData] = useState<boardResponse>({title:''})

    const questionInstance = useMutation({
        mutationFn:()=> client.post<responseWrapper<boardResponse>>(board.get.detail(board.categoryType.question, questionUid)),
        onSuccess: ({data})=>{
            if (data.status) {
                const _data = data.data
                setQuestionData(_data)
                setIsFavorite(_data.favorite)
                setFavoriteCount(_data.favoriteCount)
            }else {
                if(data.errorcode === 11) {
                    alert('블라인드 처리된 글입니다')
                    navigation.pop()
                }
            }
        }
    })

    const favoriteInstance = FavoriteInstance(board.categoryType.question,isFavorite,questionUid)

    const favoriteAction = ()=> {
        favoriteInstance.mutate(null,{
            onSuccess: ({data})=>{
                if (data.status) {
                    setIsFavorite(!isFavorite)
                    setFavoriteCount(favoriteCount+1)
                } else {
                    setIsFavorite(!isFavorite)
                    setFavoriteCount(favoriteCount-1)
                }

            }
        })
    }

    const isFocused = useIsFocused()

    useEffect(()=> {
        if (isFocused) {
            questionInstance.mutate()
        }
    },[useIsFocused(),questionUid])

    return (
        <View style={styles.questionWrapper}>
            <Text style={styles.questionTitle}>{questionData.title}</Text>
            <View style={styles.questionSubWrapper}>
                <Text style={[styles.questionMiddleText, styles.blueText]}>{questionData.keywordString}</Text>
                <View style={styles.separatorHorizontal}/>
                <Text style={styles.questionMiddleText}> {questionData.nickname}</Text>
                <View style={styles.separatorHorizontal}/>
                <Text style={styles.questionMiddleText}>{getDate(questionData.registerDate)}</Text>
            </View>
            <View style={styles.separatorVertical}/>
            <View style={styles.horizontalWrapper}>
                <Pressable
                    style={styles.horizontalWrapper}
                    onPress={favoriteAction}
                >
                    <Image style={styles.icon} source={isFavorite===true?heart_fill:heart_stroke}/>
                    <Text style={styles.questionSmallText}>하트 <Text style={styles.boldText}>{favoriteCount}</Text></Text>
                </Pressable>
                <View style={styles.separatorHorizontal}/>
                <Image style={styles.icon} source={reply_icon}/>
                <Text style={styles.questionSmallText}>답글 {questionData.answerCount}</Text>
                <View style={styles.separatorHorizontal}/>
                <Image style={styles.icon} source={view_icon}/>
                <Text style={styles.questionSmallText}>조회 <Text style={styles.boldText}>{questionData.hits}</Text></Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    questionWrapper: {
        backgroundColor: 'white',
        minHeight: PixelRatio.getPixelSizeForLayoutSize(127),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(18),
        marginTop: PixelRatio.getPixelSizeForLayoutSize(6),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(12),
        gap: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    questionTitle: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(19),
        fontWeight: '700',
    },
    questionSubWrapper:{
        flexDirection: 'row',
        alignItems: 'center',
        height: PixelRatio.getPixelSizeForLayoutSize(14),
    },
    questionMiddleText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },
    blueText: {
        color: '#2C4198'
    },
    boldText: {
        fontWeight: '700'
    },
    questionSmallText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#888888',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(13),
        fontWeight: '500',
    },
    separatorHorizontal: {
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        backgroundColor:  '#DDDDDD',
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8),
    },
    separatorVertical: {
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        backgroundColor:  '#DDDDDD',
    },
    icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(14),
        height: PixelRatio.getPixelSizeForLayoutSize(14),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(4),
    },
})

export default QuestionView;
