import {userEntity, userResponse} from "../api/entity";
import {action, computed, makeAutoObservable, makeObservable, observable} from "mobx";


 class UserContext {

     user:userResponse
     refreshToken: string
    constructor() {
        makeAutoObservable(this)
    }

    setUser(data: userResponse) {
        this.user = data
    }

    get getUser() {
        return this.user
    }

    setRefreshToken(token: string) {
        this.refreshToken = token
    }

    get getRefreshToken() {
         return this.refreshToken
    }


}

const userContext = new UserContext()

export default userContext
