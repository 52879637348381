import React, {useEffect, useState} from "react";
import {Text, View, StyleSheet, Image, Pressable} from "react-native";
import {PixelRatio} from "react-native-web";
import bookIcon from '../../assets/images/open book_.png'
import userContext from "../../context/userContext.ts";
import {useIsFocused} from "@react-navigation/native";

function BookSalonListItem({navigation, data, isLogin}) {

    return (
        <Pressable
            style={[styles.wrapper,{backgroundColor: `#${data.maincolor}`}]}
            onPress={()=>{
                const found = userContext.getUser.bookSalon.find((v)=>v===data.code)
                if (isLogin) {
                    if (found !== undefined) {
                        navigation.push('Community',{bookSalon: data.code})
                    }else {
                        alert('북살롱에 가입되어 있지 않습니다')
                    }

                }else {
                    alert(`로그인 페이지로 이동합니다`)
                    navigation.push('Login')
                }

            }}
            activeOpacity={1}
        >
            <View style={[styles.imageWrapper,{backgroundColor: `#${data.subcolor}`}]}>
                <Image style={styles.image} source={bookIcon}/>
            </View>
            <Text style={styles.titleStyle}>
                {data.description}<Text style={styles.innerTitleStyle}>북살롱</Text>
            </Text>
        </Pressable>
    );
}

const styles = StyleSheet.create({

    wrapper: {
        backgroundColor: '#EAE6FD',
        width: PixelRatio.getPixelSizeForLayoutSize(130),
        height: PixelRatio.getPixelSizeForLayoutSize(139),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(16),
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(15),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageWrapper: {
        width: PixelRatio.getPixelSizeForLayoutSize(56),
        height: PixelRatio.getPixelSizeForLayoutSize(56),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(28),
        backgroundColor: '#22222222',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    image: {
        width: PixelRatio.getPixelSizeForLayoutSize(33.08),
        height: PixelRatio.getPixelSizeForLayoutSize(24.81),
    },
    titleStyle: {
        fontFamily: 'SCDream5',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '600',
        textAlign: 'center',
        letterSpacing: PixelRatio.getPixelSizeForLayoutSize(2)
    },
    innerTitleStyle: {
        fontWeight: '300'
    }

});

export default BookSalonListItem;
