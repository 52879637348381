import React, {useEffect} from "react";
import {Image, PixelRatio, ScrollView, StyleSheet, Text, Pressable, View} from "react-native";
import x_icon from '../../assets/images/x_icon.svg'
import {publicWrapper} from '../public/PublicStyle'
import {action} from "mobx";
import {isDesktop} from "react-device-detect";
import {useIsFocused} from "@react-navigation/native";
function MenuWrapper({navigation,route,
                         contentView = (<Text>Content</Text>),
                         buttonItem={title: 'button',action:()=>{}},
                         titleView = (<Text>Title</Text>)
                     }) {

    const isFocused = useIsFocused()
    useEffect(() => {
        if (isFocused){

        }
    }, [useIsFocused()]);

    return(
        <View style={[styles.wrapper, isDesktop&&publicWrapper.deskTopWrapper]}>
            <View style={styles.horizontalWrapper}>
                <View style={{flex: 1}}/>
                <Pressable onPress={()=>navigation.pop()}>
                    <Image source={x_icon} style={styles.x_icon}/>
                </Pressable>
            </View>
            <View style={styles.titleWrapper}>
                {titleView}
            </View>
            <View style={styles.verticalSeparator}/>
            <View style={styles.contentWrapper}>
                {(contentView)}
            </View>
            <Pressable
                style={styles.bottomButtonWrapper}
                onPress={buttonItem.action}
            >
                <Text style={styles.bottomButtonText}>{buttonItem.title}</Text>
            </Pressable>


        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        height: '100%',
        padding: PixelRatio.getPixelSizeForLayoutSize(16),
    },
    verticalSeparator: {
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        backgroundColor: '#EEEEEE',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(20)

    },
    horizontalWrapper: {
        flexDirection: 'row'
    },
    x_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(30),
        height: PixelRatio.getPixelSizeForLayoutSize(30),
    },
    titleWrapper: {
        marginTop: PixelRatio.getPixelSizeForLayoutSize(24)
    },
    contentWrapper: {
        flex: 1,
    },
    bottomButtonWrapper: {
        backgroundColor: '#2C4198',
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(8),
        alignItems: 'center',
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(17),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(8)
    },
    bottomButtonText :{
        color: 'white',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
    }
})

export default MenuWrapper
