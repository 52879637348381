import React, {useEffect, useState} from "react";
import PublicTopMenu from '../components/public/PublicTopMenu.tsx'
import {Image, PixelRatio, StyleSheet, Text, TextInput, Pressable, View} from 'react-native'
import {publicWrapper} from '../components/public/PublicStyle'
import {useMutation} from "react-query";
import client from "../api/client.ts";
import QuestionView from '../components/public/QuestionView.tsx'
import ReportMenu from '../components/public/ReportMenu.tsx'
import {boardAnswerSaveRequest, boardResponse, favoriteRequest, responseWrapper} from '../api/entity.ts'
import {board,favorite} from '../api/urls'
import getDate from '../components/public/getDate'
import arrow_left from "../assets/images/arrow_left.png";
import reportIcon from "../assets/images/report.png";
import checkLogin from "../components/public/checkLogin.ts";
import {useIsFocused} from "@react-navigation/native";
import userContext from "../context/userContext.ts";
import {isDesktop} from "react-device-detect";
import AsyncStorage from "@react-native-async-storage/async-storage";

function WriteAnswer({navigation, route}){

    const [answerText,setAnswerText] = useState('')

    const questionData: boardResponse = route.params.question

    const saveInstance = useMutation({
        mutationFn: (data: boardAnswerSaveRequest)=>client.post<responseWrapper<boardResponse>>(board.post.save(board.categoryType.answer),data)
    })

    const [openType,setOpenType] = useState(1)
    const [bookSalon, setBookSalon] = useState([...userContext.getUser.bookSalon])

    const [keyword,setKeyword] = useState('abcd')
    const questionKeyword = questionData.keywordString === undefined  ? '주제없음': questionData.keywordString

    const [favoriteList,setFavoriteList] = useState([])




    const isFocused = useIsFocused()
    useEffect(()=>{
        if (isFocused) {
            AsyncStorage.getItem('token').then(v=>{
                if (v===null) {
                    alert('로그인 페이지로 이동합니다')
                    navigation.push('Login')
                }
            })
            setKeyword(questionData.keyword)

        }
    },[useIsFocused()])

    const saveToAnswer = ()=> {

        if(answerText.length === 0) {
            return alert('답글 내용을 작성해주세요')
        }

        const answerSaveRequest: boardAnswerSaveRequest = {
            title: questionData.title,
            content: answerText,
            questionUid: questionData.uid,
            isPublic: openType,
            bookSalon: bookSalon
        }
        saveInstance.mutate(answerSaveRequest,{
            onSuccess:({data})=>{
                if(data.status) {
                    alert(`저장이 완료 되었어요.`)
                    navigation.push('Answer',{question: questionData})
                }

            }
        })
    }

    if (questionData === undefined) {
        return (
            <>
                <Text>잘못된 요청입니다.</Text>
            </>
        );
    }

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            <View style={[publicWrapper.backgrounded,{padding:0}]}>
                <ReportMenu navigation={navigation} route={route} category={'question'} articleData={questionData}/>
                <QuestionView navigation={navigation} route={route} questionUid={questionData.uid}/>
                <View style={styles.answerMenuWrapper}>
                    <Text style={styles.answerMenuTitleText}>공개설정</Text>
                    <Pressable
                        style={[styles.answerMenuButtonWrapper, openType===1&&styles.selectColor]}
                        onPress={()=>{
                            setOpenType(1)

                        }}
                    >
                        <Text style={[styles.answerMenuButtonText, openType===1&&styles.selectColor]}>전체공개</Text>
                    </Pressable>
                    <Pressable
                        style={[styles.answerMenuButtonWrapper, openType===2&&styles.selectColor]}
                        onPress={()=>{
                            if (userContext.getUser.bookSalon.length===0) {
                                return alert('현재 북살롱에 가입 되있지 않습니다')
                            }
                            setOpenType(2)

                        }}
                    >
                        <Text style={[styles.answerMenuButtonText, openType===2&&styles.selectColor]}>내 북살롱 공개</Text>
                    </Pressable>
                    <Pressable
                    style={[styles.answerMenuButtonWrapper, openType===0&&styles.selectColor]}
                    onPress={()=>{
                        setOpenType(0)

                    }}
                >
                    <Text
                        style={[styles.answerMenuButtonText, openType===0&&styles.selectColor]}
                    >
                        비공개
                    </Text>
                </Pressable>
                </View>
                <View style={styles.writeAnswerWrapper}>
                    <TextInput
                        style={styles.inputAnswerText}
                        value={answerText}
                        onChangeText={setAnswerText}
                        multiline={true}
                        placeholder={'답글 내용을 입력하세요.\n' +
                            '부적절한 내용으로 답글을 작성하시면 다른 회원들에게 신고를 받아 블라인드 처리될 수 있으니 유의하시기 바랍니다. 신고로 블라인드 처리된 답글의 경우 \'책만들기\'에서 txt파일로 다운로드 받으실 수 없습니다.'}
                        placeholderTextColor={'#AAAAAA'}
                        maxLength={5000}
                    />
                    <View style={styles.answerLengthWrapper}>
                        <Text style={styles.answerLengthText}>{answerText.length}</Text>
                        <Text style={styles.answerMaxLengthText}> / 5000</Text>
                    </View>

                </View>
                <View style={styles.bottomWrapper}>
                    <Pressable
                        style={styles.bottomDismissButtonWrapper}
                        onPress={()=>{
                            navigation.pop()
                        }}
                    >
                        <Text style={styles.bottomDismissButtonText}>취소</Text>
                    </Pressable>
                    <View style={{width: PixelRatio.getPixelSizeForLayoutSize(14)}}/>
                    <Pressable
                        style={styles.bottomSubmitButtonWrapper}
                        onPress={saveToAnswer}
                    >
                        <Text style={styles.bottomSubmitButtonText}>답글 쓰기 완료</Text>
                    </Pressable>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    separatorVertical: {
        backgroundColor: '#DDDDDD',
        flex: 1,
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    separatorHorizontal: {
        backgroundColor: '#DDDDDD',
        width: PixelRatio.getPixelSizeForLayoutSize(1),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    horizontalWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    questionWrapper: {
        backgroundColor: 'white',
        height: PixelRatio.getPixelSizeForLayoutSize(127),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(15),
    },
    questionTitleText: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
    },
    answerMenuWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(82),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        flexDirection: 'row',
        alignItems: 'center',
    },
    answerMenuTitleText: {
        color: '#232323',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
        flex: 1,
    },
    answerMenuButtonWrapper: {
        borderColor: '#777777',
        height: PixelRatio.getPixelSizeForLayoutSize(28),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(11),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(7),
        borderWidth: 1,
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(50),
        marginLeft: PixelRatio.getPixelSizeForLayoutSize(5),
        alignItems: 'center',
        justifyContent: 'center',

    },
    answerMenuButtonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#777777',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
        textAlign: 'center',
        includeFontPadding: true
    },
    selectColor: {
        color: '#2C4198',
        borderColor: '#2C4198'
    },
    writeAnswerWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(362),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
    },
    inputAnswerText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500',
        backgroundColor: 'white',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        padding: PixelRatio.getPixelSizeForLayoutSize(20),
        flex: 1
    },

    answerLengthWrapper: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: PixelRatio.getPixelSizeForLayoutSize(20),
        right: PixelRatio.getPixelSizeForLayoutSize(20),

    },
    answerLengthText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#2C4198',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '700'
    },
    answerMaxLengthText: {
        fontFamily: 'KoPub_Dotum_Pro',
        color: '#AAAAAA',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(15),
        fontWeight: '500'
    },

    bottomWrapper: {
        flexDirection: 'row',
        height: PixelRatio.getPixelSizeForLayoutSize(83),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(16),
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(15),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(14),
        alignItems: 'center',
        justifyContent: 'center',
    },
    bottomDismissButtonWrapper: {
        alignItems: 'center',
        backgroundColor: 'white',
        flex: 1,
        borderColor: '#DDDDDD',
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(8),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(18),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(14),
    },
    bottomSubmitButtonWrapper: {
        alignItems: 'center',
        backgroundColor: '#2C4198',
        flex: 1,
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(8),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(18),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(14),
    },
    bottomDismissButtonText: {
        color: 'black',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
    },
    bottomSubmitButtonText: {
        color: 'white',
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '500',
    },
})

export default WriteAnswer
