import React,{useEffect,useState} from "react";
import PublicTopMenu from "../components/public/PublicTopMenu.tsx";
import {useMutation} from 'react-query';
import {
    boardResponse,
    categoryResponse,
    mainEntity, responseWrapper,
} from '../api/entity';
import {publicWrapper} from '../components/public/PublicStyle'
import KeywordListItem from '../components/main/KeywordListItem.jsx'
import BoardListItem from '../components/main/BoardListItem.tsx'
import BookSalonListItem from '../components/main/BookSalonListItem.jsx'
import client, {cleanToken} from '../api/client.ts'
import {view_api} from '../api/urls'
import {StyleSheet, Text, View, PixelRatio, FlatList, Image, Pressable, ScrollView} from "react-native";
import icon1 from '../assets/images/heart.png'
import icon2 from '../assets/images/fire.png'
import icon3 from '../assets/images/book.png'
import arrow_right from '../assets/images/arrow_right_gray.png'
import {useIsFocused} from "@react-navigation/native";
import {isDesktop} from "react-device-detect";
import fontA from '../assets/fonts/SCDream5.otf'
import fontB from '../assets/fonts/KoPub_Dotum_Pro.otf'
import UserContext from "../context/userContext.ts";
import AsyncStorage from "@react-native-async-storage/async-storage";

function Main({navigation, route}) {


    const [questionData,setQuestionData] = useState(undefined)
    const [top5Data, setTop5Data] = useState(undefined)
    const [bookSalonData,setBookSalonData] = useState(undefined)

    const mainInstance = useMutation({
        mutationFn:()=> client.post<responseWrapper<mainEntity>>(view_api.post.main),
        onSuccess:({data})=>{
            if(data.status) {
                const _data = data.data
                setQuestionData(_data.noticeQuestion)
                setTop5Data(_data.top5AnswerNumberQuestion)
                setBookSalonData(_data.bookSalonCode)
                setCategory(_data.category)
            }else {
                if(data.errorcode===98) {
                    mainInstance.mutate()
                }else if(data.errorcode===97) {
                    mainInstance.mutate()
                }
            }
        }
    })

    const isFocused = useIsFocused()
    useEffect(()=>{
        if(true){
            mainInstance.mutate()
        }
    },[])

    const [category,setCategory] = useState()

    const isLogin = client.defaults.headers.Authorization !== undefined

    return (
        <View style={isDesktop&&publicWrapper.deskTopWrapper}>
            <PublicTopMenu navigation={navigation} route={route}/>
            {/*주제 부분*/}
            <ScrollView style={publicWrapper.backgrounded}>
                <FlatList
                    horizontal={true}
                    data={category}
                    renderItem={({item}): categoryResponse=>
                        <KeywordListItem navigation={navigation} data={item} isLogin={isLogin} />
                    }
                />

                {/*추천 질문 부분*/}
                <View style={styles.subTitleWrapper}>
                    <View style={{flexDirection: 'row', alignItems: 'center', flex: 1}}>
                        <Image
                            style={styles.icon}
                            source={icon1}
                        />
                        <Text style={styles.titleText}>
                            추천질문
                        </Text>
                    </View>


                    <Pressable
                        style={styles.buttonWrapper}
                        onPress={()=>navigation.push('AllQuestions',{sorting:'favorite'})}
                    >
                        <Text style={[styles.buttonText,{color: 'gray'}]}>{'전체보기'}</Text>
                        <Image style={styles.arrow} source={arrow_right}/>
                    </Pressable>

                </View>
                <FlatList
                    style={styles.boardWrapper}
                    ItemSeparatorComponent={<View style={styles.separator}/>}
                    data={questionData}
                    renderItem={({item}): boardResponse=>
                        <BoardListItem data={item} navigation={navigation} isLogin={isLogin}/>
                    }
                />
                {/*답글많은 질문 부분*/}
                <View style={styles.subTitleWrapper}>
                    <View style={{flexDirection: 'row', alignItems: 'center', flex: 1}}>
                        <Image
                            style={styles.icon}
                            source={icon2}
                        />
                        <Text style={[styles.titleText]}>
                            답글많은질문
                        </Text>
                    </View>
                    <Pressable
                        style={styles.buttonWrapper}
                        onPress={()=>navigation.push('AllQuestions',{sorting:'answer'})}
                    >
                        <Text style={[styles.buttonText,{color: 'gray'}]}>{'전체보기'}</Text>
                        <Image style={styles.arrow} source={arrow_right}/>
                    </Pressable>

                </View>
                <FlatList
                    style={styles.boardWrapper}
                    ItemSeparatorComponent={<View style={styles.separator}/>}
                    data={top5Data}
                    renderItem={({item}): boardResponse=>
                        <BoardListItem data={item} navigation={navigation} isLogin={isLogin} />
                    }
                />

                {/*북살롱 부분*/}
                <View style={styles.subTitleWrapper}>
                    <View style={{flexDirection: 'row', alignItems: 'center', flex: 1}}>
                        <Image
                            style={styles.icon}
                            source={icon3}
                        />
                        <Text style={styles.titleText}>
                            북살롱 둘러보기
                        </Text>
                    </View>
                    <Pressable
                        style={styles.buttonWrapper}
                        onPress={()=>navigation.push('Community')}
                    >
                        <Text style={[styles.buttonText,{color: 'gray'}]}>{'전체보기'}</Text>
                        <Image style={styles.arrow} source={arrow_right}/>
                    </Pressable>
                </View>
                <FlatList
                    horizontal={true}
                    data={bookSalonData}
                    renderItem={({item})=>
                        <BookSalonListItem navigation={navigation} data={item} isLogin={isLogin}/>
                    }
                />
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    contentsWrapper: {
        maxHeight: innerHeight,
        height: PixelRatio.getPixelSizeForLayoutSize(innerHeight) - PixelRatio.getPixelSizeForLayoutSize(800)
    },
    titleText: {
        fontFamily: 'SCDream5',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
    },
    icon: {
        alignSelf: 'center',
        width: PixelRatio.getPixelSizeForLayoutSize(18),
        height: PixelRatio.getPixelSizeForLayoutSize(18),
        marginRight: PixelRatio.getPixelSizeForLayoutSize(6)
    },

    buttonText: {
        fontFamily: 'KoPub_Dotum_Pro',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '500',
        alignSelf: 'center',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(8),
    },
    boardWrapper: {
        backgroundColor: 'white',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(20),
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(5),
        'box-shadow': '3px 3px 5px #0000000D'
    },
    separator: {
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(8),
        backgroundColor: '#E5E5E5'
    },
    buttonWrapper: {
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
    },
    arrow: {
        width: PixelRatio.getPixelSizeForLayoutSize(6),
        height: PixelRatio.getPixelSizeForLayoutSize(11),
    },
    subTitleWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(16),
    },

})


export default Main;
