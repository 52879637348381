const user = {
    get: {
        refresh: '/api/user/refresh',
        logout: '/api/user/logout', //로그아웃
    },
    post: {
        signUp: '/api/user/signup',
        isIdCanBeUsed: '/api/user/isIdCanBeUsed',
        isNicknameCanBeUsed: '/api/user/isNicknameCanBeUsed',
        isPhoneNumberCanBeUsed: '/api/user/isTelCanBeUsed',
        login: '/api/user/login', // 로그인
        readUser: '/api/user/readUser',
        updateUser: '/api/user/updateUser',
        makeRandomNumber: '/api/user/makeRandomNumberForPasswordReset',
        checkRandomNumber: '/api/user/checkRandomNumberForPasswordReset',
        changePassword: '/api/user/changePassword',
    },
    getJob: '/api/user/getJobCommoncode',
    getHobby: '/api/user/getHobbyCommoncode'
};

const board = {
    categoryType: {
        question: 'question',
        answer: 'answer',
        reply: 'reply',
    },
    get: {
        notice: '/api/board/question/notice',
        read: function (category) {
            return `/api/board/${category}/read`;
        },
        detail: function (category, uid) {
            return `/api/board/${category}/${uid}`;
        },

    },
    post: {
        save: (category)=> {
            return `/api/board/${category}/save`;
        },
        update: (category)=> {
            return `/api/board/${category}/update`;
        },
        report:  (category) => {
            return `/api/board/${category}/report`;
        },
        unReport: (category) => {
            return `/api/board/${category}/unreport';`
        },
        deleteReply: (uid)=>{
            return `/api/board/reply/delete/${uid}`
        },
        anotherAnswer: '/api/board/answer/anotherAnswer',
        getCategoryCode: '/api/board/question/getCategoryCode',
        getBookSalonCode: '/api/board/question/getBookSalonCode',
        sortByAnswerNumberTop5: '/api/board/question/read/sortByAnswerNumberTop5',
        makePrivate:(category= 'question'|'answer')=> `/api/board/${category}/makePrivate`,
        makePublic: (category= 'question'|'answer')=> `/api/board/${category}/makePublic`,
        saveText: '/api/board/saveTxtFile',
        search: '/api/board/question/search',
        totalCount: '/api/board/question/number'
    }
};

const inquire = {
    read: '/api/board/inquire/read',
    save: '/api/board/inquire/save',
    detail: (uid)=>`/api/board/inquire/${uid}`
}

const notice = {
    read: '/api/board/notice/read',
    detail: (uid)=>`/api/board/notice/${uid}`,
    termsOfService: '/api/termsOfService/termsOfService'
}

const view_api = {
    post: {
        main: '/api/view/main',
    }
}

const favorite = {
    categoryType: {
        question: 'question',
        answer: 'answer',
        reply: 'reply',
    },
    post: {
        add: function (category) {
            return `/api/favorite/${category}/addFavorite`;
        },
        show: function (category) {
            return `/api/favorite/${category}/showFavorite`;
        },
        remove: function (category) {
            return `/api/favorite/${category}/removeFavorite`;
        },
        myFavorite: '/api/view/question/myFavorite',
        myAnswer: '/api/view/question/myAnswer',
        myQuestion: '/api/view/question/myAnswer',
    }
};

const myQuestion = {
    post:{
        myFavorite: '/api/view/question/myFavorite',
        myAnswer: '/api/view/question/myAnswer',
        myQuestion: '/api/view/question/myQuestion',
    }
}

export {user, favorite, board, view_api, myQuestion, inquire, notice}
